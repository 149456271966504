import { useState, useEffect, useRef } from "react";
export default function useComponentVisible(initialIsVisible) {
  const [componentVisible, setComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);
  const [ignoreClick, setIgnoreClick] = useState(false);
  const handleClickOutside = (event) => {
    if (!ignoreClick) {
      if (ref.current && !ref.current.contains(event.target)) {
        setComponentVisible("");
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  return { ref, componentVisible, setComponentVisible, setIgnoreClick };
}
