import { Form, Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const bg = {
  background: "url('/assets/images/astro.jpg') no-repeat",
  backgroundSize: "cover",
};

const Forgot = () => {
  const history = useHistory();
  function submitHandler() {
    history.push("/login");
  }
  return (
    <div className="login">
      <div className="login__wrapper" style={bg}>
        <div className="login__form">
          <img
            src="/assets/images/karmic_vert.png"
            alt="logo"
            className="logo"
          />
          <h5 className="text-secondary mb-2 text-center font-weight-bold">
            Forgot your password?
          </h5>
          <p className="text-secondary mb-4 text-center">
            Enter your Email and we'll send you a reset
          </p>
          <Form>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Button
              className="btn-primary btn-round mb-2"
              onClick={submitHandler}
            >
              Submit
            </Button>
            <p className="back-link">
              <Link to="/">
                <FaArrowLeft className="mr-2" />
                Back to Login
              </Link>
            </p>
          </Form>
          <div className="copyright">
            <p>Designed and Powered by</p>
            <a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
              Ideabreed IT Solution Pvt. Ltd.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
