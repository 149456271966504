import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postNotification } from "../../../_redux/actions/ActionSettings";
import { getAllZodiacs } from "../../../_redux/actions/ActionZodiacs";
import { connect } from "react-redux";
import {
  FaChevronDown,
  FaChevronUp,
  FaTimesCircle,
  FaTrashAlt,
} from "react-icons/fa";
import Select from "../../../components/Search";
import { fetchWrapper } from "../../../_helpers";
import ReactFlagsSelect from "react-flags-select";
import ReactCountryFlag from "react-country-flag";
import { toast } from "react-toastify";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const AddNotification = ({
  postNotification,
  getAllZodiacs,
  allZodiacs,
  setShowAdd,
  showAdd,
}) => {
  const [showFilter, setShowFilter] = useState("");
  const [page] = useState(1);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [customers, setCustumers] = useState({ count: 0, results: [] });
  const [count, setCount] = useState(0);
  const [zodiacName, setZodiacName] = useState("");

  const [filter, setFilter] = useState({
    country: "",
    device: "",
    dob_AD: "",
    gender: "",
    zodiac: "",
  });
  const [intermediatefilter, setIntermediateFilter] = useState({});
  const [usedFilter, setUsedFilter] = useState({});
  const [extraUser, setExtraUser] = useState([]);

  useEffect(() => {
    getAllZodiacs();
  }, [getAllZodiacs]);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    let querystr = getFilteredString(usedFilter).substring(1);
    if (!(data.date && data.time)) {
      return toast.error("Date and time is required");
    }
    const tempData = {
      delivery_datetime: `${data.date} ${data.time}`,
      title: data.title,
      message: data.message,
      user_ids: extraUser.map((u) => u.user.id.toString()),
    };
    postNotification(tempData, querystr);
    setShowAdd(!showAdd);
  };

  const onSubmitNow = (data) => {
    let querystr = getFilteredString(usedFilter).substring(1);
    const tempData = {
      title: data.title,
      message: data.message,
      user_ids: extraUser.map((u) => u.user.id.toString()),
    };
    postNotification(tempData, querystr);
    setShowAdd(!showAdd);
  };

  const handleSearchUser = async (nameString) => {
    if (/^\d+$/.test(nameString)) {
      try {
        const response = await fetchWrapper.get(
          COMMON_URL + `customer/profile/${nameString}/`
        );
        setUsers([response]);
      } catch (e) {}
    } else if (nameString) {
      try {
        const response = await fetchWrapper.get(
          COMMON_URL + `customer/profile/?page=${page}&name=${nameString}`
        );
        setUsers(response.results);
      } catch (e) {}
    }
  };

  const handleFilter = async (filter) => {
    try {
      const response = await fetchWrapper.get(
        COMMON_URL +
          `customer/profile/?page=${page}${getFilteredString(filter)}`
      );
      setIntermediateFilter(filter);
      setCustumers({ count: response.count, results: response.results });
    } catch (e) {}
  };
  const handleRemoveFilter = async (filter) => {
    const isFIlter = Object.values(filter).find((val) => val !== "");
    if (isFIlter && customers.results.length) {
      handleFilter(filter);
    } else {
      setCustumers({ count: 0, results: [] });
      setIntermediateFilter(filter);
    }
  };
  const handleAdd = async () => {
    let querystr = getFilteredString(intermediatefilter).substring(1);
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `system/system-notifications/get_users/?${querystr}`
      );
      setCount(response.user_count);
      setUsedFilter(intermediatefilter);
      setExtraUser(selectedUser);
    } catch (e) {}
  };

  const getFilteredString = (filter = {}) => {
    let filterStr = "";

    for (const key in filter) {
      if (filter[key]) {
        filterStr = filterStr + `&${key}=${filter[key]}`;
      }
    }
    return filterStr;
  };

  return (
    <Row>
      <Col md={8}>
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center">
              <p className="font-weight-bold mr-3">Add User</p>
              <Select
                handleUserInput={handleSearchUser}
                data={users.filter((cos) => {
                  const selectedUserIndex =
                    selectedUser.findIndex((user) => user.id === cos.id) === -1
                      ? true
                      : false;

                  const filteredUserIndex =
                    customers.results.findIndex(
                      (user) => user.id === cos.id
                    ) === -1
                      ? true
                      : false;

                  if (selectedUserIndex && filteredUserIndex) return true;
                  return false;
                })}
                handleDataSelect={(newUser) => {
                  setSelectedUser((prev) => [...prev, newUser]);
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="filter-wrapper">
                <ReactFlagsSelect
                  selected={filter.country}
                  onSelect={(code) => {
                    setFilter((prev) => ({
                      ...prev,
                      country: code,
                    }));
                    // setShowStatus(false);
                    setShowFilter("");
                  }}
                  showSelectedLabel={true}
                  showSecondarySelectedLabel={true}
                  selectedSize={16}
                  showOptionLabel={true}
                  showSecondaryOptionLabel={true}
                  optionsSize={16}
                  placeholder="Select a country"
                  searchable={true}
                  alignOptionsToRight={false}
                  fullWidth={true}
                  disabled={false}
                />
              </div>
              <div className="filter-wrapper">
                <div className="select-toggle">
                  <div
                    className="selector"
                    onClick={() =>
                      setShowFilter(showFilter !== "device" ? "device" : "")
                    }
                  >
                    <p>{filter.device ? filter.device : "Device"}</p>
                    {showFilter === "device" ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </div>
                  {showFilter === "device" && (
                    <div className="select-wrapper-right select-wrapper-sm">
                      <Button
                        className="btn-outline-primary-100 mb-2"
                        onClick={() => {
                          setFilter((prev) => ({ ...prev, device: "android" }));
                          setShowFilter("");
                        }}
                      >
                        Android
                      </Button>
                      <Button
                        className="btn-outline-primary-100 mb-2"
                        onClick={() => {
                          setFilter((prev) => ({ ...prev, device: "ios" }));
                          setShowFilter("");
                        }}
                      >
                        iOs
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="filter-wrapper">
                <div className="select-toggle">
                  <div
                    className="selector"
                    onClick={() =>
                      setShowFilter(showFilter !== "dob" ? "dob" : "")
                    }
                  >
                    <p>{filter.dob ? filter.dob : "DOB"}</p>
                    {showFilter === "dob" ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {showFilter === "dob" && (
                    <div className="select-wrapper-right select-wrapper-sm">
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Control type="month"></Form.Control>
                        </Form.Group>
                        <Button
                          type="submit"
                          className="btn-outline-primary-100 mb-2"
                          onClick={(e) => {
                            setShowFilter("");
                          }}
                        >
                          Close
                        </Button>
                      </Form.Row>
                    </div>
                  )}
                </div>
              </div>
              <div className="filter-wrapper">
                <div className="select-toggle">
                  <div
                    className="selector"
                    onClick={() =>
                      setShowFilter(showFilter !== "gender" ? "gender" : "")
                    }
                  >
                    <p>{filter.gender ? filter.gender : "Gender"}</p>
                    {showFilter === "gender" ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </div>
                  {showFilter === "gender" && (
                    <div className="select-wrapper select-wrapper-sm">
                      <Button
                        className="btn-outline-primary-100 mb-2"
                        onClick={() => {
                          setFilter((prev) => ({ ...prev, gender: "Male" }));
                          setShowFilter("");
                        }}
                      >
                        Male
                      </Button>
                      <Button
                        className="btn-outline-primary-100 mb-2"
                        onClick={() => {
                          setFilter((prev) => ({ ...prev, gender: "Female" }));
                          setShowFilter("");
                        }}
                      >
                        Female
                      </Button>
                      <Button
                        className="btn-outline-primary-100 mb-2"
                        onClick={() => {
                          setFilter((prev) => ({ ...prev, gender: "Other" }));
                          setShowFilter("");
                        }}
                      >
                        Other
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="filter-wrapper">
                <div className="select-toggle">
                  <div
                    className="selector"
                    onClick={() =>
                      setShowFilter(showFilter !== "zodiac" ? "zodiac" : "")
                    }
                  >
                    <p>{zodiacName ? zodiacName : "Zodiac"}</p>
                    {showFilter === "zodiac" ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </div>
                  {showFilter === "zodiac" && (
                    <div className="select-wrapper select-wrapper-sm">
                      {allZodiacs &&
                        allZodiacs.map((item) => (
                          <Button
                            className="btn-outline-primary-100 mb-2"
                            onClick={() => {
                              setFilter((prev) => ({
                                ...prev,
                                zodiac: `${item.id}`,
                              }));
                              setShowFilter("");
                              setZodiacName(item.name);
                            }}
                          >
                            {item.name}
                          </Button>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="filter-wrapper">
                <Button
                  className="btn-dark btn-round"
                  onClick={() => handleFilter(filter)}
                >
                  Apply
                </Button>
              </div>
            </div>
            {Object.keys(intermediatefilter).map(
              (filterKey) =>
                intermediatefilter[filterKey] && (
                  <span className="user-wrapper">
                    <p>{intermediatefilter[filterKey]}</p>
                    <FaTimesCircle
                      size="12"
                      color="#BC2A20"
                      onClick={() => {
                        setFilter((prev) => {
                          handleRemoveFilter({
                            ...prev,
                            [filterKey]: "",
                          });
                          return {
                            ...prev,
                            [filterKey]: "",
                          };
                        });
                      }}
                    />
                  </span>
                )
            )}
            {/* <p className="mt-3 font-weight-bold text-dark">
              <Form.Check type="checkbox" label="Result" />
            </p> */}

            <hr className="mt-1" />
            <div className="messages messages-sm p-0 flex-column">
              {customers.results
                .filter((cos) =>
                  selectedUser.findIndex((user) => user.id === cos.id) === -1
                    ? true
                    : false
                )
                .map((item) => (
                  <div className="search-items">
                    <Row>
                      <Col md={1}>
                        <div className="d-flex align-items-center mr-2">
                          {/* <Form.Check type="checkbox" /> */}
                          <p>
                            <ReactCountryFlag
                              className="emojiFlag mr-1"
                              countryCode={item.country_code}
                              style={{
                                fontSize: "2em",
                              }}
                              title={item.country}
                              svg
                            />
                            {item.country}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="d-flex align-items-center">
                          <img
                            src={
                              item.profile_image
                                ? item.profile_image
                                : "/assets/images/avatar.png"
                            }
                            alt="profile"
                            className="user-img"
                          />
                          <div>
                            <span className="user-name">{item.user?.name}</span>
                            <p>{item.user?.username}</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <p>{item.gender}</p>
                      </Col>
                      <Col md={2}>
                        <p>{item.zodiac_name}</p>
                      </Col>

                      <Col md={2}>
                        <p>{item.dob_AD}</p>
                      </Col>
                      <Col md={2}>
                        <p>{item.agent}</p>
                      </Col>
                    </Row>
                  </div>
                ))}
              {selectedUser.map((item) => (
                <div className="search-items">
                  <Row>
                    <Col md={1}>
                      <div className="d-flex align-items-center mr-2">
                        {/* <Form.Check type="checkbox" /> */}
                        <p>
                          <ReactCountryFlag
                            className="emojiFlag mr-1"
                            countryCode={item.country_code}
                            style={{
                              fontSize: "2em",
                            }}
                            title={item.country}
                            svg
                          />
                          {item.country}
                        </p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            item.profile_image
                              ? item.profile_image
                              : "/assets/images/avatar.png"
                          }
                          alt="profile"
                          className="user-img"
                        />
                        <div>
                          <span className="user-name">{item.name}</span>
                          <p>{item.user.username}</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <p>{item.gender}</p>
                    </Col>
                    <Col md={2}>
                      <p>{item.zodiac_name}</p>
                    </Col>

                    <Col md={2}>
                      <p>{item.dob_AD}</p>
                    </Col>
                    {/* <Col md={2}>
                      <p>{item.agent}</p>
                    </Col> */}
                    <Col md={1}>
                      <FaTrashAlt
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setSelectedUser((prev) => {
                            const tempData = [...prev];
                            const index = tempData.findIndex(
                              (data) => data.id === item.id
                            );
                            if (index !== -1) {
                              tempData.splice(index, 1);
                            }
                            return tempData;
                          })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
            <hr />
            <div className="text-center mt-3">
              <Button className="btn-dark btn-round px-5" onClick={handleAdd}>
                Add
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card>
          <Card.Body>
            <Form>
              <p className="font-weight-bold">Selected Users</p>
              <div className="mt-3 user-selection-wrapper">
                {/* <p>No users selected</p> */}
                <span className="user-wrapper">
                  <p>{count > 0 ? count : "No filters applied"}</p>
                  {Object.values(usedFilter).map((f) => (
                    <Badge className="mx-1 bg-primary text-white">{f}</Badge>
                  ))}
                  <FaTimesCircle
                    size="12"
                    color="#BC2A20"
                    onClick={() => {
                      setUsedFilter({});
                      setCount(0);
                    }}
                  />
                </span>

                {extraUser.map((u) => (
                  <span className="user-wrapper">
                    {u.name}
                    <FaTimesCircle
                      size="12"
                      color="#BC2A20"
                      onClick={() =>
                        setExtraUser((prev) => {
                          const tempData = [...prev];
                          const index = tempData.findIndex(
                            (data) => data.id === u.id
                          );
                          if (index !== -1) {
                            tempData.splice(index, 1);
                          }
                          return tempData;
                        })
                      }
                    />
                  </span>
                ))}
                {/* <span className="user-wrapper">
                  <p>Male: 177</p>
                  <FaTimesCircle size="12" color="#BC2A20" />{" "}
                </span>
                <span className="user-wrapper">
                  <p>Zodiac: Virgo</p>
                  <FaTimesCircle size="12" color="#BC2A20" />{" "}
                </span> */}
              </div>
              <Form.Group className="mt-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" {...register("title")} />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" row={5} {...register("message")} />
              </Form.Group>
              <Row>
                <Col md={7}>
                  <Form.Group className="mt-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" {...register("date")} />
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group className="mt-3">
                    <Form.Label>Time</Form.Label>
                    <Form.Control type="time" {...register("time")} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center mt-5">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  className="btn-primary btn-round w-100"
                >
                  Schedule
                </Button>
                <Button
                  onClick={handleSubmit(onSubmitNow)}
                  className="btn-dark btn-round mt-3"
                >
                  Send now
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  allZodiacs: state?.zodiacs?.zodiacs,
});

const mapDispatchToProps = { postNotification, getAllZodiacs };

export default connect(mapStateToProps, mapDispatchToProps)(AddNotification);
