import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  postClarificationType,
  getClarificationType,
  deleteClarificationType,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

const AddTemplateType = ({
  postClarificationType,
  getClarificationType,
  clarificationType,
  deleteClarificationType,
  setShowAddType,
}) => {
  useEffect(() => {
    getClarificationType();
  }, [getClarificationType]);

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    postClarificationType(data);
    setShowAddType(false);
    reset();
  };
  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Template Type Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="eg. Confusion"
                  {...register("clarification_type")}
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <div className="scroll-wrapper">
                <Row>
                  {clarificationType.length > 0 &&
                    clarificationType.map((item, index) => (
                      <Col md={6}>
                        <div className="question-type-list d-flex align-items-center justify-content-between">
                          <p>{item.clarification_type}</p>
                          <FaTrashAlt
                            color="#BC2A20"
                            size={14}
                            className="pointer"
                            onClick={() => deleteClarificationType(item.id)}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  clarificationType: state.clarificationType.clarificationType,
});

const mapDispatchToProps = {
  postClarificationType,
  deleteClarificationType,
  getClarificationType,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateType);
