import { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import CustomerProfile from "./pages/Customer/Profile";
import ModeratorProfile from "./pages/Moderator/Profile";
import AstrologerProfile from "./pages/Astrologer/Profile";
import Forgot from "./pages/auth/Forgot";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import Questions from "./pages/Questions";
import StaffManagement from "./pages/Staff/StaffManagement";
import Chat from "./pages/Chat/Chat";
import ProfileModal from "./pages/Customer/ProfileModal";
import { AuthRoute, PrivateRoute } from "./_helpers";
import QuestionManagement from "./pages/QuestionManagement/QuestionManagement";
import GeneralSetting from "./pages/Settings/GeneralSettings/GeneralSetting";
import SystemNotification from "./pages/Settings/SystemNotifications/SystemNotification";
import SystemMessage from "./pages/Settings/SystemMessages/SystemMessage";
import ClarificationTemplate from "./pages/Settings/Clarification/ClarificationTemplate";
import CustomersListing from "./pages/Qa/CustomersListing";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./scss/style.scss";
import { connect } from "react-redux";

import { getUserData } from "./_redux/actions/ActionUser";
import PageContent from "./pages/PageContent";
import WelcomePage from "./pages/WelcomePage";
import NotFound from "./pages/NotFound";
import Customers from "./pages/Customer/Customers";
import Zodiacs from "./pages/Zodiacs/Zodiacs";
import Helper from "./pages/Settings/AstrologerHelpers/Helper";
import ModeratorHelper from "./pages/Settings/ModeratorHelpers/ModeratorHelper";
import SalesReport from "./pages/SalesReport/SalesReport";
import PointsReport from "./pages/PointsReport/PointsReport";
import CustomersProfile from "./pages/Qa/CustomersProfile";
import PendingFeedback from "./pages/Qa/PendingFeedback";
import SimpleReactLightbox from "simple-react-lightbox";

function App({ getUserData, isLogged, message }) {
  const history = useHistory();
  useEffect(() => {
    if (isLogged) getUserData(history);
  }, [getUserData, isLogged, history]);

  // centralized message display
  useEffect(() => {
    if (message) {
      if (message.error) {
        toast.error(message.message);
      } else {
        toast.success(message.message);
      }
    }
  }, [message]);
  return (
    <SimpleReactLightbox>
      <Switch>
        <AuthRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Dashboard} role={[]} />
        <PrivateRoute
          exact
          path="/forgot"
          component={Forgot}
          role={["superuser", "moderator", "astrologer", "qa"]}
        />
        <PrivateRoute exact path="/questions/:pageNum" component={Questions} role={[]} />
        <PrivateRoute
          exact
          path="/customer/profile/:id"
          component={CustomerProfile}
          role={["superuser", "moderator", "astrologer", "qa"]}
        />
        <PrivateRoute
          exact
          path="/customers/profile/:id"
          component={CustomersProfile}
          role={["qa"]}
        />
        <PrivateRoute
          exact
          path="/customer/profile/modal/:id"
          component={ProfileModal}
          role={["superuser", "moderator", "astrologer", "qa"]}
        />
        <PrivateRoute
          exact
          path="/moderator/profile/:id"
          component={ModeratorProfile}
          role={["superuser", "moderator"]}
        />
        <PrivateRoute
          exact
          path="/astrologer/profile"
          component={AstrologerProfile}
          role={["superuser", "astrologer"]}
        />
        <PrivateRoute exact path="/general/staff" component={StaffManagement} />
        <PrivateRoute
          exact
          path="/general/questionmanagement"
          component={QuestionManagement}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/settings/zodiacs/:pageNum"
          component={Zodiacs}
          role={[]}
        />
        <PrivateRoute exact path="/sales/:pageNum" component={SalesReport} role={[]} />
        <PrivateRoute exact path="/points/:pageNum" component={PointsReport} role={[]} />
        <PrivateRoute
          exact
          path="/chat"
          component={Chat}
          role={["superuser", "moderator", "astrologer", "qa"]}
        />
        <PrivateRoute
          exact
          path="/settings/general"
          component={GeneralSetting}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/settings/notification"
          component={SystemNotification}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/settings/messages"
          component={SystemMessage}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/settings/clarification"
          component={ClarificationTemplate}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/settings/moderator/helpers"
          component={ModeratorHelper}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/settings/astrologer/helpers"
          component={Helper}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/pagecontent"
          component={PageContent}
          role={[]}
        />
        <PrivateRoute
          exact
          path="/welcome"
          component={WelcomePage}
          role={["superuser", "moderator", "astrologer", "qa"]}
        />
        <PrivateRoute
          exact
          path="/customerlisting/:pageNum"
          component={CustomersListing}
          role={["qa"]}
        />
        <PrivateRoute
          exact
          path="/feedback/:pageNum"
          component={PendingFeedback}
          role={["qa"]}
        />
        <PrivateRoute
          exact
          path="/notfound"
          component={NotFound}
          role={["superuser", "moderator", "astrologer", "qa"]}
        />
        <PrivateRoute exact path="/chat/:id" component={Chat} role={["qa"]} />
        <PrivateRoute
          exact
          path="/customers/:pageNum"
          component={Customers}
          role={[]}
        />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </SimpleReactLightbox>
  );
}
const mapStateToProps = (state) => ({
  message: state.ui.message,
  isLogged: state.user.isLogged,
});

export default connect(mapStateToProps, { getUserData })(App);
