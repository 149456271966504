import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Layout from "../../../layouts/Layout";
import AddTemplate from "./AddTemplate";
import AddTemplateType from "./AddTemplateType";
import {
  getClarificationTemplate,
  deleteClarificationTemplate,
} from "../../../_redux/actions/ActionSettings";

import { connect } from "react-redux";
import PaginationComp from "../../../components/PaginationComp";
import Loader from "../../../components/Loader";
import swal from "sweetalert";

const ClarificationTemplate = ({
  getClarificationTemplate,
  clarificationTemplate,
  deleteClarificationTemplate,
  totalClarificationTemplate,
  loading,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showAddType, setShowAddType] = useState(false);
  const [page, setPage] = useState(1);
  const [clarificationTemp, setClarificationTemp] = useState(null);
  useEffect(() => {
    getClarificationTemplate(page);
  }, [getClarificationTemplate, page]);
  return (
    <Layout>
      <div className="questions-section">
        <Row className="mt-3">
          <Col md={4}>
            <h5 className="font-weight-bold text-danger mb-2">
              Clarification Templates
            </h5>
          </Col>
          <Col md={{ span: 3, offset: 3 }}>
            <Button
              className="btn-primary btn-round float-right mb-2"
              onClick={() => {
                setShowAddType(!showAddType);
                setShowAdd(false);
              }}
            >
              Add Template Type
            </Button>
          </Col>
          <Col md={2}>
            <Button
              className="btn-primary btn-round float-right mb-2"
              onClick={() => {
                setShowAdd(!showAdd);
                setShowAddType(false);
                setClarificationTemp(null);
              }}
            >
              Add Template
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={12}>
            {showAddType && <AddTemplateType setShowAddType={setShowAddType} />}
          </Col>
          <Col md={12}>
            {showAdd && (
              <AddTemplate
                setShowAdd={setShowAdd}
                clarificationTemp={clarificationTemp}
                setClarificationTemp={setClarificationTemp}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Type</th>
                      <th>Message</th>
                      <th>Created At</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      clarificationTemplate.length > 0 &&
                      clarificationTemplate.map((item, index) => {
                        return (
                          <tr>
                            <td width="5%">{(page - 1) * 10 + index + 1}</td>
                            <td>{item.clarification_type_title}</td>
                            <td width="55%">{item.clarification}</td>
                            <td width="15%">{item.created.slice(0, 10)}</td>
                            <td width="10%" className="text-center">
                              <FaEdit
                                className="text-info pointer mr-1"
                                onClick={() => {
                                  setShowAdd(!showAdd);
                                  setClarificationTemp(item);
                                }}
                              />
                              <FaTrashAlt
                                className="text-danger pointer"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover !",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      deleteClarificationTemplate(item.id);
                                    }
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </Table>
                {totalClarificationTemplate > 10 && (
                  <PaginationComp
                    total={totalClarificationTemplate}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  clarificationTemplate: state.clarificationTemplate.clarificationTemplate,
  totalClarificationTemplate:
    state.clarificationTemplate.totalClarificationTemplate,
  loading: state.ui.loading,
});

export default connect(mapStateToProps, {
  getClarificationTemplate,
  deleteClarificationTemplate,
})(ClarificationTemplate);
