const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        Designed and Powered by &nbsp;
        <a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
          Ideabreed IT Solution Pvt. Ltd.
        </a>
      </div>
    </div>
  );
};

export default Footer;
