import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationComp = ({ total, page, setPage, showGoto = true }) => {
  const [pageNum, setPageNum] = useState(page);
  const totalCount = useMemo(
    () => Array.from({ length: Math.ceil(total / 10) }, (v, i) => i + 1),
    [total]
  );

  useEffect(() => {
    setPageNum(page);
  }, [page]);

  return (
    <div className="pagination-wrapper">
      <p>
        Showing {page === Math.ceil(total / 10) ? total % 10 : 10} of {total}{" "}
        entries
      </p>
      <Pagination>
        <Pagination.First
          disabled={page <= 5}
          onClick={() => (page > 1 ? setPage(page - 5) : null)}
        />
        <Pagination.Prev
          disabled={page <= 1}
          onClick={() => (page > 1 ? setPage(page - 1) : null)}
        />
        {page < 6 ? (
          totalCount
            .filter((count, index) => index < 5)
            .map((count) => (
              <>
                <Pagination.Item
                  active={count === page}
                  key={count}
                  onClick={() => setPage(count)}
                >
                  {count}
                </Pagination.Item>
              </>
            ))
        ) : (
          <>
            <Pagination.Item>...</Pagination.Item>
            <Pagination.Item onClick={() => setPage(page - 2)}>
              {page - 2}
            </Pagination.Item>
            <Pagination.Item onClick={() => setPage(page - 1)}>
              {page - 1}
            </Pagination.Item>

            <Pagination.Item active>{page}</Pagination.Item>

            <Pagination.Item onClick={() => setPage(page + 1)}>
              {page + 1}
            </Pagination.Item>

            <Pagination.Item
              onClick={() =>
                page < totalCount.length ? setPage(page + 2) : null
              }
            >
              {page + 2}
            </Pagination.Item>

            <Pagination.Item>...</Pagination.Item>
          </>
        )}

        <Pagination.Next
          disabled={page >= totalCount.length}
          onClick={() => (page <= totalCount.length ? setPage(page + 1) : null)}
        />
        <Pagination.Last
          disabled={page >= totalCount.length - 9}
          onClick={() =>
            page < totalCount.length && page + 5 < totalCount.length
              ? setPage(page + 5)
              : setPage(totalCount.length - page)
          }
        />
        {showGoto ? (
          <div className="pagination-input_div">
            {page} / {totalCount.length}
            <input
              className="pagination-input"
              type="number"
              value={pageNum}
              onChange={(e) => {
                if (e.target.value <= totalCount.length) {
                  setPageNum(e.target.value);
                }
              }}
            />
            <button
              onClick={() => {
                setPage(pageNum);
              }}
            >
              Go
            </button>
          </div>
        ) : null}
      </Pagination>
    </div>
  );
};

export default PaginationComp;
