import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getTicket = (history) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(COMMON_URL + `open-ticket/`);
    dispatch({
      type: ActionTypes.GET_TICKET,
      payload: response,
    });
    history.push("/chat");
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: "No tickets found",
      },
    });
  }
};
