import { toast } from "react-toastify";
import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getCustomers =
  (page = 1, filter) =>
  async (dispatch) => {
    let filterStr = "";
    if (filter.country) {
      filterStr = filterStr + `&country=${filter.country}`;
    }
    if (filter.gender) {
      filterStr = filterStr + `&gender=${filter.gender}`;
    }
    if (filter.device) {
      filterStr = filterStr + `&device=${filter.device}`;
    }
    if (filter.dob_AD) {
      filterStr = filterStr + `&dob_AD=${filter.dob_AD}`;
    }
    if (filter.zodiac) {
      filterStr = filterStr + `&zodiac=${filter.zodiac}`;
    }
    if (filter.name) {
      filterStr = filterStr + `&name=${filter.name}`;
    }
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `customer/profile/?page=${page}${filterStr}`
      );
      dispatch({
        type: ActionTypes.GET_CUSTOMERS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const editCustomers = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(COMMON_URL + `user/${id}/`, data);
    dispatch({
      type: ActionTypes.EDIT_CUSTOMER,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Customer profile updated successfully",
      },
    });
  } catch (e) {
    if (e.data.email) {
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: false,
          message: e.data.email[0],
        },
      });
    } else {
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: false,
          message: "something went wrong",
        },
      });
    }
  }
};

export const assignZodiac = (data, id) => async (dispatch) => {
  try {
    await fetchWrapper.patch(
      COMMON_URL + `customer/profile/${id}/`,
      data
    );
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Zodiac Assigned Successfully",
      },
    });
  } catch (e) {
    console.log(e);
    toast.success("Couldn't Update Zodiac");
  }
};

export const getCustomerProfile = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `customer/profile/${id}/`
    );
    dispatch({
      type: ActionTypes.GET_CUSTOMER_PROFILE,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.GET_CUSTOMER_PROFILE,
      payload: null,
    });
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const getPinnedMessages = (id, filter) => async (dispatch) => {
  let entityStr = "";
  if (filter) {
    entityStr = entityStr + `&response_type=${filter}`;
  }
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `customer/profile/${id}/pinned/?${entityStr}`
    );
    dispatch({
      type: ActionTypes.GET_PINNED_MESSAGES,
      payload: response.results,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getCustomerNotes = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `customer/profile/${id}/notes/`
    );
    dispatch({
      type: ActionTypes.GET_CUSTOMER_NOTES,
      payload: response.results,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postCustomerNotes = (data) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `customer-notes/`,
      data
    );
    dispatch({
      type: ActionTypes.POST_CUSTOMER_NOTES,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Note added successfully",
      },
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: e.message,
      },
    });
  }
};
export const deleteCustomerNotes = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(
      COMMON_URL + `customer-notes/${id}`
    );
    dispatch({
      type: ActionTypes.DELETE_CUSTOMER_NOTES,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Note deleted successfully",
      },
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: e.message,
      },
    });
  }
};

export const getCustomerImages = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `customer/profile/${id}/image/`
    );
    dispatch({
      type: ActionTypes.GET_CUSTOMER_IMAGES,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postCustomerImages = (data, id) => async (dispatch) => {
  try {
    const form_data = new FormData();
    for (const key of Object.keys(data.image)) {
      form_data.append('image', data.image[key])
    }
    form_data.append("customer", id);
    const response = await fetchWrapper.postMultipart(
      COMMON_URL + `customer/profile/${id}/image/`,
      form_data,
      true
    );

    dispatch({
      type: ActionTypes.POST_CUSTOMER_IMAGES,
      payload: response
    });
    dispatch({  
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Image added successfully",
      },
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: e.message,
      },
    });
  }
};
export const deleteCustomerImages = (id, img_id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(
      COMMON_URL + `customer/profile/${id}/image/?image_id=${img_id}`
    );
    dispatch({
      type: ActionTypes.DELETE_CUSTOMER_IMAGE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Image deleted successfully",
      },
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: e.message,
      },
    });
  }
};

export const addCustomerCredit = (data) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(COMMON_URL + `add-credit/`, data);
    dispatch({
      type: ActionTypes.ADD_CUSTOMER_CREDIT,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Credit added successfully",
      },
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: e.message,
      },
    });
  }
};
