import React, { useState, useEffect } from "react";
import { Button, Card, Form, FormControl, Modal, Table } from "react-bootstrap";
import {
  FaCaretDown,
  FaCaretUp,
  FaCheckCircle,
  FaCreditCard,
  FaCross,
  FaEye,
  FaMicrophoneAlt,
  FaQuestionCircle,
  FaTicketAlt,
  FaTimes,
  FaTimesCircle,
  FaUser,
} from "react-icons/fa";
import { BsFilter } from "react-icons/bs";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import { getDashboardStats } from "../_redux/actions/ActionDashboard";
import { getAllModerator } from "../_redux/actions/ActionUser";
import {
  assignModerator,
  changeQuestionState,
} from "../_redux/actions/ActionQuestions";
import { connect } from "react-redux";
import ModeratorSelect from "../components/ModeratorSelect";
import Loader from "../components/Loader";
import ReactCountryFlag from "react-country-flag";
import ReactTooltip from "react-tooltip";
import { fetchWrapper } from "../_helpers";
import { toast } from "react-toastify";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const Dashboard = ({
  getDashboardStats,
  dashboardStats,
  getAllModerator,
  moderator,
  assignModerator,
  changeQuestionState,
}) => {
  const [assign, setAssign] = useState(false);
  const [question, setQuestion] = useState(null);
  const [page] = useState(1);
  const [moderators, setModerators] = useState([]);
  const [selectedMod, setSelectedMod] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [changeState, setChangeState] = useState({ id: null, bool: false });
  const [showAssignees, setShowAssignees] = useState(false);
  const [filter, setFilter] = useState("Overall");
  const [updatedValue, setUpdatedValue] = useState({ id: null, value: "" });
  const MAX_LENGTH = 120;

  useEffect(() => {
    getDashboardStats();
    getAllModerator();
  }, [getDashboardStats, getAllModerator]);

  const handleAssign = (question) => {
    if (selectedMod) {
      assignModerator(question.id, selectedMod.user.profile_id);
      setAssign(false);
    } else {
      toast.error("Please select a moderator");
    }
  };

  const handleSearchUser = async (nameString) => {
    if (nameString) {
      if (/^\d+$/.test(nameString)) {
        try {
          const response = await fetchWrapper.get(
            COMMON_URL + `profile/${nameString}/`
          );
          setModerators((prev) => [...prev, response]);
        } catch (e) {}
      } else if (nameString) {
        try {
          const response = await fetchWrapper.get(
            COMMON_URL +
              `profile/?page=${page}&role=moderator&name=${nameString}`
          );
          setModerators(response.results);
        } catch (e) {}
      }
    }
  };
  const handleStateChange = (id, e) => {
    const state = e.target.value;
    changeQuestionState(id, state)
      .then((res) => {
        setUpdatedValue({ id: id, value: res.state });
        toast.success(`Question ${id} state changed to ${state}`);
      })
      .catch((err) => toast.error("Something went wrong!"));
    setChangeState({});
  };
  return (
    <Layout>
      {dashboardStats ? (
        <>
          <div className="dashboard-header">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex-inline flex-column align-items-center justify-content-center">
                    <h2>
                      {filter === "Overall"
                        ? dashboardStats.customer_count
                        : dashboardStats.customer_today}
                    </h2>
                    <h5>Total Customers</h5>
                  </div>
                  <FaUser color="#F1B938" size={24} />
                </div>
                {filter === "Overall" && (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h6
                      className={
                        dashboardStats.customer_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      Today
                    </h6>
                    <h6
                      className={
                        dashboardStats.customer_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      {dashboardStats.customer_today_compare >= 0 ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      )}
                      {dashboardStats.customer_today_compare >= 0
                        ? dashboardStats.customer_today_compare
                        : dashboardStats.customer_today_compare
                            .toString()
                            .slice(1)}
                    </h6>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex-inline flex-column align-items-center justify-content-center">
                    <h2>
                      {filter === "Overall"
                        ? dashboardStats.total_question_count
                        : dashboardStats.total_question_today}
                    </h2>
                    <h5>Total Questions</h5>
                  </div>
                  <FaQuestionCircle color="#D24DA6" size={24} />
                </div>
                {filter === "Overall" && (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h6
                      className={
                        dashboardStats.total_question_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      Today
                    </h6>
                    <h6
                      className={
                        dashboardStats.total_question_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      {dashboardStats.total_question_today_compare >= 0 ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      )}{" "}
                      {dashboardStats.total_question_today_compare >= 0
                        ? dashboardStats.total_question_today_compare
                        : dashboardStats.total_question_today_compare
                            .toString()
                            .slice(1)}
                    </h6>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex-inline flex-column align-items-center justify-content-center">
                    <h2>
                      {filter === "Overall"
                        ? dashboardStats.free_question_count
                        : dashboardStats.free_question_today}
                    </h2>
                    <h5>Total Free</h5>
                  </div>
                  <FaTicketAlt color="#565FD9" size={24} />
                </div>
                {filter === "Overall" && (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h6
                      className={
                        dashboardStats.free_question_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      Today
                    </h6>
                    <h6
                      className={
                        dashboardStats.free_question_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      {dashboardStats.free_question_today_compare >= 0 ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      )}{" "}
                      {dashboardStats.free_question_today_compare >= 0
                        ? dashboardStats.free_question_today_compare
                        : dashboardStats.free_question_today_compare
                            .toString()
                            .slice(1)}
                    </h6>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex-inline flex-column align-items-center justify-content-center">
                    <h2>
                      {filter === "Overall"
                        ? dashboardStats.paid_question_count
                        : dashboardStats.paid_question_today}
                    </h2>
                    <h5>Total Paid</h5>
                  </div>
                  <FaCreditCard color="#34D2A7" size={24} />
                </div>
                {filter === "Overall" && (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h6
                      className={
                        dashboardStats.paid_question_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      Today
                    </h6>
                    <h6
                      className={
                        dashboardStats.paid_question_today_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      {dashboardStats.paid_question_today_compare >= 0 ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      )}{" "}
                      {dashboardStats.paid_question_today_compare >= 0
                        ? dashboardStats.paid_question_today_compare
                        : dashboardStats.paid_question_today_compare
                            .toString()
                            .slice(1)}
                    </h6>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex-inline flex-column align-items-center justify-content-center">
                    <h2>
                      {filter === "Overall"
                        ? dashboardStats.answered
                        : dashboardStats.answered_today}
                    </h2>
                    <h5>Total Answered</h5>
                  </div>
                  <FaMicrophoneAlt color="#BC2A20" size={24} />
                </div>
                {filter === "Overall" && (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h6
                      className={
                        dashboardStats.total_answer_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      Today
                    </h6>
                    <h6
                      className={
                        dashboardStats.total_answer_compare >= 0
                          ? `text-success`
                          : `text-danger`
                      }
                    >
                      {dashboardStats.total_answer_compare >= 0 ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      )}
                      {dashboardStats.total_answer_compare >= 0
                        ? dashboardStats.total_answer_compare
                        : dashboardStats.total_answer_compare
                            .toString()
                            .slice(1)}
                    </h6>
                  </div>
                )}
              </Card.Body>
            </Card>

            <div className="select-toggle">
              <div
                className="selector pointer"
                onClick={() => setShowFilter(!showFilter)}
              >
                <BsFilter className="mr-2" />
                <p>{filter}</p>
              </div>
              {showFilter && (
                <div className="select-wrapper-right select-wrapper-sm">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter("Overall");
                      setShowFilter(false);
                    }}
                  >
                    Overall
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter("Today");
                      setShowFilter(false);
                    }}
                  >
                    Today
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="dashboard-table">
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h6 className="font-weight-bold">Recently asked questions</h6>
                <Link to="/questions/1" className="btn btn-primary btn-round">
                  View all
                </Link>
              </Card.Header>
              <Table responsive>
                <tbody>
                  {dashboardStats.lastest_question.length > 0 ? (
                    dashboardStats.lastest_question.map((item, index) => (
                      <tr className={item.status === "Paid" ? "bg-gray" : ""}>
                        <td className="text-danger text-center" width="5%">
                          {item.status === "Paid" ? "$" : ""}
                        </td>

                        <td width="10%">
                          <ReactCountryFlag
                            className="emojiFlag"
                            countryCode={item.customer.country_code}
                            style={{
                              fontSize: "2em",
                            }}
                            title={item.customer.country}
                            svg
                          />
                          <p className="os-name">{item.customer?.agent}</p>
                        </td>
                        <td width="20%">
                          <Link to={`/customer/profile/${item.customer.id}`}>
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  item.customer.profile_image
                                    ? item.customer.profile_image
                                    : "/assets/images/avatar.png"
                                }
                                className="user-img"
                                alt="user"
                              />
                              <div className="ml-2">
                                <h6 className="name font-weight-bold">
                                  {item.customer.name}
                                </h6>
                                <h6 className="day">{item.customer.user.id}</h6>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td
                          width="35%"
                          className="description pointer"
                          data-tip={item.question}
                        >
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="float"
                          />
                          {item.question?.substring(0, MAX_LENGTH)}
                          <div className="day">
                            {item.id} | {item.created.slice(0, 10)}
                          </div>
                        </td>
                        <td width="20%" className="type">
                          {changeState.id == item.id ? (
                            <Form.Control
                              as="select"
                              defaultValue={item.state}
                              onChange={(e) => handleStateChange(item.id, e)}
                            >
                              <option value="Raw">Raw</option>
                              <option value="Answered">Answered</option>
                              <option value="In Progress">In Progress</option>
                              <option value="Translated by Moderator">
                                Translated by Moderator
                              </option>
                              <option value="Translated by Astrologer">
                                Translated by Astrologer
                              </option>
                              <option value="Deleted">Deleted</option>
                              <option value="Locked">Locked</option>
                            </Form.Control>
                          ) : (
                            <p
                              onDoubleClick={() =>
                                setChangeState({ id: item.id, bool: true })
                              }
                            >
                              {updatedValue.id === item.id
                                ? updatedValue.value
                                : item.state}
                              <div className="day">
                                {item.moderator && (
                                  <Link
                                    to={`/moderator/profile/${item.moderator}`}
                                  >
                                    {`Mod: ${item.moderator}`}
                                  </Link>
                                )}
                                {item.moderator && item.astrologer && " | "}
                                {item.astrologer && (
                                  <Link
                                    to={`/moderator/profile/${item.astrologer}`}
                                  >
                                    {`Astro: ${item.astrologer}`}
                                  </Link>
                                )}
                              </div>
                            </p>
                          )}
                        </td>
                        <td width="15%">
                          {item.state !== "Answered" &&
                            item.state !== "Locked" &&
                            item.state !== "Deleted" && (
                              <img
                                src="/assets/images/play.png"
                                alt="assign"
                                size="1.5rem"
                                className="assign-btn"
                                onClick={() => {
                                  setAssign(true);
                                  setQuestion(item);
                                }}
                              />
                            )}
                          <FaEye
                            size="1.5rem"
                            className="assign-btn ml-3"
                            onClick={() => {
                              setQuestion(item);
                              setShowAssignees(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h5 className="p-3">No data to display</h5>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
          {question && (
            <Modal
              show={showAssignees && !assign}
              onHide={() => {
                setAssign(false);
                setQuestion(null);
              }}
              // className="modal-bottom assign-moderator"
            >
              <Modal.Header closeButton>
                <Modal.Title>Assigned Moderators</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table>
                  {question.assigned_moderator_listing.map((question) => (
                    <tr>
                      <td>
                        <img
                          src={
                            question.moderator_image ||
                            "/assets/images/avatar.png"
                          }
                          className="user-img"
                          alt="user"
                          height="32px"
                        />
                      </td>
                      <td>{question.fullname}</td>
                      <td>
                        {question.assigned_datetime &&
                          new Date(question.assigned_datetime).toLocaleString()}
                      </td>
                      <td>
                        {question.is_active ? (
                          <FaCheckCircle className="text-success" />
                        ) : (
                          <FaTimesCircle className="text-danger" />
                        )}
                      </td>
                    </tr>
                  ))}
                </Table>
              </Modal.Body>
            </Modal>
          )}
          {question && (
            <Modal
              show={assign}
              onHide={() => {
                setAssign(false);
                setQuestion(null);
              }}
              className="modal-bottom assign-moderator"
            >
              <Modal.Header closeButton>
                <Modal.Title>Assign to Moderator</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ModeratorSelect
                  handleUserInput={handleSearchUser}
                  data={moderators}
                  handleDataSelect={(newUser) => {
                    setSelectedMod(newUser);
                  }}
                  selectedMod={selectedMod}
                  setSelectedMod={setSelectedMod}
                />
                <div className="question-bg">
                  <p className="assign-question">
                    {question.question.substring(0, MAX_LENGTH)}
                  </p>
                </div>

                <div className="user-detail">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        question.customer.profile_image
                          ? question.customer.profile_image
                          : "/assets/images/avatar.png"
                      }
                      className="user-img"
                      alt="user"
                    />
                    <div className="ml-2">
                      <h6 className="name">{question.customer.name}</h6>
                      <h6 className="day">{question.created.slice(0, 10)}</h6>
                    </div>
                  </div>
                  <div>
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={question.customer.country_code}
                      style={{
                        fontSize: "2em",
                      }}
                      title={question.customer.country}
                      svg
                    />
                    <p className="os-name">{question.customer.country}</p>
                  </div>
                  <div>
                    <h5>{question.status === "Paid" ? "$" : ""}</h5>
                    <p>{question.status}</p>
                  </div>
                </div>
                <Button
                  className="btn-primary btn-round"
                  onClick={() => handleAssign(question)}
                >
                  Assign
                </Button>
              </Modal.Body>
            </Modal>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  dashboardStats: state.dashboardStats.dashboardStats,
  moderator: state.user.moderator,
});

const mapDispatchToProps = {
  getDashboardStats,
  getAllModerator,
  assignModerator,
  changeQuestionState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
