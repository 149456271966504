import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

//redux stuff
import { connect } from "react-redux";
import { editCustomers } from "../../_redux/actions/ActionCustomers";

const EditCustomer = ({
  showEdit,
  setShowEdit,
  editCustomers,
  customerInfo,
}) => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    editCustomers(data, customerInfo.user.id);
    setShowEdit(false);
    reset();
  };

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              {/* <Form.Group>
                <Form.File id="formcheck-api-regular">
                  <Form.File.Label>Profile Image</Form.File.Label>
                  <Form.File.Input name="profile_image" />
                </Form.File>
              </Form.Group> */}

              <Form.Group controlId="formGroupName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="eg.anish@gmail.com"
                  {...register("username")}
                  defaultValue={customerInfo && customerInfo.user?.username}
                />
              </Form.Group>
            </Col>

            <Col md={8}>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formGroupName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="eg. Anish"
                      {...register("name")}
                      defaultValue={customerInfo && customerInfo.name}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formGroupName">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="eg.anish@gmail.com"
                      {...register("email")}
                      defaultValue={customerInfo && customerInfo.user?.email}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formGroupName">
                    <Form.Label>Phone No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="eg.9845288425"
                      {...register("phone")}
                      defaultValue={customerInfo && customerInfo.user?.phone}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 2, offset: 8 }}>
                  <Button
                    className="btn-outline-primary-200 btn-round btn-sm"
                    onClick={() => setShowEdit(!showEdit)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    className="btn-primary btn-round btn-sm"
                    type="submit"
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { editCustomers };

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
