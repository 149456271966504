import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
import { logoutUser } from "./ActionAuthentication";
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getUserData = (history) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(COMMON_URL + `auth/me/`);
    dispatch({ type: ActionTypes.SET_USER, payload: response });
    if (response.user.role !== "superuser") {
      history.push("/welcome");
    }
  } catch (e) {
    if (e.code === 401 || e.code === 403) {
      dispatch(logoutUser());
    }
  }
};

export const getAllUser =
  (page = 1, filter) =>
  async (dispatch) => {
    let filterStr = "";
    if (filter.gender) {
      filterStr = filterStr + `&gender=${filter.gender}`;
    }
    if (filter.role) {
      filterStr = filterStr + `&role=${filter.role}`;
    }
    if (filter.level) {
      filterStr = filterStr + `&level=${filter.level}`;
    }
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `profile/?page=${page}${filterStr}`
      );
      dispatch({ type: ActionTypes.SET_ALL_USER, payload: response });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const postAUser = (newUser, setShowAdd, showAdd) => async (dispatch) => {
  try {
    const form_data = new FormData();
    if (newUser.profile_image[0]) {
      form_data.append("profile_image", newUser.profile_image[0]);
    }

    form_data.append("username", newUser.username);
    form_data.append("first_name", newUser.first_name);
    form_data.append("last_name", newUser.last_name);
    form_data.append("email", newUser.email);
    form_data.append("role", newUser.role);
    form_data.append("password", newUser.password);
    form_data.append("gender", newUser.gender);
    form_data.append("assigned_astrologer", newUser.assigned_astrologer);
    form_data.append("level", newUser.level);
    form_data.append("phone", newUser.phone);

    const response = await fetchWrapper.postMultipart(
      COMMON_URL + `profile/`,
      form_data,
      true
    );
    dispatch({ type: ActionTypes.SET_NEW_USER, payload: response });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Staff added successfully",
      },
    });
    setShowAdd(!showAdd);
  } catch (error) {
    if (error?.data.email) {
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: true,
          message: error.data.email[0],
        },
      });
    } else if (error?.data.username) {
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: true,
          message: error.data.username[0],
        },
      });
    } else {
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: true,
          message: "Something went wrong",
        },
      });
      console.log(error);
    }
  }
};
export const editUser = (data, id, setShowAdd, showAdd) => async (dispatch) => {
  try {
    const form_data = new FormData();
    if (data.profile_image[0]) {
      form_data.append("profile_image", data.profile_image[0]);
    }
    form_data.append("username", data.username);
    form_data.append("first_name", data.first_name);
    form_data.append("last_name", data.last_name);
    form_data.append("email", data.email);
    form_data.append("role", data.role);
    data.password && form_data.append("password", data.password);
    form_data.append("gender", data.gender);
    data.assigned_astrologer &&
      form_data.append("assigned_astrologer", data.assigned_astrologer);
    form_data.append("level", data.level);
    form_data.append("phone", data.phone);
    data.showin_app && form_data.append("showin_app", data.showin_app);

    // const user = { ...data };
    // if (user.profile_image) {
    //   delete user.profile_image;
    // }
    const updatedUser = await fetchWrapper.patch(
      COMMON_URL + `profile/${id}/`,
      form_data,
      true
    );
    dispatch({ type: ActionTypes.EDIT_USER, payload: updatedUser });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Staff edited successfully",
      },
    });
    setShowAdd(!showAdd);
  } catch (e) {
    console.log(e);
  }
};

export const deleteAUser = (userId) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `profile/${userId}`);
    dispatch({ type: ActionTypes.REMOVE_DELETED_USER, payload: userId });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Staff deleted successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserProfile = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(COMMON_URL + `profile/${id}/`);
    dispatch({ type: ActionTypes.GET_USER_PROFILE, payload: response });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const changePassword =
  (data, id, setShowChangePassword) => async (dispatch) => {
    try {
      const response = await fetchWrapper.post(
        COMMON_URL + `user/${id}/set_password/`,
        data
      );
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD,
        payload: response,
      });
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: false,
          message: "Password Changed successfully",
        },
      });
      setShowChangePassword(false);
    } catch (e) {
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: true,
          message: "Old password didn't match",
        },
      });
    }
  };

// Moderator
export const getAllModerator = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `profile/?role=moderator`
    );
    dispatch({
      type: ActionTypes.GET_ALL_MODERATOR,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};
// Astrologer
export const getAllAstrologer = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `profile/?role=astrologer`
    );
    dispatch({
      type: ActionTypes.GET_ALL_ASTROLOGER,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};
