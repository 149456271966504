import * as ActionTypes from "../ActionTypes";

const initialState = {
  helperType: [],
  helperTemplate: [],
  totalhelperTemplate: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_MOD_HELPER_TYPE:
      return {
        ...state,
        helperType: action.payload.results,
      };
    case ActionTypes.POST_MOD_HELPER_TYPE:
      return {
        ...state,
        helperType: [action.payload, ...state.helperType],
      };
    case ActionTypes.DELETE_MOD_HELPER_TYPE:
      const tempClar = [...state.helperType];
      const index = tempClar.findIndex((user) => user.id === action.payload);
      tempClar.splice(index, 1);
      return {
        ...state,
        helperType: tempClar,
      };

    case ActionTypes.GET_MOD_HELPER_TEMPLATE:
      return {
        ...state,
        helperTemplate: action.payload.results,
        totalhelperTemplate: action.payload.count,
      };
    case ActionTypes.POST_MOD_HELPER_TEMPLATE:
      return {
        ...state,
        helperTemplate: [action.payload, ...state.helperTemplate],
      };
    case ActionTypes.DELETE_MOD_HELPER_TEMPLATE:
      const tempHelp = [...state.helperTemplate];
      const indexHelp = tempHelp.findIndex(
        (user) => user.id === action.payload
      );
      tempHelp.splice(indexHelp, 1);
      return {
        ...state,
        helperTemplate: tempHelp,
        totalhelperTemplate: state.totalhelperTemplate - 1,
      };
    case ActionTypes.EDIT_MOD_HELPER_TEMPLATE:
      let helpType = [...state.helperType];
      const editedIndex = helpType.findIndex(
        (type) => type.id === action.payload.id
      );
      if (editedIndex !== -1) {
        const templt = [...helpType[editedIndex].templates];
        const editedTmpIndx = templt.findIndex(
          (type) => type.id === action.payload.id
        );
        if (editedTmpIndx !== 1) {
          helpType[editedIndex].templates[editedTmpIndx] = action.payload;
        }
      }
      return { ...state, helperType: [...helpType] };
    default:
      return state;
  }
}
