import * as ActionTypes from "../ActionTypes";

const initialState = {
  settings: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_GENERAL_SETTINGS:
      return { ...state, settings: action.payload.results };
    case ActionTypes.POST_GENERAL_SETTINGS:
      const tempSettings = [...state.settings];
      const index = tempSettings.findIndex(
        (content) => content.id === action.payload.id
      );
      if (index !== -1) {
        tempSettings[index] = action.payload;
      }
      return { ...state, settings: tempSettings };
    default:
      return state;
  }
}
