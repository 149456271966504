import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Note from "./Note";
import { postCustomerNotes } from "../../_redux/actions/ActionCustomers";
import { connect } from "react-redux";

const Notes = ({ notes, postCustomerNotes, customer }) => {
  const [assign, setAssign] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data, notes) => {
    const tempNote = { ...data, customer_id: customer.id };
    postCustomerNotes(tempNote);
    setAssign(false);
  };

  return (
    <div className="notes">
      <Button
        className="btn-primary btn-round btn-block"
        onClick={() => {
          setAssign(true);
        }}
      >
        Add Note
      </Button>
      {notes.length > 0 && notes.map((item, index) => <Note item={item} />)}

      <Modal
        show={assign}
        onHide={() => {
          setAssign(false);
        }}
        className="modal-bottom assign-moderator"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formGroupName">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter note message here"
                {...register("note_content")}
              />
            </Form.Group>
            <Button className="btn-primary btn-round btn-sm" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { postCustomerNotes })(Notes);
