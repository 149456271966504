import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Layout from "../../../layouts/Layout";
import {
  getModHelperTemplate,
  deleteModHelperTemplate,
} from "../../../_redux/actions/ActionSettings";

import { connect } from "react-redux";
import PaginationComp from "../../../components/PaginationComp";
import Loader from "../../../components/Loader";
import AddHelper from "./AddHelper";
import AddHelperType from "./AddHelperType";
import swal from "sweetalert";

const Helper = ({
  getModHelperTemplate,
  deleteModHelperTemplate,
  totalHelperTemplate,
  helperTemplate,
  loading,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showAddType, setShowAddType] = useState(false);
  const [helperData, setHelperData] = useState(null);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getModHelperTemplate(page);
  }, [getModHelperTemplate, page]);
  return (
    <Layout>
      <div className="questions-section">
        <Row className="mt-3">
          <Col md={4}>
            <h5 className="font-weight-bold text-danger mb-2">
              Moderator Helpers
            </h5>
          </Col>
          <Col md={{ span: 3, offset: 3 }}>
            <Button
              className="btn-primary btn-round float-right mb-2"
              onClick={() => {
                setShowAddType(!showAddType);
                setShowAdd(false);
              }}
            >
              Add Helper Type
            </Button>
          </Col>
          <Col md={2}>
            <Button
              className="btn-primary btn-round float-right mb-2"
              onClick={() => {
                setShowAdd(!showAdd);
                setShowAddType(false);
                setHelperData(null);
              }}
            >
              Add Helper
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={12}>
            {showAddType && (
              <AddHelperType showAdd={showAdd} setShowAdd={setShowAdd} />
            )}
          </Col>
          <Col md={12}>
            {showAdd && (
              <AddHelper
                setShowAdd={setShowAdd}
                helperData={helperData}
                setHelperData={setHelperData}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Type</th>
                      <th>Helper</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      helperTemplate.length > 0 ? (
                        helperTemplate.map((item, index) => {
                          return (
                            <tr>
                              <td width="5%">{(page - 1) * 10 + index + 1}</td>
                              <td>{item.category_title}</td>
                              <td width="55%">{item.helper_text}</td>
                              <td width="10%" className="text-center">
                                <FaEdit
                                  className="text-info pointer mr-1"
                                  onClick={() => {
                                    setShowAdd(!showAdd);
                                    setHelperData(item);
                                  }}
                                />
                                <FaTrashAlt
                                  className="text-danger pointer"
                                  onClick={() => {
                                    swal({
                                      title: "Are you sure?",
                                      text: "Once deleted, you will not be able to recover !",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        deleteModHelperTemplate(item.id);
                                      }
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className="text-center">No results found</div>
                          </td>
                        </tr>
                      )
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </Table>

               
                <PaginationComp
                  total={totalHelperTemplate}
                  page={page}
                  setPage={setPage}
                />
              
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  helperTemplate: state.moderatorHelper.helperTemplate,
  totalHelperTemplate: state.moderatorHelper.totalhelperTemplate,
  loading: state.ui.loading,
});

export default connect(mapStateToProps, {
  getModHelperTemplate,
  deleteModHelperTemplate,
})(Helper);
