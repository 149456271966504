import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  addNewZodiac,
  getAllZodiacs,
} from "../../_redux/actions/ActionZodiacs";
import { connect } from "react-redux";

const AddZodiac = ({
  setAddZodShow,
  addZodShow,
  addNewZodiac,
  getAllZodiacs,
}) => {
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    getAllZodiacs();
  }, [getAllZodiacs]);

  const onSubmit = (data) => {
    const form_data = new FormData();
    for (const each_data in data) {
      if (each_data === "image") {
        form_data.append(`${each_data}`, data[each_data][0]);
      } else {
        form_data.append(`${each_data}`, data[each_data]);
      }
    }
    addNewZodiac(form_data);
    setAddZodShow(false);
  };

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={3}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Zodiac Name</Form.Label>
                <Form.Control required type="text" {...register("name")} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  type="text"
                  {...register("description")}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Upload Zodiac Image</Form.Label>
                <Form.Control
                  required
                  type="file"
                  accept="image/png, image/jpeg"
                  {...register("image")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2} className="d-flex">
              <Button
                className="btn-primary btn-round btn-sm mr-2"
                type="submit"
              >
                Add
              </Button>
              <Button
                className="btn-primary btn-round btn-sm"
                type="submit"
                onClick={() => setAddZodShow(false)}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  allZodiacs: state?.zodiacs?.zodiacs?.results,
  loading: state?.zodiacs?.isLoading,
});

export default connect(mapStateToProps, { addNewZodiac, getAllZodiacs })(
  AddZodiac
);
