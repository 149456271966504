import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck, FaEdit, FaTrash, FaWindowClose } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  editAstroHelperTemplate,
  deleteAstroHelperTemplate,
  getAstroHelperType,
  editModHelperTemplate,
  deleteModHelperTemplate,
  getModHelperType,
} from "../../../_redux/actions/ActionSettings";

const SingleTemplate = ({
  temp,
  setNewTemp,
  newTemp,
  setMessage,
  role,
  category,
  editAstroHelperTemplate,
  deleteAstroHelperTemplate,
  getAstroHelperType,
  editModHelperTemplate,
  deleteModHelperTemplate,
  getModHelperType,
  setShowHelper,
  setIgnoreClick,
}) => {
  const [showEditHelper, setShowEditHelper] = useState(false);
  const [editHelperLoading, setEditHelperLoading] = useState(false);

  const handleEditHelper = async (cat, id) => {
    const data = {
      category: cat,
      text: newTemp,
    };
    const dataMod = {
      category: cat,
      helper_text: newTemp,
    };
    if (newTemp !== "") {
      setEditHelperLoading(true);
      if (role === "astrologer") {
        await editAstroHelperTemplate(data, id);
        getAstroHelperType();
      } else {
        await editModHelperTemplate(dataMod, id);
        getModHelperType();
      }
      setEditHelperLoading(false);
    } else {
      toast.error("Text cannot be empty");
      setEditHelperLoading(false);
    }

    setShowEditHelper(false);
  };

  const handleDeleteTemplate = async (id) => {
    if (role === "astrologer") {
      await deleteAstroHelperTemplate(id);
      getAstroHelperType();
    } else {
      await deleteModHelperTemplate(id);
      getModHelperType();
    }
  };

  return (
    <div className="helper-list d-flex justify-content-between align-items-center">
      {showEditHelper ? (
        <Form.Control
          type="text"
          defaultValue={role === "astrologer" ? temp.text : temp.helper_text}
          onChange={(e) => setNewTemp(e.target.value)}
          className="mr-2"
        />
      ) : (
        <div
          onClick={() => {
            setMessage(
              "response_text",
              role === "astrologer" ? temp.text : temp.helper_text
            );
            setShowHelper(false);
            setIgnoreClick(false);
          }}
        >
          {role === "astrologer" ? temp.text : temp.helper_text}
        </div>
      )}
      <div className="d-flex align-items-center">
        {showEditHelper ? (
          <>
            {editHelperLoading ? (
              <VscLoading className="spin mx-2" />
            ) : (
              <>
                <FaCheck
                  className="text-info pointer"
                  size={18}
                  onClick={() => {
                    handleEditHelper(category, temp.id);
                  }}
                />
                <FaWindowClose
                  className="text-danger pointer ml-2"
                  size={18}
                  onClick={() => setShowEditHelper(false)}
                />
              </>
            )}
          </>
        ) : (
          <>
            <FaEdit
              className="text-info pointer"
              size={18}
              onClick={() => {
                setShowEditHelper(true);
              }}
            />
            <FaTrash
              className="text-danger pointer ml-1"
              size={18}
              onClick={() => {
                swal({
                  text: "Are you sure you want to delete this template?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((confirm) => {
                  if (confirm) {
                    handleDeleteTemplate(temp.id);
                  }
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  helper:
    state?.user?.user?.user.role === "astrologer"
      ? state.AstrologerHelper?.helperType
      : state.moderatorHelper?.helperType,
});

export default connect(mapStateToProps, {
  editAstroHelperTemplate,
  deleteAstroHelperTemplate,
  getAstroHelperType,
  editModHelperTemplate,
  deleteModHelperTemplate,
  getModHelperType,
})(SingleTemplate);
