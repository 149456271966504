import * as ActionTypes from "../ActionTypes";

const initialState = {
  pageContent: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_PAGE_CONTENT:
      return { ...state, pageContent: action.payload };
    case ActionTypes.EDIT_PAGE_CONTENT:
      const tempContent = [...state.pageContent];
      const index = tempContent.findIndex(
        (content) => content.id === action.payload.id
      );
      if (index !== -1) {
        tempContent[index] = action.payload;
      }
      return { ...state, pageContent: tempContent };
    default:
      return state;
  }
}
