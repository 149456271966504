import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Col, Container, Row, Card, ProgressBar } from "react-bootstrap";
import {
  FaCaretUp,
  FaInfoCircle,
  FaQuestionCircle,
  FaUserCircle,
} from "react-icons/fa";
import { getTicket } from "../_redux/actions/ActionTicket";
import { getGeneralSettings } from "../_redux/actions/ActionSettings";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { fetchWrapper } from "../_helpers";
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const WelcomePage = ({ user, getTicket, settings, getGeneralSettings }) => {
  const history = useHistory();
  const [stackQuestion, setStackQuestion] = useState([])
  
  useEffect(() => {
    getGeneralSettings();
  }, [getGeneralSettings]);

  const onTicketHandle = () => {
    getTicket(history);
  };

  useEffect(() => {
    fetchWrapper.get(COMMON_URL + `profile/${user.id}/questions_in_moderator_dashboard`)
      .then(res => setStackQuestion(res.questions))
      .catch(err => console.log(err))
  }, [])
  return (
    <>
      <Header ticket={true} onTicketHandle={onTicketHandle} />
      <div className="full-width-content profile-section">
        <Container>
          <Row>
            <Col md={5}>
              <div className="welcome-message">
                <p className="font-weight-bold">Dear {user.user?.first_name}</p>
                {settings && (
                  <div
                    dangerouslySetInnerHTML={{ __html: settings[0]?.notice }}
                  />
                )}

                <p className="mb-0 mt-3">With Regards</p>
                <p className="font-weight-bold mb-0">Karmic Astrology Team</p>
              </div>
              <div className="welcome-message mt-5">
                <h6 className="font-weight-bold mb-3">Questions in your stack</h6>
                {stackQuestion?.map(q => (
                  <div className="rounded p-2 mb-1" style={{ backgroundColor: "#b7d784"}}>
                    <p className="text-dark font-weight-bold">
                      <img
                        src={q.customer.profile_image || "/assets/images/avatar.png"}
                        height="32px"
                        alt="profile"
                        className="profile-img mr-2"
                      />
                      {q?.customer?.name}
                      <small className="float-right">
                        {q.created && new Date(q.created).toLocaleString()}
                      </small>
                    </p>
                    <p className="text-dark">
                      {q?.question}
                    </p>
                  </div>
                ))}
              </div>

            </Col>
            <Col md={7}>
              <div className="profile-wrapper">
                <Card>
                  <div className="profile-header">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          user.profile_image
                            ? user.profile_image
                            : "/assets/images/avatar.png"
                        }
                        alt="profile"
                        className="profile-img"
                      />
                      <div>
                        <h5>
                          {user.user?.first_name} {user.user?.last_name}
                        </h5>
                        <p className="textCapitalize">
                          {user.user.role} id: {user.user?.profile_id}
                        </p>
                      </div>
                    </div>
                    {user.user.role === "moderator" && (
                      <div className="designation">
                        <img
                          src={`/assets/images/${user.level}.svg`}
                          alt=""
                          className="level-img"
                        />
                        {user.level}
                      </div>
                    )}
                    {user.user.role === "moderator" && (
                      <div className="queries-welcome">
                        <h5 className="text-center">
                          {user.point ? user.point : "0"}
                        </h5>
                        <p>Points</p>
                      </div>
                    )}
                  </div>

                  <Row>
                    <Col md={4}>
                      <div className="profile-info">
                        <span className="d-flex align-items-center">
                          <FaUserCircle className="mr-1 text-info" size="18" />
                          <p className="info-heading text-info">
                            Identification
                          </p>
                        </span>
                        <Row className="mt-3 ml-2">
                          <Col md={12}>
                            <div className="queries mb-3">
                              <h5>{user.user.username}</h5>
                              <p>Username</p>
                            </div>
                          </Col>
                          {user.user.role === "moderator" && (
                            <Col md={12}>
                              <div className="queries">
                                <h5>
                                  <img
                                    src={
                                      user.assigned_astrologer_info
                                        ?.profile_image
                                        ? user.assigned_astrologer_info
                                          ?.profile_image
                                        : "/assets/images/avatar.png"
                                    }
                                    alt=""
                                    className="astro-img"
                                  />{" "}
                                  {user.assigned_astrologer_info?.name
                                    ? user.assigned_astrologer_info?.name
                                    : "Not Assigned"}
                                </h5>
                                <p>Assigned astrologer</p>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="profile-info">
                        <div className="d-flex justify-content-between">
                          <span className="d-flex align-items-center">
                            <FaInfoCircle
                              className="mr-1 text-danger"
                              size="18"
                            />
                            <p className="info-heading text-danger">
                              Information
                            </p>
                          </span>
                        </div>
                        <Row className="mt-3 ml-2">
                          <Col md={12}>
                            <div className="queries mb-3">
                              <h5>{user.gender}</h5>
                              <p>Gender</p>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="queries">
                              <h5>{user.user.phone}</h5>
                              <p>Contact</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {user.user.role !== "qa" && (
                      <Col md={4}>
                        <div className="profile-queries">
                          <span className="d-flex align-items-center">
                            <FaQuestionCircle
                              color="#1C70BE"
                              className="mr-1"
                              size="18"
                            />
                            <p className="info-heading">Queries received</p>
                          </span>
                          <Row className="mt-3 ml-2">
                            <Col md={4}>
                              <div className="queries">
                                <h5 className="text-center">
                                  {user.stats?.total}
                                </h5>
                                <p className="text-center">Total</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="queries">
                                <h5 className="text-center">
                                  {user.stats?.paid}
                                </h5>
                                <p className="text-center">Paid</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="queries">
                                <h5 className="text-center">
                                  {user.stats?.free}
                                </h5>
                                <p className="text-center">Free</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Card>
                {user.user.role !== "qa" && (
                  <Card className="my-3">
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <div className="bg-xlight d-flex justify-content-between align-items-center">
                            <h5>
                              No. of new{" "}
                              <span className="text-success">paid</span> queries
                              received in an hour
                            </h5>
                            <div>
                              <FaCaretUp className="text-success mr-1" />
                              <p className="text-success">
                                {user.stats?.hourly_paid}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="bg-xlight d-flex justify-content-between align-items-center">
                            <h5>
                              No. of new{" "}
                              <span className="text-danger">free</span> queries
                              received in an hour
                            </h5>
                            <div>
                              <FaCaretUp className="text-success mr-1" />
                              <p className="text-success">
                                {user.stats?.hourly_free}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3 stat">
                        <Col md={5}>
                          <p>No. of queries answered this week</p>
                        </Col>
                        <Col md={5}>
                          <ProgressBar
                            variant="success"
                            now={
                              (user.stats?.weekly_total /
                                user.stats?.weekly_total) *
                              100
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <h6>{user.stats?.weekly_total}</h6>
                        </Col>
                      </Row>
                      <Row className="mt-3 stat">
                        <Col md={5}>
                          <p>No. of paid queries answered this week</p>
                        </Col>
                        <Col md={5}>
                          <ProgressBar
                            variant="info"
                            now={
                              (user.stats?.weekly_paid /
                                user.stats?.weekly_total) *
                              100
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <h6>{user.stats?.weekly_paid}</h6>
                        </Col>
                      </Row>
                      <Row className="mt-3 stat">
                        <Col md={5}>
                          <p>No. of free queries answered this week</p>
                        </Col>
                        <Col md={5}>
                          <ProgressBar
                            variant="warning"
                            now={
                              (user.stats?.weekly_free /
                                user.stats?.weekly_total) *
                              100
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <h6>{user.stats?.weekly_free}</h6>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  settings: state.GeneralSettings.settings,
});

const mapDispatchToProps = { getTicket, getGeneralSettings };

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
