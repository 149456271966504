import * as ActionTypes from "../ActionTypes";

const initialState = {
  dashboardStats: null,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_STATS:
      return { ...state, dashboardStats: action.payload };

    default:
      return state;
  }
}
