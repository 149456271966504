import * as ActionTypes from "../ActionTypes";

const initialState = {
  report: [],
  totalReports: 0,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_SALES_REPORT:
      return { ...state, report: action.payload.results, totalReports : action.payload.count };
    
    default:
      return state;
  }
}
