import React, { useState, useEffect } from "react";
import { Col, Form, Row, Card, Table, Button, Modal } from "react-bootstrap";
import { FaCheckCircle, FaChevronDown, FaChevronUp, FaEye, FaTimesCircle } from "react-icons/fa";
import Layout from "../layouts/Layout";
import { getCustomerQuestions, changeQuestionState } from "../_redux/actions/ActionQuestions";
import { getAllModerator } from "../_redux/actions/ActionUser";
import { assignModerator } from "../_redux/actions/ActionQuestions";
import { connect } from "react-redux";
import PaginationComp from "../components/PaginationComp";
import { useParams, useHistory, Link } from "react-router-dom";
import Loader from "../components/Loader";
import ModeratorSelect from "../components/ModeratorSelect";
import ReactFlagsSelect from "react-flags-select";
import ReactCountryFlag from "react-country-flag";
import ReactTooltip from "react-tooltip";
import { fetchWrapper } from "../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const Questions = ({
  getCustomerQuestions,
  customerQuestions,
  totalQuestions,
  getAllModerator,
  assignModerator,
  moderator,
  loading,
  changeQuestionState
}) => {
  const { pageNum } = useParams();
  const history = useHistory();

  const [showDate, setShowDate] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [assign, setAssign] = useState(false);
  const [question, setQuestion] = useState(null);
  const [page, setPage] = useState(pageNum);
  const [modPage] = useState(1);
  const [moderators, setModerators] = useState([]);
  const MAX_LENGTH = 120;
  const [showAssignees, setShowAssignees] = useState(false);
  const [changeState, setChangeState] = useState({ id: null, bool: false });
  const [updatedValue, setUpdatedValue] = useState({ id: null, value: "" })
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    status: "",
    country: "",
    state: "",
    query: "",
  });
  const [selectedMod, setSelectedMod] = useState(null);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  useEffect(() => {
    getCustomerQuestions(page, filter);
    getAllModerator();
  }, [filter, getAllModerator, getCustomerQuestions, page]);

  const handleAssign = (question) => {
    if (!selectedMod) {
      toast.error("Select a moderator!")
      return
    }
    assignModerator(question.id, selectedMod?.user.profile_id);
    setAssign(false);
  };

  const handleSearchUser = async (nameString) => {
    if (nameString) {
      if (/^\d+$/.test(nameString)) {
        try {
          const response = await fetchWrapper.get(
            COMMON_URL + `profile/${nameString}/`
          );
          setModerators(() => [response]);
        } catch (e) { }
      } else if (nameString) {
        try {
          const response = await fetchWrapper.get(
            COMMON_URL +
            `profile/?page=${modPage}&role=moderator&name=${nameString}`
          );
          setModerators(response.results);
        } catch (e) { }
      }
    }
  };
  const handleStateChange = (id, e) => {
    const state = e.target.value
    changeQuestionState(id, state)
      .then(
        res => {
          setUpdatedValue({ id: id, value: res.state })
          toast.success(`Question ${id} state changed to ${state}`)
        }
      )
      .catch(err => toast.error("Something went wrong!"))
    setChangeState({})
  }
  return (
    <Layout>
      <div className="questions-section">
        <Row>
          <Col md={2}>
            <div className="select-toggle">
              <div className="selector" onClick={() => setShowDate(!showDate)}>
                <p>Date</p>
                {showDate ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showDate && (
                <div className="select-wrapper">
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            from: e.target.value,
                          }));
                          // setShowDate(false);
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            to: e.target.value,
                          }));
                          setShowDate(false);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        query: "today",
                      }));
                      setShowDate(false);
                    }}
                  >
                    Today
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        query: "week",
                      }));
                      setShowDate(false);
                    }}
                  >
                    This Week
                  </Button>
                  {/* <Button className="btn-outline-primary-100">
                    This Month
                  </Button> */}
                </div>
              )}
            </div>
          </Col>
          <Col md={2}>
            <div className="select-toggle">
              <div className="selector" onClick={() => setShowType(!showType)}>
                <p>Type</p>
                {showType ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showType && (
                <div className="select-wrapper-right">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, status: "Paid" }));
                      setShowType(false);
                    }}
                  >
                    Paid
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, status: "Free" }));
                      setShowType(false);
                    }}
                  >
                    Free
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={3}>
            <ReactFlagsSelect
              selected={filter.country}
              onSelect={(code) => {
                setFilter((prev) => ({
                  ...prev,
                  country: code,
                }));
              }}
              showSelectedLabel={true}
              showSecondarySelectedLabel={true}
              selectedSize={16}
              showOptionLabel={true}
              showSecondaryOptionLabel={true}
              optionsSize={16}
              placeholder="Select a country"
              searchable={true}
              alignOptionsToRight={false}
              fullWidth={true}
              disabled={false}
            />
          </Col>

          <Col md={{ span: 2, offset: 3 }}>
            <div className="select-toggle">
              <div
                className="selector"
                onClick={() => setShowStatus(!showStatus)}
              >
                <p>Status</p>
                {showStatus ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showStatus && (
                <div className="select-wrapper-right">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        state: "Raw",
                      }));
                      setShowStatus(false);
                    }}
                  >
                    Raw
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        state: "Translated by Moderator",
                      }));
                      setShowStatus(false);
                    }}
                  >
                    Translated by Moderator
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        state: "Translated by Astrologer",
                      }));
                      setShowStatus(false);
                    }}
                  >
                    Translated by Astrologer
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        state: "In Progress",
                      }));
                      setShowStatus(false);
                    }}
                  >
                    In Progress
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        state: "Deleted",
                      }));
                      setShowStatus(false);
                    }}
                  >
                    Deleted
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        state: "Locked",
                      }));
                      setShowStatus(false);
                    }}
                  >
                    Locked
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {filter.from && (
              <span className="user-wrapper">
                <p>{filter && filter.from}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      from: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.to && (
              <span className="user-wrapper">
                <p>{filter && filter.to}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      to: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.status && (
              <span className="user-wrapper">
                <p>{filter && filter.status}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      status: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.country && (
              <span className="user-wrapper">
                <p>{filter && filter.country}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      country: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.state && (
              <span className="user-wrapper">
                <p>{filter && filter.state}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      state: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.query && (
              <span className="user-wrapper">
                <p>{filter && filter.query}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      query: "",
                    }));
                  }}
                />
              </span>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <tbody>
                    {!loading ? customerQuestions.length > 0 &&  (
                      customerQuestions.map((item, index) => (
                        <tr className={item.status === "Paid" ? "bg-gray" : ""}>
                          <td className="text-danger text-center" width="5%">
                            {item.status === "Paid" ? "$" : ""}
                          </td>

                          <td width="10%">
                            <ReactCountryFlag
                              className="emojiFlag"
                              countryCode={item.customer.country_code}
                              style={{
                                fontSize: "2em",
                              }}
                              title={item.customer.country}
                              svg
                            />
                            <p className="os-name">{item.customer?.agent}</p>
                          </td>
                          <td width="20%">
                            <Link to={`/customer/profile/${item.customer.id}`}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item.customer.profile_image
                                      ? item.customer.profile_image
                                      : "/assets/images/avatar.png"
                                  }
                                  className="user-img"
                                  alt="user"
                                />
                                <div className="ml-2">
                                  <h6 className="name font-weight-bold">
                                    {item.customer.name}
                                  </h6>
                                  <h6 className="day">
                                    {item.customer.user.id}
                                  </h6>
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td
                            width="35%"
                            className="description pointer"
                            data-tip={item.question}
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="float"
                            />
                            {item.question?.substring(0, MAX_LENGTH)}
                            <div className="day">
                              {item.id} | {item.created.slice(0, 10)}
                            </div>
                          </td>
                          <td width="20%" className="type">
                            {
                              changeState.id == item.id ? <Form.Control
                                as="select"
                                defaultValue={item.state}
                                onChange={(e) => handleStateChange(item.id, e)}
                              >
                                <option value="Raw">Raw</option>
                                <option value="Answered">Answered</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Translated by Moderator">Translated by Moderator</option>
                                <option value="Translated by Astrologer">Translated by Astrologer</option>
                                <option value="Deleted">Deleted</option>
                                <option value="Locked">Locked</option>
                              </Form.Control>
                                :
                                <p onDoubleClick={() => setChangeState({ id: item.id, bool: true })}>
                                  {updatedValue.id === item.id ? updatedValue.value : item.state}
                                  <div className="day">
                                    {item.moderator &&
                                      <Link to={`/moderator/profile/${item.moderator}`}>{`Mod: ${item.moderator}`}
                                      </Link>}
                                    {item.moderator && item.astrologer && " | "}
                                    {
                                      item.astrologer &&
                                      <Link to={`/moderator/profile/${item.astrologer}`}>{`Astro: ${item.astrologer}`}
                                      </Link>}
                                  </div>
                                </p>

                            }
                          </td>
                          <td width="15%">
                            {item.state !== "Answered" &&
                              item.state !== "Locked" &&
                              item.state !== "Deleted" && (
                                <img
                                  src="/assets/images/play.png"
                                  alt="assign"
                                  size="1.5rem"
                                  className="assign-btn"
                                  onClick={() => {
                                    setAssign(true);
                                    setQuestion(item);
                                  }}
                                />
                              )}
                            <FaEye
                              size="1.5rem"
                              className="assign-btn ml-3"
                              onClick={() => {
                                setQuestion(item);
                                setShowAssignees(true)
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : <Loader/>}
                  </tbody>
                </Table>
                {totalQuestions > 10 && (
                  <PaginationComp
                    total={totalQuestions}
                    page={page}
                    setPage={handleSetPage}
                  />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      {question && (
        <Modal
          show={assign}
          onHide={() => {
            setAssign(false);
            setQuestion(null);
          }}
          className="modal-bottom assign-moderator"
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign to Moderator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ModeratorSelect
              handleUserInput={handleSearchUser}
              data={moderators}
              handleDataSelect={(newUser) => {
                setSelectedMod(newUser);
              }}
              selectedMod={selectedMod}
              setSelectedMod={setSelectedMod}
            />
            <div className="question-bg">
              <p className="assign-question">
                {question.question?.substring(0, MAX_LENGTH)}
              </p>
            </div>
            <div className="user-detail">
              <div className="d-flex align-items-center">
                <img
                  src={
                    question.customer.profile_image
                      ? question.customer.profile_image
                      : "/assets/images/avatar.png"
                  }
                  className="user-img"
                  alt="user"
                />
                <div className="ml-2">
                  <h6 className="name">{question.customer.name}</h6>
                  <h6 className="day">{question.created.slice(0, 10)}</h6>
                </div>
              </div>
              <div>
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode={question.customer.country_code}
                  style={{
                    fontSize: "2em",
                  }}
                  title={question.customer.country}
                  svg
                />
                <p className="os-name">{question.customer.country}</p>
              </div>
              <div>
                <h5>{question.status === "Paid" ? "$" : ""}</h5>
                <p>{question.status}</p>
              </div>
            </div>
            <Button
              className="btn-primary btn-round"
              onClick={() => handleAssign(question)}
            >
              Assign
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {question && (
        <Modal
          show={showAssignees && !assign}
          onHide={() => {
            setAssign(false);
            setQuestion(null);
          }}
        // className="modal-bottom assign-moderator"
        >
          <Modal.Header closeButton>
            <Modal.Title>Assigned Moderators</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              {question.assigned_moderator_listing.map(
                question => (
                  <tr>
                    <td>
                      <img
                        src={question.moderator_image || "/assets/images/avatar.png"}
                        className="user-img"
                        alt="user"
                        height="32px"
                      />
                    </td>
                    <td>{question.fullname}</td>
                    <td>{question.assigned_datetime && new Date(question.assigned_datetime).toLocaleString()}</td>
                    <td>{question.is_active ? <FaCheckCircle className="text-success" /> : <FaTimesCircle className="text-danger" />}</td>
                  </tr>
                )
              )}
            </Table>
          </Modal.Body>
        </Modal>
      )}
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  customerQuestions: state.customerQuestions.customerQuestions,
  totalQuestions: state.customerQuestions.totalQuestions,
  moderator: state.user.moderator,
  loading: state.ui.loading,
});

// export default QuestionManagement;
export default connect(mapStateToProps, {
  getCustomerQuestions,
  getAllModerator,
  assignModerator,
  changeQuestionState
})(Questions);
