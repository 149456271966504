import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

// Get Feedback

export const getFeedback =
  (page = 1) =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `qa/qa-support/?page=${page}`
      );
      dispatch({
        type: ActionTypes.GET_QUERY_FEEDBACK,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const getSingleFeedback = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `qa/qa-support/?customer=${id}`
    );
    dispatch({
      type: ActionTypes.GET_SINGLE_QUERY_FEEDBACK,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const postQueryResponse = (data, id, history) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `qa/qa-support/${id}/responses/`,
      data
    );
    dispatch({
      type: ActionTypes.POST_QUERY_RESPONSE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Message sent successfully",
      },
    });
    history.push("/welcome");
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: "Error",
      },
    });
  }
};
