import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getAllZodiacs, editZodiac } from "../../_redux/actions/ActionZodiacs";
import { connect } from "react-redux";

const EditZodiac = ({ setEditZodShow, zod, getAllZodiacs, editZodiac }) => {
  const [imageFile, setImageFile] = useState(zod.image && zod.image);
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    getAllZodiacs();
  }, [getAllZodiacs]);

  const onSubmit = (data) => {
    const form_data = new FormData();
    for (const each_data in data) {
      form_data.append(`${each_data}`, data[each_data]);
    }
    if (typeof imageFile === "object") {
      form_data.append("image", imageFile);
    }
    editZodiac(form_data, zod.id);
    setEditZodShow(false);
  };

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={3}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Zodiac Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  defaultValue={zod.name}
                  {...register("name")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  type="text"
                  defaultValue={
                    zod.description ? zod.description : "No Description"
                  }
                  {...register("description")}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                controlId="formGroupName"
                className="d-flex h-100 w-100 align-items-center justify-content-center"
              >
                <label
                  htmlFor="changeImage"
                  className="d-flex align-items-center justify-content-center w-100 h-100 mb-0"
                >
                  <div className="d-flex w-100">
                    {zod.image ? (
                      <img
                        src={
                          typeof imageFile === "string"
                            ? imageFile
                            : URL.createObjectURL(imageFile)
                        }
                        alt="avatar"
                        className="table-avatar"
                      />
                    ) : (
                      <div className="table-avatar bg-secondary">here</div>
                    )}
                  </div>
                  <div className="btn btn-primary btn-round btn-sm">
                    Choose Image
                  </div>
                </label>
                <input
                  id="changeImage"
                  className="d-none"
                  required
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImageFile(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2} className="d-flex">
              <Button
                className="btn-primary btn-round btn-sm mr-2"
                type="submit"
              >
                Save
              </Button>
              <Button
                className="btn-primary btn-round btn-sm"
                type="submit"
                onClick={() => setEditZodShow(false)}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  allZodiacs: state?.zodiacs?.zodiacs?.results,
  loading: state?.zodiacs?.isLoading,
});

export default connect(mapStateToProps, { getAllZodiacs, editZodiac })(
  EditZodiac
);
