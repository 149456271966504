import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Message from "./Message";
import { getPinnedMessages } from "../../_redux/actions/ActionCustomers";
import { connect } from "react-redux";
import Loader from "../../components/Loader";

const PinnedMessage = ({
  customer,
  pinnedMessage,
  getPinnedMessages,
  loading,
}) => {
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getPinnedMessages(customer.id, filter);
  }, [getPinnedMessages, customer.id, filter]);

  return (
    <div className="pinned">
      <div className="roles">
        <Form>
          <Form.Check
            inline
            type="radio"
            name="pinned"
            label="All"
            onClick={() => setFilter("")}
          />
          <Form.Check
            inline
            type="radio"
            name="pinned"
            label="Moderator"
            onClick={() => setFilter("moderator")}
          />
          <Form.Check
            inline
            type="radio"
            name="pinned"
            label="Customer"
            onClick={() => setFilter("customer")}
          />
          <Form.Check
            inline
            type="radio"
            name="pinned"
            label="Astrologer"
            onClick={() => setFilter("astrologer")}
          />
        </Form>
      </div>
      <div className="message-wrapper">
        <div className="messages flex-column">
          {pinnedMessage ? (
            pinnedMessage.length > 0 &&
            pinnedMessage.map((item, index) => (
              <div className="d-flex mb-2">
                <img
                  src={
                    item.response_from_user.image
                      ? item.response_from_user.image
                      : "/assets/images/avatar.png"
                  }
                  className="message-avatar"
                  alt=""
                />
                <Message
                  type={item.response_from_user.role}
                  name={item.response_from_user.name}
                  message={item.response_text}
                  pin={item.pinned}
                  created={item.created_at}
                  id={item.id}
                  entity_type={item.entity_type}
                />
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pinnedMessage: state.customers.pinnedMessage,
  loading: state.ui.loading,
});

export default connect(mapStateToProps, {
  getPinnedMessages,
})(PinnedMessage);
