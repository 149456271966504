import * as ActionTypes from "../ActionTypes";

const initialState = {
  zodiacs: {},
  isLoading: false,
  count: 0,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.IS_ZODIAC_LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.STOP_ZODIAC_LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.GET_ZODIACS:
      return {
        ...state,
        zodiacs: action.payload,
        // count: action.payload.count,
      };
    case ActionTypes.ADD_NEW_ZODIAC:
      return {
        ...state,
        zodiacs: [action.payload, ...state.zodiacs],
      };

    case ActionTypes.EDIT_ZODIAC:
      const zod = [...state.zodiacs];
      const editedIndex = zod.findIndex((zod) => zod.id === action.payload.id);
      if (editedIndex !== -1) {
        zod[editedIndex] = action.payload;
      }
      return { ...state, zodiacs: zod };

    case ActionTypes.DELETE_ZODIAC:
      const tempZod = [...state.zodiacs];
      const index = tempZod.findIndex((zod) => zod.id === action.payload);
      if (index !== -1) {
        tempZod.splice(index, 1);
      }
      return { ...state, zodiacs: tempZod };

    default:
      return state;
  }
}
