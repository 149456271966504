import React from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import useComponentVisible from "../hooks/useComponentVisible";

const ModeratorSelect = ({
  handleUserInput,
  data = [],
  handleDataSelect,
  selectedMod,
  setSelectedMod,
}) => {
  // const [input, setInput] = useState("");
  // const [moderatorList, setModeratorList] = useState([]);

  const { componentVisible, setComponentVisible } =
    useComponentVisible(false);

  // const updateInput = (input) => {
  //   const filtered = searchData.filter((moderator) => {
  //     return moderator.user.first_name
  //       .toLowerCase()
  //       .includes(input.toLowerCase());
  //   });
  //   setInput(input);
  //   setModeratorList(filtered);
  // };

  return (
    <div className="search-bar">
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Search Moderator"
            id="searchInput"
            defaultValue={selectedMod && selectedMod.user?.first_name}
            value={
              selectedMod &&
              `${selectedMod.user?.first_name} ${selectedMod.user?.last_name}`
            }
            onClick={() =>
              !componentVisible ? setComponentVisible(true) : null
            }
            onChange={(e) => {
              handleUserInput(e.target.value);
              setSelectedMod(null);
            }}
            className={selectedMod && `bg-gray`}
          ></Form.Control>
          {/* <Form.Control
            type="text"
            placeholder="Search User"
            defaultValue={selectedMod && selectedMod.user?.first_name}
            value={selectedMod && selectedMod.user?.first_name}
            onChange={(e) => updateInput(e.target.value)}
            onClick={() => setSelectedMod(null)}
          ></Form.Control> */}
        </Form.Group>
      </Form>
      {componentVisible && (
        <div className="mod-search-wrapper">
          {data ? (
            data
              .map((data) => (
                <div
                  className="search-items pointer"
                  key={data.id}
                  onClick={() => {
                    handleDataSelect(data);
                    setComponentVisible(false);
                    document.getElementById("searchInput").value = "";
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            data.profile_image
                              ? data.profile_image
                              : "/assets/images/avatar.png"
                          }
                          className="user-img"
                          alt="user"
                        />
                        <div className="ml-2">
                          <h6 className="user-name">
                            {data.user.first_name} {data.user.last_name}
                          </h6>
                          <h6 className="os">{data.user.username}</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="os">{data.level}</div>
                    </Col>
                    <Col md={3}>
                      <div className="queries">
                        <h5 className="text-center">
                          {data.point ? data.point : "0"}
                        </h5>
                        <p className="text-center">Points</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))
              .slice(0, 3)
          ) : (
            <Spinner animation="border" variant="danger" />
          )}
        </div>
      )}
    </div>
  );
};

export default ModeratorSelect;
