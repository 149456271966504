import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  getModHelperType,
  postModHelperType,
  deleteModHelperType,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

const AddHelperType = ({
  getModHelperType,
  postModHelperType,
  deleteModHelperType,
  helperType,
}) => {
  useEffect(() => {
    getModHelperType();
  }, [getModHelperType]);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    postModHelperType(data);
  };
  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Helper Type Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="eg. Confusion"
                  {...register("category")}
                />
              </Form.Group>
            </Col>

            <Col md={8}>
              <div className="scroll-wrapper">
                <Row>
                  {helperType.length > 0 &&
                    helperType.map((item, index) => (
                      <Col md={6}>
                        <div className="question-type-list d-flex align-items-center justify-content-between">
                          <p>{item.category}</p>
                          <FaTrashAlt
                            color="#BC2A20"
                            size={14}
                            className="pointer"
                            onClick={() => deleteModHelperType(item.id)}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  helperType: state.moderatorHelper.helperType,
});

const mapDispatchToProps = {
  getModHelperType,
  postModHelperType,
  deleteModHelperType,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHelperType);
