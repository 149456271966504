import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { connect } from "react-redux";
import PaginationComp from "../../components/PaginationComp";
import Layout from "../../layouts/Layout";
import { useParams, useHistory } from "react-router-dom";

import {
  getAllZodiacs,
  deleteZodiac,
} from "../../_redux/actions/ActionZodiacs";
import AddZodiac from "./AddZodiac";
import EditZodiac from "./EditZodiac";
import Loader from "../../components/Loader";
import swal from "sweetalert";

const Zodiacs = ({
  getAllZodiacs,
  allZodiacs,
  loading,
  totalResults,
  deleteZodiac,
}) => {
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum);
  const [addZodShow, setAddZodShow] = useState(false);
  const [editZodShow, setEditZodShow] = useState(false);
  const [curZod, setCurZod] = useState({});
  const history = useHistory();

  useEffect(() => {
    getAllZodiacs(page);
  }, [getAllZodiacs, page]);

  const handleAddZodiac = (data) => {
    setAddZodShow(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("are you sure you want to delete this zodiac?")) {
      deleteZodiac(id);
      getAllZodiacs(page);
    }
  };
  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  return (
    <Layout>
      <div className="questions-section">
        <Row className="mt-3">
          <Col md={4}>
            <h5 className="font-weight-bold text-danger">Zodiacs</h5>
          </Col>
          <Col md={{ span: 4, offset: 4 }}>
            <Button
              className="btn-primary btn-round float-right"
              onClick={() => handleAddZodiac()}
            >
              Add a Zodiac
            </Button>
          </Col>
        </Row>
        {addZodShow && (
          <div className="mt-3 p-2">
            <Row>
              <Col md={12}>
                <AddZodiac
                  setAddZodShow={setAddZodShow}
                  addZodShow={addZodShow}
                />
              </Col>
            </Row>
          </div>
        )}
        {editZodShow && (
          <div className="mt-3 p-2">
            <Row>
              <Col md={12}>
                <EditZodiac
                  setEditZodShow={setEditZodShow}
                  editZodShow={editZodShow}
                  zod={curZod}
                />
              </Col>
            </Row>
          </div>
        )}
        <Row className="mt-2">
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Zodiac</th>
                      <th>Description</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      allZodiacs?.length > 0 ? (
                        allZodiacs?.map((zod, index) => (
                          <tr>
                            <td width="5%">{(page - 1) * 10 + index + 1}</td>
                            <td>
                              <div className="rounded-circle d-flex align-items-center">
                                {zod?.image ? (
                                  <img
                                    src={zod?.image}
                                    className="table-avatar"
                                    alt="avatar"
                                  />
                                ) : (
                                  <div className="noImg"></div>
                                )}
                                <div className="text-secondary ml-2">
                                  {zod.name}
                                </div>
                              </div>
                            </td>
                            <td width="50%">
                              {zod.description
                                ? zod.description
                                : "No Description"}
                            </td>
                            <td className="text-center">
                              <FaEdit
                                className="text-info pointer mr-1"
                                onClick={() => {
                                  setEditZodShow(true);
                                  setCurZod(zod);
                                }}
                              />
                              <FaTrashAlt
                                className="text-danger pointer"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover !",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      handleDelete(zod.id);
                                    }
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="text-center">No results found</div>
                          </td>
                        </tr>
                      )
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </Table>
                <PaginationComp
                  total={totalResults}
                  page={page}
                  setPage={handleSetPage}
                  showGoto={false}
                />
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  allZodiacs: state?.zodiacs?.zodiacs,
  totalResults: state?.zodiacs?.count,
  loading: state?.zodiacs?.isLoading,
});

export default connect(mapStateToProps, { getAllZodiacs, deleteZodiac })(
  Zodiacs
);
