import * as Yup from 'yup';

export const regValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});

export const editRegValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().test(
		'empty-check',
		'Password must be at least 8 characters',
		(password) => password.length === 0 || password.length >= 8
	),
	confirmPassword: Yup.string().when('password', {
		is: (password) => password.length > 0,
		then: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Confirm Password is required'),
	}),
});

export const logValidationSchema = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
});

export const resetPasswordValidation = Yup.object().shape({
	token: Yup.string().required('token is required'),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.matches(/\d/, 'Password must contain atleast one number')
		.matches(/[a-zA-Z]/, 'Password must contain atleast one letter'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});

export const chalan_validation = Yup.object().shape({
	challan_from_id: Yup.string().required('Chilling center is required'),
	challan_to_id: Yup.string().required('Industry is required'),
	milk_quantity: Yup.number('Milk Quantity must be a number')
		.typeError('Must be a valid number.')
		.positive('Milk Quantity must be positive')
		.required('Milk Quantity  is required'),
	fat_percentage: Yup.number('Fat must be a number')
		.typeError('Must be a valid number.')
		.positive('Fat must be positive')
		.required('Fat is required'),
	snf_percentage: Yup.number('SNF must be a number')
		.typeError('Must be a valid number.')
		.positive('SNF must be positive')
		.required('SNF is required'),
	challan_number: Yup.string().required('Challan Number is required'),
	// lacto_percentage: Yup.number('Lacto must be a number')
	// 	.typeError('Must be a valid number.')
	// 	.positive('Lacto must be positive')
	// ,
	vehicle_number: Yup.string(),
	issued_by: Yup.string(),
	// purchase_date: Yup.string().required('Purchase Date is required'),
});

export const schedule_validation = Yup.object().shape({
	chilling_center: Yup.string().required('Chilling center is required'),
	factory: Yup.string().required('Industry is required'),
});

export const chilling_sangh_and_cooperative_validation = Yup.object().shape({
	company_name: Yup.string().required('Name is required'),
	phone_number: Yup.string().required('Phone Number is required'),
	pan: Yup.string().required('Pan is required'),
	address: Yup.string().required('Address is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
});

export const fact0ry_validation = Yup.object().shape({
	company_name: Yup.string().required('Name is required'),
	phone_number: Yup.string().required('Phone Number is required'),
	pan: Yup.string().required('Pan is required'),
	address: Yup.string().required('Address is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	color_code: Yup.string().required('Color is required'),
});

export const daily_sales_validation = Yup.object().shape({
	title: Yup.string(),
	address: Yup.string(),
	milk_quantity: Yup.number('Must be a number')
		.typeError('Must be a valid number.')
		.required('Milk Quantity is required')
		.positive('Milk Quantity must be positive'),
	paid_amount: Yup.number('Must be a number')
		.typeError('Must be a valid number.')
		.required('Amount Recieved is required')
		.positive('Milk Quantity must be positive'),
});
