//authentication types
export const SET_USER = "SET_USER";
export const SET_NEW_USER = "SET_NEW_USER";
export const REMOVE_DELETED_USER = "REMOVE_DELETED_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const CLEAR_USER_LOADING = "CLEAR_USER_LOADING";
export const SET_LOGGEDIN = "SET_LOGGEDIN";
export const SET_LOGGEDOUT = "SET_LOGGEDOUT";
export const SET_ALL_USER = "SET_ALL_USER";
export const EDIT_USER = "EDIT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
// export const SET_USER_ERROR = "SET_USER_ERROR";
export const GET_ALL_MODERATOR = "GET_ALL_MODERATOR";
export const GET_ALL_ASTROLOGER = "GET_ALL_ASTROLOGER";
export const GET_USER_PROFILE = "GET_USER_PROFILE";

// user interface types
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

// questions types
export const GET_QUESTION_TYPES = "GET_QUESTION_TYPES";
export const POST_QUESTION_TYPES = "POST_QUESTION_TYPES";
export const DELETE_QUESTION_TYPES = "DELETE_QUESTION_TYPES";
export const GET_TEMPLATE_QUESTIONS = "GET_TEMPLATE_QUESTIONS";
export const POST_TEMPLATE_QUESTIONS = "POST_TEMPLATE_QUESTIONS";
export const DELETE_TEMPLATE_QUESTION = "DELETE_TEMPLATE_QUESTION";
export const EDIT_TEMPLATE_QUESTION = "EDIT_TEMPLATE_QUESTION";

// zodiacs
export const GET_ZODIACS = "GET_ZODIACS";
export const ADD_NEW_ZODIAC = "ADD_NEW_ZODIAC";
export const EDIT_ZODIAC = "EDIT_ZODIAC";
export const DELETE_ZODIAC = "DELETE_ZODIAC";
export const IS_ZODIAC_LOADING = "IS_ZODIAC_LOADING";
export const STOP_ZODIAC_LOADING = "STOP_ZODIAC_LOADING";

// Customer Questions
export const GET_CUSTOMER_QUESTIONS = "GET_CUSTOMER_QUESTIONS";
export const GET_QUESTIONS_ONLY = "GET_QUESTIONS_ONLY";
export const GET_MODERATOR_QUESTIONS = "GET_MODERATOR_QUESTIONS";
export const GET_QUESTIONS_RESPONSE = "GET_QUESTIONS_RESPONSE";
export const PATCH_QUESTIONS_RESPONSE = "PATCH_QUESTIONS_RESPONSE";
export const GET_FILTERED_RESPONSE = "GET_FILTERED_RESPONSE";
export const ASSIGN_MODERATOR = "ASSIGN_MODERATOR";
export const GET_QUESTION_BYID = "GET_QUESTION_BYID";
export const ADD_CUSTOMER_CREDIT = "ADD_CUSTOMER_CREDIT";

// response
export const POST_RESPONSE = "POST_RESPONSE";

// Settings

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const POST_NEW_NOTIFICATION = "POST_NEW_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const GET_SYSTEM_MESSAGES = "GET_SYSTEM_MESSAGES";
export const POST_SYSTEM_MESSAGE = "POST_SYSTEM_MESSAGE";
export const DELETE_SYSTEM_MESSAGE = "DELETE_SYSTEM_MESSAGE";
export const EDIT_SYSTEM_MESSAGE = "EDIT_SYSTEM_MESSAGE";
export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";
export const POST_GENERAL_SETTINGS = "POST_GENERAL_SETTINGS";

export const GET_ALL_CLARIFICATION_TYPE = "GET_ALL_CLARIFICATION_TYPE";
export const POST_CLARIFICATION_TYPE = "POST_CLARIFICATION_TYPE";
export const DELETE_CLARIFICATION_TYPE = "DELETE_CLARIFICATION_TYPE";

export const GET_ALL_CLARIFICATION_TEMPLATE = "GET_ALL_CLARIFICATION_TEMPLATE";
export const POST_CLARIFICATION_TEMPLATE = "POST_CLARIFICATION_TEMPLATE";
export const DELETE_CLARIFICATION_TEMPLATE = "DELETE_CLARIFICATION_TEMPLATE";
export const EDIT_CLARIFICATION_TEMPLATE = "EDIT_CLARIFICATION_TEMPLATE";

// Page content
export const GET_PAGE_CONTENT = "GET_PAGE_CONTENT";
export const EDIT_PAGE_CONTENT = "EDIT_PAGE_CONTENT";

// Dashboard
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";

// Customer
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const GET_PINNED_MESSAGES = "GET_PINNED_MESSAGES";
export const GET_CUSTOMER_NOTES = "GET_CUSTOMER_NOTES";
export const POST_CUSTOMER_NOTES = "POST_CUSTOMER_NOTES";
export const DELETE_CUSTOMER_NOTES = "DELETE_CUSTOMER_NOTES";
export const GET_CUSTOMER_IMAGES = "GET_CUSTOMER_IMAGES";
export const POST_CUSTOMER_IMAGES = "POST_CUSTOMER_IMAGES";
export const DELETE_CUSTOMER_IMAGE = "DELETE_CUSTOMER_IMAGE";

// Ticket
export const GET_TICKET = "GET_TICKET";

// Astrologer helper
export const GET_ASTROLOGER_HELPER_TYPE = "GET_ASTROLOGER_HELPER_TYPE";
export const POST_ASTROLOGER_HELPER_TYPE = "POST_ASTROLOGER_HELPER_TYPE";
export const DELETE_ASTROLOGER_HELPER_TYPE = "DELETE_ASTROLOGER_HELPER_TYPE";
export const GET_ASTROLOGER_HELPER_TEMPLATE = "GET_ASTROLOGER_HELPER_TEMPLATE";
export const EDIT_ASTROLOGER_HELPER_TEMPLATE =
  "EDIT_ASTROLOGER_HELPER_TEMPLATE";
export const POST_ASTROLOGER_HELPER_TEMPLATE =
  "POST_ASTROLOGER_HELPER_TEMPLATE";
export const DELETE_ASTROLOGER_HELPER_TEMPLATE =
  "DELETE_ASTROLOGER_HELPER_TEMPLATE";

// Moderator helper
export const GET_MOD_HELPER_TYPE = "GET_MOD_HELPER_TYPE";
export const POST_MOD_HELPER_TYPE = "POST_MOD_HELPER_TYPE";
export const DELETE_MOD_HELPER_TYPE = "DELETE_MOD_HELPER_TYPE";
export const GET_MOD_HELPER_TEMPLATE = "GET_MOD_HELPER_TEMPLATE";
export const EDIT_MOD_HELPER_TEMPLATE = "EDIT_MOD_HELPER_TEMPLATE";
export const POST_MOD_HELPER_TEMPLATE = "POST_MOD_HELPER_TEMPLATE";
export const DELETE_MOD_HELPER_TEMPLATE = "DELETE_MOD_HELPER_TEMPLATE";

//sales reports
export const GET_SALES_REPORT = "GET_SALES_REPORT";

// point systems
export const GET_POINTS_REPORT = "GET_POINTS_REPORT";

// Feedback
export const GET_QUERY_FEEDBACK = "GET_QUERY_FEEDBACK";
export const POST_QUERY_RESPONSE = "POST_QUERY_RESPONSE";
export const GET_SINGLE_QUERY_FEEDBACK = "GET_SINGLE_QUERY_FEEDBACK";
