import React, { useEffect, useState } from "react";
import { Form, Col, Button, Tabs, Tab } from "react-bootstrap";
import Layout from "../layouts/Layout";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import {
  getPageContent,
  editPageContent,
} from "../_redux/actions/ActionSettings";
import { connect } from "react-redux";
import Loader from "../components/Loader";

const PageContent = ({
  getPageContent,
  pageContent,
  editPageContent,
  loading,
}) => {
  const [content, setContent] = useState({});

  useEffect(() => {
    getPageContent();
  }, [getPageContent]);

  useEffect(() => {
    if (pageContent.length > 0) {
      const tempContent = {};
      pageContent.map(
        (item, index) =>
          (tempContent[item.slug] = EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(item.content))
          ))
      );
      setContent(tempContent);
    }
  }, [pageContent]);

  // const { register, handleSubmit } = useForm({
  //   mode: "onBlur",
  //   defaultValues: {},
  // });

  const onSubmit = (content, slug) => {
    content = content && draftToHtml(convertToRaw(content.getCurrentContent()));
    editPageContent(content, slug);
  };
  return (
    <Layout>
      <div className="setting-wrapper">
        <Tabs
          defaultActiveKey="about-us"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {!loading ? (
            pageContent.length > 0 &&
            pageContent
              .map((item, index) => (
                <Tab eventKey={item.slug} title={item.title}>
                  <div id={item.slug}>
                    <Col md={12}>
                      <Form.Group>
                        <Editor
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          editorState={content[item.slug]}
                          onEditorStateChange={(e) =>
                            setContent((prev) => {
                              const editorContent = { ...prev };
                              editorContent[item.slug] = e;
                              return editorContent;
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Button
                        className="btn-primary btn-round"
                        onClick={() => onSubmit(content[item.slug], item.slug)}
                      >
                        Save
                      </Button>
                    </Col>
                  </div>
                </Tab>
              ))
              .reverse()
          ) : (
            <Loader />
          )}
        </Tabs>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  pageContent: state.pageContent.pageContent,
  loading: state.ui.loading,
});

export default connect(mapStateToProps, { getPageContent, editPageContent })(
  PageContent
);
