import React, { useState } from "react";
import { GoPin } from "react-icons/go";
import { RiMoreFill } from "react-icons/ri";
import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../../_redux/ActionTypes";
import { useDispatch } from "react-redux";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const Message = ({ type, name, message, pin, created, id, entity_type }) => {
  const [more, setMore] = useState(false);
  // const [pinned, setPin] = useState(pin);
  const dispatch = useDispatch();

  const onPin = async (pinned, id) => {
    const response = await fetchWrapper.patch(
      COMMON_URL + `question/response/${id}/`,
      { pinned: !pin }
    );
    // setPin(!pinned);
    dispatch({ type: ActionTypes.PATCH_QUESTIONS_RESPONSE, payload: response });
    setMore(!more);
  };

  return (
    <div className={`single-message ${type}`}>
      {pin && <GoPin className="pin text-danger" size={12} />}
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="font-weight-bold">{name}</h5>
        <div className="more-icon">
          <RiMoreFill
            onClick={() => setMore(!more)}
            style={{ cursor: "pointer" }}
          />
          {more && (
            <div className="more-wrapper">
              <ul>
                <li onClick={() => onPin(pin, id)}>
                  <span>
                    <GoPin className="text-danger" size={12} />
                  </span>
                  {pin ? "Unpin" : "Pin"}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <h6>{type}</h6>
        <h6>{entity_type}</h6>
      </div>
      <p>{message}</p>
      <p className="time">{created}</p>
    </div>
  );
};

export default Message;
