import * as ActionTypes from "../ActionTypes";

const initialState = {
  customers: [],
  customer: null,
  pinnedMessage: [],
  notes: [],
  images: [],
  totalCustomers: [],
  credit: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload.results,
        totalCustomers: action.payload.count,
      };
    case ActionTypes.GET_CUSTOMER_PROFILE:
      return {
        ...state,
        customer: action.payload,
      };

    case ActionTypes.EDIT_CUSTOMER:
      const tempCustomer = [...state.customers];
      const editedIndex = tempCustomer.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        tempCustomer[editedIndex] = action.payload;
      }
      return { ...state, customers: tempCustomer };
    case ActionTypes.GET_PINNED_MESSAGES:
      return { ...state, pinnedMessage: action.payload };

    case ActionTypes.PATCH_QUESTIONS_RESPONSE:
      const tempResponse = [...state.pinnedMessage];
      if (action.payload.pinned) {
        const index = tempResponse.findIndex(
          (res) => res.id === action.payload.id
        );
        if (index !== -1) {
          tempResponse[index] = action.payload;
        } else {
          tempResponse.push(action.payload);
        }
      } else {
        const index = tempResponse.findIndex(
          (res) => res.id === action.payload.id
        );
        tempResponse.splice(index, 1);
      }
      return {
        ...state,
        pinnedMessage: tempResponse,
      };
    case ActionTypes.GET_CUSTOMER_NOTES:
      return { ...state, notes: action.payload };
    case ActionTypes.GET_CUSTOMER_IMAGES:
      return { ...state, images: action.payload };
    case ActionTypes.DELETE_CUSTOMER_IMAGE:
      const tempImages = [...state.images];
      const indexImg = tempImages.findIndex(
        (user) => user.id === action.payload.id
      );
      tempImages.splice(indexImg, 1);
      return { ...state, images: tempImages };

    case ActionTypes.POST_CUSTOMER_IMAGES:
      return {
        ...state,
        images: [...action.payload.images.map(img => ({ image: img })), ...state.images],
      };

    case ActionTypes.DELETE_CUSTOMER_NOTES:
      const tempNotes = [...state.notes];
      const index = tempNotes.findIndex((user) => user.id === action.payload);
      tempNotes.splice(index, 1);
      return { ...state, notes: tempNotes };
    case ActionTypes.POST_CUSTOMER_NOTES:
      return {
        ...state,
        notes: [action.payload, ...state.notes],
      };
    case ActionTypes.ADD_CUSTOMER_CREDIT:
      return {
        ...state,
        credit: [action.payload, ...state.credit],
      };

    default:
      return state;
  }
}
