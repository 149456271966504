import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Layout from "../../layouts/Layout";
import { connect } from "react-redux";
import {
  getTemplateQuestions,
  deleteQuestionTemplate,
} from "../../_redux/actions/ActionQuestions";
import AddQuestionTemplate from "./AddQuestionTemplate";
import AddQuestionType from "./AddQuestionType";
import PaginationComp from "../../components/PaginationComp";
import Loader from "../../components/Loader";
import swal from "sweetalert";

const QuestionManagement = ({
  getTemplateQuestions,
  deleteQuestionTemplate,
  questionTemplate,
  totalQuestionTemplate,
  loading,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showAddType, setShowAddType] = useState(false);
  const [page, setPage] = useState(1);
  const [questionTemp, setQuestionTemp] = useState(null);
  useEffect(() => {
    getTemplateQuestions(page);
  }, [getTemplateQuestions, page]);
  return (
    <Layout>
      <div className="questions-section">
        <Row className="mt-3">
          <Col md={4} sm={12} xs={12}>
            <h5 className="font-weight-bold text-danger mb-2">
              Question Templates
            </h5>
          </Col>
          <Col md={{ span: 3, offset: 3 }} xs={12}>
            <Button
              className="btn-primary btn-round float-right mb-2"
              onClick={() => {
                setShowAddType(!showAddType);
                setShowAdd(false);
              }}
            >
              Add Template Type
            </Button>
          </Col>
          <Col md={2}>
            <Button
              className="btn-primary btn-round float-right mb-2"
              onClick={() => {
                setShowAdd(!showAdd);
                setShowAddType(false);
              }}
            >
              Add Template
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={12}>
            {showAddType && <AddQuestionType setShowAddType={setShowAddType} />}
          </Col>
          <Col md={12}>
            {showAdd && (
              <AddQuestionTemplate
                setShowAdd={setShowAdd}
                questionTemp={questionTemp}
                setQuestionTemp={setQuestionTemp}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Type</th>
                      <th>Question</th>
                      <th>Created At</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      questionTemplate.length > 0 &&
                      questionTemplate.map((item, index) => (
                        <tr>
                          <td width="5%">{(page - 1) * 10 + index + 1}</td>
                          <td width="10%">{item.question_type_title}</td>
                          <td width="40%" className="type">
                            {item.question}
                          </td>
                          <td width="10%" className="type">
                            {item.created.slice(0, 10)}
                          </td>
                          <td width="20%" className="type text-center">
                            <FaEdit
                              className="text-info pointer mr-1"
                              onClick={() => {
                                setShowAdd(!showAdd);
                                setQuestionTemp(item);
                              }}
                            />

                            <FaTrashAlt
                              className="text-danger pointer"
                              onClick={() => {
                                swal({
                                  title: "Are you sure?",
                                  text: "Once deleted, you will not be able to recover !",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    deleteQuestionTemplate(item.id);
                                  }
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </Table>
                {totalQuestionTemplate > 10 && (
                  <PaginationComp
                    total={totalQuestionTemplate}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  questionTemplate: state.questionTemplate.questionTemplate,
  totalQuestionTemplate: state.questionTemplate.totalQuestionTemplate,
  loading: state.ui.loading,
});

// export default QuestionManagement;
export default connect(mapStateToProps, {
  getTemplateQuestions,
  deleteQuestionTemplate,
})(QuestionManagement);
