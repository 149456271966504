import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  postNewQuestionTemplate,
  getQuestionTypes,
  editQuestionTemplate,
} from "../../_redux/actions/ActionQuestions";
import { connect } from "react-redux";

const AddQuestionTemplate = ({
  postNewQuestionTemplate,
  getQuestionTypes,
  questionTypes,
  questionTemp,
  editQuestionTemplate,
  setShowAdd,
  setQuestionTemp,
}) => {
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    questionTemp
      ? editQuestionTemplate(data, questionTemp.id)
      : postNewQuestionTemplate(data);
    setQuestionTemp(null);
    setShowAdd(false);
  };
  useEffect(() => {
    getQuestionTypes();
    return () => {};
  }, [getQuestionTypes]);

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Question Type</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={questionTemp && questionTemp.question_type}
                  {...register("question_type")}
                >
                  {questionTypes.length > 0 &&
                    questionTypes.map((item, index) => (
                      <option
                        value={
                          questionTemp ? questionTemp.question_type : item.id
                        }
                        key={item.id}
                      >
                        {item.question_type}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  {...register("question")}
                  defaultValue={questionTemp && questionTemp.question}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                {questionTemp ? "Edit" : "Add"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  questionTypes: state.questionTemplate.questionTypes,
});

const mapDispatchToProps = {
  getQuestionTypes,
  postNewQuestionTemplate,
  editQuestionTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddQuestionTemplate);
