import { useState, useEffect } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import { FaInfoCircle, FaUserCircle } from "react-icons/fa";
import {
  getCustomerProfile,
  getCustomerImages,
} from "../../_redux/actions/ActionCustomers";
import { getQuestionResponse } from "../../_redux/actions/ActionQuestions";
import Message from "../Chat/Message";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
const ProfileModal = ({
  match,
  getCustomerProfile,
  getCustomerImages,
  customer,
  response,
  totalResponse,
  getQuestionResponse,
  question,
}) => {
  const [showProfile, setShowProfile] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getQuestionResponse(customer.id);
  }, [customer.id, getQuestionResponse]);

  const loadFunc = () => {
    getQuestionResponse(customer.id, page + 1);
    setPage(page + 1);
  };
  useEffect(() => {
    getCustomerProfile(match.params.id);
    getCustomerImages(customer.id);
  }, [customer.id, getCustomerImages, getCustomerProfile, match.params.id]);
  return (
    <div className="mb-5">
      <Modal
        show={showProfile}
        onHide={() => setShowProfile(false)}
        className="fullWidth"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search result for "{customer.id}"</Modal.Title>
        </Modal.Header>
        <Row>
          <Col md={4}>
            <div className="leftbar modal-leftbar">
              <div className="profile-header">
                <div className="d-flex align-items-center">
                  <img
                    src={
                      customer.profile_image
                        ? customer.profile_image
                        : "/assets/images/user1.png"
                    }
                    alt="profile"
                    className="profile-img"
                  />
                  <div>
                    <h5>
                      {customer.user.first_name} {customer.user.last_name}
                    </h5>
                    <p>Customer id: {customer.id}</p>
                  </div>
                </div>
              </div>
              <div className="profile-info">
                <span className="d-flex align-items-center">
                  <FaUserCircle className="mr-1 text-info" size="18" />
                  <p className="info-heading text-info">Identification</p>
                </span>
                <Row className="mt-3 ml-2">
                  <Col md={6}>
                    <div className="queries">
                      <h5>{customer.user.username}</h5>
                      <p>Username</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="profile-info">
                <div className="d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <FaInfoCircle className="mr-1 text-danger" size="18" />
                    <p className="info-heading text-danger">Information</p>
                  </span>
                </div>
                <Row className="mt-3 ml-2">
                  <Col md={6}>
                    <div className="queries">
                      <h5>{customer.gender}</h5>
                      <p>Gender</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="queries">
                      <h5>{customer.age}</h5>
                      <p>Age</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 ml-2">
                  <Col md={6}>
                    <div className="queries">
                      <h5>{customer.dob_AD}</h5>
                      <p>Date of Birth</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="queries">
                      <h5>
                        {customer.birth_time}{" "}
                        <span className="accurate">
                          {customer.is_bt_accurate
                            ? "Accurate"
                            : "Not Accurate"}
                        </span>
                      </h5>
                      <p>Time of Birth</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 ml-2">
                  <Col md={6}>
                    <div className="queries">
                      <h5>
                        <img
                          src="/assets/images/flag1.png"
                          alt=""
                          className="flag-img"
                        />{" "}
                        {customer.country}
                      </h5>
                      <p>Country</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="queries">
                      <h5>{customer.address}</h5>
                      <p>Place</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 ml-2">
                  <Col md={6}>
                    <div className="queries">
                      <h5>{customer.phone}</h5>
                      <p>Contact</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="message-wrapper">
              <div id="scrollableDiv">
                <InfiniteScroll
                  dataLength={response.length}
                  next={loadFunc}
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    overflow: "hidden",
                    paddingTop: "10px",
                  }}
                  inverse={true}
                  hasMore={response.length < totalResponse}
                  loader={
                    <Spinner animation="border" variant="danger" size="sm" />
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {response.length > 0 &&
                    response.map((item, index) => (
                      <div className="d-flex mb-2">
                        <img
                          src={
                            item.response_from_user.image
                              ? item.response_from_user.image
                              : "/assets/images/avatar.png"
                          }
                          className="message-avatar"
                          alt=""
                        />
                        <Message
                          type={item.response_type}
                          name={item.response_from_user.first_name}
                          message={item.response_text}
                          pin={item.pinned}
                          created={item.created}
                          id={item.id}
                          entity_type={item.entity_type}
                        />
                      </div>
                    ))}
                </InfiniteScroll>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customers.customer,
  images: state.customers.images,
  question: state.ticket.ticket,
  response: state.customerQuestions.questionResponse,
  totalResponse: state.customerQuestions.totalResponse,
});
export default connect(mapStateToProps, {
  getCustomerProfile,
  getCustomerImages,
  getQuestionResponse,
})(ProfileModal);
