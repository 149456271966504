import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { FaPlusSquare, FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import SingleTemplate from "./SingleTemplate";
import { VscLoading } from "react-icons/vsc";
const SingleCategory = ({
  setMessage,
  user,
  item,
  postAstroHelperTemplate,
  postModHelperTemplate,
  getAstroHelperType,
  getModHelperType,
  handleHelper,
  setShowHelper,
  setIgnoreClick,
}) => {
  const [showCreateHelper, setShowCreateHelper] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [newTemp, setNewTemp] = useState("");

  const handleCreateHelper = async () => {
    const data = {
      category: item.id,
      text: newTemp,
    };
    const dataMod = {
      category: item.id,
      helper_text: newTemp,
    };
    if (newTemp !== "") {
      setCreateLoading(true);
      if (user.user.role === "astrologer") {
        await postAstroHelperTemplate(data);
        getAstroHelperType();
      } else {
        await postModHelperTemplate(dataMod);
        getModHelperType();
      }
      setCreateLoading(false);
      setShowCreateHelper(false);
    } else {
      toast.error("helper text should not be empty");
      setCreateLoading(false);
    }
  };

  return (
    <Card.Body className="p-0">
      <div className="helper-list d-flex flex-column justify-content-center">
        {!showCreateHelper && (
          <div
            className="d-flex align-items-center mb-2"
            onClick={() => setShowCreateHelper(true)}
          >
            <FaPlusSquare className="text-danger pointer mr-2" size={18} />
            <span className="text-danger">Create New Helper</span>
          </div>
        )}
        {showCreateHelper && (
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              onChange={(e) => setNewTemp(e.target.value)}
            />
            <div>
              {createLoading ? (
                <div className="mx-2">
                  <VscLoading className="spin" />{" "}
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <FaPlusSquare
                    className="text-info pointer ml-2 mr-2"
                    size={24}
                    onClick={() => handleCreateHelper()}
                  />
                  <FaWindowClose
                    className="text-danger pointer"
                    size={24}
                    onClick={() => setShowCreateHelper(false)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {item.templates.map((temp) => (
        <SingleTemplate
          temp={temp}
          setNewTemp={setNewTemp}
          newTemp={newTemp}
          setMessage={setMessage}
          role={user.user.role}
          category={item.id}
          handleHelper={handleHelper}
          setShowHelper={setShowHelper}
          setIgnoreClick={setIgnoreClick}
        />
      ))}
    </Card.Body>
  );
};

export default SingleCategory;
