import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { connect } from "react-redux";

import { getCustomers } from "../../_redux/actions/ActionCustomers";
import { Link, useParams, useHistory } from "react-router-dom";
import PaginationComp from "../../components/PaginationComp";
import Loader from "../../components/Loader";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

const CustomersListing = ({
  getCustomers,
  customers,
  totalCustomers,
  loading,
}) => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  useEffect(() => {
    getCustomers(page, "");
  }, [getCustomers, page]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  return (
    <>
      <Header />
      <div className="questions-section minh-100">
        <Container>
          <Row className="mt-5">
            <Col md={4}>
              <h5 className="font-weight-bold text-danger">Customers</h5>
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col md={12}>
              <div className="dashboard-table">
                <Card>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Gender</th>
                        <th>Date of birth</th>
                        <th>Device</th>
                        <th>Country</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading ? (
                        customers.length > 0 &&
                        customers.map((item, i) => (
                          <tr key={item.user.id}>
                            <td width="5%" className="text-muted">
                              {i + 1}.
                            </td>
                            <td>{item.user?.id}</td>
                            <td width="25%">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item.profile_image
                                      ? item.profile_image
                                      : "/assets/images/avatar.png"
                                  }
                                  className="user-img"
                                  alt="user"
                                />
                                <div className="ml-2">
                                  <h6 className="name">{item.name}</h6>
                                </div>
                              </div>
                            </td>
                            <td width="15%" className="description">
                              {item.user?.username}
                            </td>
                            <td width="15%" className="type textCapitalize">
                              {item.gender}
                            </td>

                            <td width="15%" className="type textCapitalize">
                              {item.dob_AD}
                            </td>
                            <td width="15%" className="type textCapitalize">
                              {item.agent}
                            </td>
                            <td width="15%" className="type">
                              {item.country}
                            </td>
                            <td width="10%" className="text-center">
                              <Link to={`/customers/profile/${item.id}`}>
                                <FaEye className="text-success pointer mr-2" />
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </Table>
                  {totalCustomers > 10 && (
                    <PaginationComp
                      total={totalCustomers}
                      page={page}
                      setPage={handleSetPage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
  totalCustomers: state.customers.totalCustomers,
  loading: state.ui.loading,
});

const mapDispatchToProps = { getCustomers };

export default connect(mapStateToProps, mapDispatchToProps)(CustomersListing);
