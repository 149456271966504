import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 30px;
  list-style: none;
  height: 50px;
  letter-spacing: 0.3px;
  text-decoration: none;
  opacity: 0.7;
  &:hover {
    color: #fff;
    opacity: 1;
  }
`;

const Submenu = styled.div`
  background-color: #a8241c;
  padding: 0 10px;
`;

const DropDownLink = styled(NavLink)`
  height: 50px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0.7;
  .menu-items {
    span {
      font-size: 12px;
    }
  }
  &:hover {
    color: #fff;
  }
`;

const SubMenu = ({ item, currentPath, showMenu }) => {
  const isMenuOpen = React.useMemo(() => {
    if (!item.subNav) return false;
    const index = item.subNav.findIndex((nav) => nav.path === currentPath);
    if (index !== -1) return true;
    return false;
  }, [currentPath, item.subNav]);

  const [subnav, setSubnav] = useState(isMenuOpen);
  return (
    <>
      <SidebarLink
        to={item.path}
        onClick={() => item.subNav && setSubnav(!subnav)}
        activeClassName="navbar__link--active"
        exact={true}
      >
        <div className="menu-items" data-tip={showMenu ? null : item.title}>
          {item.icon}
          <span>{item.title}</span>
          {!showMenu && <ReactTooltip place="top" type="dark" effect="float" />}
        </div>
        <div className="chevron">
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      <Submenu>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <DropDownLink
                to={item.path}
                key={index}
                activeClassName="navbar__link--active"
                exact={true}
              >
                <div className="menu-items" data-tip={item.title}>
                  {item.icon}
                  <span>{item.title}</span>
                  {/* {!showMenu && (
                    <ReactTooltip place="top" type="dark" effect="solid" />
                  )} */}
                </div>
              </DropDownLink>
            );
          })}
      </Submenu>
    </>
  );
};

export default SubMenu;
