import * as ActionTypes from "../ActionTypes";

const initialState = {
  messages: [],
  totalMessages: 0,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_SYSTEM_MESSAGES:
      return {
        ...state,
        messages: action.payload.results,
        totalMessages: action.payload.count,
      };
    case ActionTypes.POST_SYSTEM_MESSAGE:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
        totalMessages: state.totalMessages + 1,
      };
    case ActionTypes.DELETE_SYSTEM_MESSAGE:
      const tempMsg = [...state.messages];
      const index = tempMsg.findIndex((user) => user.id === action.payload);
      tempMsg.splice(index, 1);
      return {
        ...state,
        messages: tempMsg,
        totalMessages: state.totalMessages - 1,
      };
    case ActionTypes.EDIT_SYSTEM_MESSAGE:
      const msgTemp = [...state.messages];
      const editedIndex = msgTemp.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        msgTemp[editedIndex] = action.payload;
      }
      return { ...state, messages: msgTemp };
    default:
      return state;
  }
}
