import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import Question from "./reducers/question";
import Zodiacs from "./reducers/zodiacs";
import customerQuestions from "./reducers/customerQuestions";
import User from "./reducers/user";
import UI from "./reducers/ui";
import Notifications from "./reducers/systemNotifications";
import Messages from "./reducers/systemMessages";
import clarificationTemplate from "./reducers/clarificationTemplate";
import clarificationType from "./reducers/clarificationType";
import dashboardStats from "./reducers/dashboardStats";
import pageContent from "./reducers/pageContent";
import Customers from "./reducers/customers";
import Ticket from "./reducers/ticket";
import GeneralSettings from "./reducers/generalSettings";
import AstrologerHelper from "./reducers/astrologerHelper";
import moderatorHelper from "./reducers/moderatorHelper";
import sales from "./reducers/salesReport";
import points from "./reducers/pointsReport";
import feedback from "./reducers/feedback";

export const ConfigureStore = () => {
  let middleware =
    process.env.NODE_ENV === "development"
      ? applyMiddleware(thunk, logger)
      : applyMiddleware(thunk);

  const appReducer = combineReducers({
    user: User,
    ui: UI,
    questionTemplate: Question,
    zodiacs: Zodiacs,
    notifications: Notifications,
    messages: Messages,
    clarificationTemplate: clarificationTemplate,
    clarificationType: clarificationType,
    customerQuestions: customerQuestions,
    dashboardStats: dashboardStats,
    pageContent: pageContent,
    customers: Customers,
    ticket: Ticket,
    GeneralSettings: GeneralSettings,
    AstrologerHelper: AstrologerHelper,
    moderatorHelper: moderatorHelper,
    sales: sales,
    points: points,
    feedback: feedback,
  });

  const rootReducer = (state, action) => {
    if (action.type === "SET_LOGGEDOUT") {
      state = undefined;
    }

    return appReducer(state, action);
  };

  const store = createStore(rootReducer, middleware);
  return store;
};
