export function authHeader(multipart) {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");

  if (token && token) {
    if (multipart) {
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
    }
  } else {
    return { "Content-Type": "application/json" };
  }
}
