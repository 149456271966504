import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

// General Settings

export const getGeneralSettings = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `system/system-config/`
    );
    dispatch({
      type: ActionTypes.GET_GENERAL_SETTINGS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const postGeneralSettings = (data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `system/system-config/1/`,
      data
    );
    dispatch({ type: ActionTypes.POST_GENERAL_SETTINGS, payload: response });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "General Settings updated successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const getNotifications =
  (page = 1) =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `system/system-notifications/?page=${page}`
      );
      dispatch({
        type: ActionTypes.GET_ALL_NOTIFICATIONS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const postNotification = (notification, filter) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `system/system-notifications/?${filter}`,
      notification
    );
    dispatch({ type: ActionTypes.POST_NEW_NOTIFICATION, payload: response });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Notification template added successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const deleteNotification = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(
      COMMON_URL + `system/system-notifications/${id}/`,
      id
    );
    dispatch({
      type: ActionTypes.DELETE_NOTIFICATION,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "System Notification deleted successfully",
      },
    });
  } catch (error) {}
};

export const getSystemMessages =
  (page = 1) =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `system/system-message/?page=${page}`
      );
      dispatch({
        type: ActionTypes.GET_SYSTEM_MESSAGES,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const postSystemMessage = (message) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `system/system-message/`,
      message
    );
    dispatch({ type: ActionTypes.POST_SYSTEM_MESSAGE, payload: response });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Message Template added successfully",
      },
    });
  } catch (error) {}
};

export const deleteSystemMessage = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `system/system-message/${id}/`, id);
    dispatch({
      type: ActionTypes.DELETE_SYSTEM_MESSAGE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Message template deleted successfully",
      },
    });
  } catch (error) {}
};

export const editSystemMessage = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `system/system-message/${id}/`,
      data
    );
    dispatch({ type: ActionTypes.EDIT_SYSTEM_MESSAGE, payload: response });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "System Message edited successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

// Clarification Type
export const getClarificationType = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `template/clarify-type/`
    );
    dispatch({
      type: ActionTypes.GET_ALL_CLARIFICATION_TYPE,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const postClarificationType =
  (clarificationType) => async (dispatch) => {
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.post(
        COMMON_URL + `template/clarify-type/`,
        clarificationType
      );
      dispatch({
        type: ActionTypes.POST_CLARIFICATION_TYPE,
        payload: response,
      });
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: false,
          message: "Clarification type added successfully",
        },
      });
    } catch (error) {}
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const deleteClarificationType = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `template/clarify-type/${id}/`, id);
    dispatch({
      type: ActionTypes.DELETE_CLARIFICATION_TYPE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Clarification type deleted successfully",
      },
    });
  } catch (error) {}
};

// Clarification Template

export const getClarificationTemplate =
  (page = 1) =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.LOADING_UI,
    });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `template/clarify/?page=${page}`
      );
      dispatch({
        type: ActionTypes.GET_ALL_CLARIFICATION_TEMPLATE,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };

export const postClarificationTemplate =
  (clarificationTemplate) => async (dispatch) => {
    try {
      const response = await fetchWrapper.post(
        COMMON_URL + `template/clarify/`,
        clarificationTemplate
      );
      dispatch({
        type: ActionTypes.POST_CLARIFICATION_TEMPLATE,
        payload: response,
      });
      dispatch({
        type: ActionTypes.SET_MESSAGE,
        payload: {
          error: false,
          message: "Clarification template added successfully",
        },
      });
    } catch (error) {}
  };

export const deleteClarificationTemplate = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `template/clarify/${id}/`, id);
    dispatch({
      type: ActionTypes.DELETE_CLARIFICATION_TEMPLATE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Clarification template deleted successfully",
      },
    });
  } catch (error) {}
};

export const editClarificationTemplate = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `template/clarify/${id}/`,
      data
    );
    dispatch({
      type: ActionTypes.EDIT_CLARIFICATION_TEMPLATE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Clarification template edited successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getPageContent = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `system/system-pages/`
    );
    dispatch({
      type: ActionTypes.GET_PAGE_CONTENT,
      payload: response.results,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const editPageContent = (content, slug) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `system/system-pages/${slug}/`,
      { content }
    );
    dispatch({
      type: ActionTypes.EDIT_PAGE_CONTENT,
      payload: response.results,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

// Astrologer Helper Type
export const getAstroHelperType = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `template/astrologer-helper-category/`
    );
    dispatch({
      type: ActionTypes.GET_ASTROLOGER_HELPER_TYPE,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postAstroHelperType = (clarificationType) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `template/astrologer-helper-category/`,
      clarificationType
    );
    dispatch({
      type: ActionTypes.POST_ASTROLOGER_HELPER_TYPE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper type added successfully",
      },
    });
  } catch (error) {}
};

export const deleteAstroHelperType = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(
      COMMON_URL + `template/astrologer-helper-category/${id}/`,
      id
    );
    dispatch({
      type: ActionTypes.DELETE_ASTROLOGER_HELPER_TYPE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper type deleted successfully",
      },
    });
  } catch (error) {}
};

// Astrologer Helper Template
export const getAstroHelperTemplate = (page) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `template/astrologer-helper-template/?page=${page}`
    );
    dispatch({
      type: ActionTypes.GET_ASTROLOGER_HELPER_TEMPLATE,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const postAstroHelperTemplate = (data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `template/astrologer-helper-template/`,
      data
    );
    dispatch({
      type: ActionTypes.POST_ASTROLOGER_HELPER_TEMPLATE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper template added successfully",
      },
    });
  } catch (error) {}
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const deleteAstroHelperTemplate = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(
      COMMON_URL + `template/astrologer-helper-template/${id}/`,
      id
    );
    dispatch({
      type: ActionTypes.DELETE_ASTROLOGER_HELPER_TEMPLATE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper template deleted successfully",
      },
    });
  } catch (error) {}
};

export const editAstroHelperTemplate = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `template/astrologer-helper-template/${id}/`,
      data
    );
    dispatch({
      type: ActionTypes.EDIT_ASTROLOGER_HELPER_TEMPLATE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper template Edited successfully",
      },
    });
  } catch (error) {}
};

// Moderator Helper Type
export const getModHelperType = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `system/helper-text-category/`
    );
    dispatch({
      type: ActionTypes.GET_MOD_HELPER_TYPE,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postModHelperType = (cat) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `system/helper-text-category/`,
      cat
    );
    dispatch({
      type: ActionTypes.POST_MOD_HELPER_TYPE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper type added successfully",
      },
    });
  } catch (error) {}
};

export const deleteModHelperType = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(
      COMMON_URL + `system/helper-text-category/${id}/`,
      id
    );
    dispatch({
      type: ActionTypes.DELETE_MOD_HELPER_TYPE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper type deleted successfully",
      },
    });
  } catch (error) {}
};

// moderator Helper Template
export const getModHelperTemplate = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(COMMON_URL + `system/helper-text/`);
    dispatch({
      type: ActionTypes.GET_MOD_HELPER_TEMPLATE,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const postModHelperTemplate = (temp) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `system/helper-text/`,
      temp
    );
    dispatch({
      type: ActionTypes.POST_MOD_HELPER_TEMPLATE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper template added successfully",
      },
    });
  } catch (error) {}
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

export const editModHelperTemplate = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `system/helper-text/${id}/`,
      data
    );
    dispatch({
      type: ActionTypes.EDIT_MOD_HELPER_TEMPLATE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper template Edited successfully",
      },
    });
  } catch (error) {}
};

export const deleteModHelperTemplate = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `system/helper-text/${id}/`, id);
    dispatch({
      type: ActionTypes.DELETE_MOD_HELPER_TEMPLATE,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Helper template deleted successfully",
      },
    });
  } catch (error) {}
};
