import * as ActionTypes from "../ActionTypes";

const initialState = {
  ticket: null,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_TICKET:
      return { ...state, ticket: action.payload };

    default:
      return state;
  }
}
