import { useEffect } from "react";
import { Card, Col, Row, ProgressBar } from "react-bootstrap";
import {
  FaCaretUp,
  FaInfoCircle,
  FaQuestionCircle,
  FaStar,
  FaUserCircle,
} from "react-icons/fa";
import Layout from "../../layouts/Layout";
import { connect } from "react-redux";
import { getUserProfile } from "../../_redux/actions/ActionUser";

const Profile = ({ getUserProfile, match, moderator }) => {
  useEffect(() => {
    getUserProfile(match.params.id);
  }, [getUserProfile, match.params.id]);

  const ratings = moderator && moderator.rating ? moderator.rating : {};
  const totalRatings = Object.values(ratings).reduce((a, b) => a + b, 0);

  return (
    <Layout>
      <div className="profile-section">
        {moderator && (
          <Row>
            <Col md={5}>
              <div className="profile-wrapper">
                <Card>
                  <div className="profile-header">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          moderator.profile_image
                            ? moderator.profile_image
                            : "/assets/images/avatar.png"
                        }
                        alt="profile"
                        className="profile-img"
                      />
                      <div>
                        <h5>
                          {moderator.user?.first_name}{" "}
                          {moderator.user?.last_name}
                        </h5>
                        <p className="textCapitalize">
                          {moderator.user?.role} id:{" "}
                          {moderator.user?.profile_id}
                        </p>
                      </div>
                    </div>
                    {moderator.user?.role === "moderator" && (
                      <div className="designation">
                        <img
                          src={`/assets/images/${moderator.level}.svg`}
                          alt=""
                          className="level-img"
                        />
                        {moderator.level}
                      </div>
                    )}
                    {moderator.user?.role === "moderator" && (
                      <div className="queries-welcome">
                        <h5 className="text-center">
                          {moderator.point ? moderator.point : "0"}
                        </h5>
                        <p>Points</p>
                      </div>
                    )}
                    {/* <div className="designation">
                      <img
                        src={`/assets/images/${moderator.level}.svg`}
                        alt=""
                        className="level-img"
                      />
                      {moderator.level}
                    </div>

                    <div className="query">
                      <h5 className="text-center">
                        {moderator.point ? moderator.point : "0"}
                      </h5>
                      <p>Points</p>
                    </div> */}
                  </div>
                  <div className="profile-info">
                    <span className="d-flex align-items-center">
                      <FaUserCircle className="mr-1 text-info" size="18" />
                      <p className="info-heading text-info">Identification</p>
                    </span>
                    <Row className="mt-3 ml-2">
                      <Col md={6}>
                        <div className="queries">
                          <h5>{moderator.user?.username}</h5>
                          <p>Username</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="queries">
                          <h5>
                            <img
                              src={
                                moderator.assigned_astrologer_info
                                  ?.profile_image
                                  ? moderator.assigned_astrologer_info
                                      ?.profile_image
                                  : "/assets/images/avatar.png"
                              }
                              alt=""
                              className="astro-img"
                            />{" "}
                            {moderator.assigned_astrologer_info?.name}
                          </h5>
                          <p>Assigned astrologer</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="profile-info">
                    <div className="d-flex justify-content-between">
                      <span className="d-flex align-items-center">
                        <FaInfoCircle className="mr-1 text-danger" size="18" />
                        <p className="info-heading text-danger">Information</p>
                      </span>
                      {/* <span className="d-flex align-items-center">
                        <FaPencilAlt
                          color="#333333"
                          size="12"
                          className="mr-1"
                        />
                        <p className="info-edit">Edit</p>
                      </span> */}
                    </div>
                    <Row className="mt-3 ml-2">
                      <Col md={6}>
                        <div className="queries">
                          <h5>{moderator.gender}</h5>
                          <p>Gender</p>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="queries">
                          <h5>{moderator.user?.phone}</h5>
                          <p>Contact</p>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-center mt-3"></div>
                  </div>
                  <div className="profile-queries">
                    <span className="d-flex align-items-center">
                      <FaQuestionCircle
                        color="#1C70BE"
                        className="mr-1"
                        size="18"
                      />
                      <p className="info-heading">Queries handled</p>
                    </span>
                    <Row className="mt-3 ml-2">
                      <Col md={4}>
                        <div className="queries">
                          <h5>{moderator.stats?.total}</h5>
                          <p>Total</p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="queries">
                          <h5>{moderator.stats?.paid}</h5>
                          <p>Paid</p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="queries">
                          <h5>{moderator.stats?.free}</h5>
                          <p>Free</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="profile-review">
                    <span className="d-flex align-items-center">
                      <FaStar className="mr-1" color="#AE7B01" size="18" />
                      <p className="info-heading">Reviews</p>
                    </span>
                    <Row className="mt-2 ml-2">
                      <Col md={2}>
                        <p>5 Stars</p>
                      </Col>
                      <Col md={2}>
                        <FaStar color="#FCBE03" size="16" />
                      </Col>
                      <Col md={6}>
                        <ProgressBar
                          variant="success"
                          now={
                            moderator.rating &&
                            (moderator.rating[5] / totalRatings) * 100
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <h6>{moderator.rating && moderator.rating[5]}</h6>
                      </Col>
                    </Row>
                    <Row className="mt-2 ml-2">
                      <Col md={2}>
                        <p>4 Stars</p>
                      </Col>
                      <Col md={2}>
                        <FaStar color="#FCBE03" size="16" />
                      </Col>
                      <Col md={6}>
                        <ProgressBar
                          variant="success"
                          now={
                            moderator.rating &&
                            (moderator.rating[4] / totalRatings) * 100
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <h6>{moderator.rating && moderator.rating[4]}</h6>
                      </Col>
                    </Row>
                    <Row className="mt-2 ml-2">
                      <Col md={2}>
                        <p>3 Stars</p>
                      </Col>
                      <Col md={2}>
                        <FaStar color="#FCBE03" size="16" />
                      </Col>
                      <Col md={6}>
                        <ProgressBar
                          variant="success"
                          now={
                            moderator.rating &&
                            (moderator.rating[3] / totalRatings) * 100
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <h6>{moderator.rating && moderator.rating[3]}</h6>
                      </Col>
                    </Row>
                    <Row className="mt-2 ml-2">
                      <Col md={2}>
                        <p>2 Stars</p>
                      </Col>
                      <Col md={2}>
                        <FaStar color="#FCBE03" size="16" />
                      </Col>
                      <Col md={6}>
                        <ProgressBar
                          variant="success"
                          now={
                            moderator.rating &&
                            (moderator.rating[2] / totalRatings) * 100
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <h6>{moderator.rating && moderator.rating[2]}</h6>
                      </Col>
                    </Row>
                    <Row className="mt-2 ml-2">
                      <Col md={2}>
                        <p>1 Star</p>
                      </Col>
                      <Col md={2}>
                        <FaStar color="#FCBE03" size="16" />
                      </Col>
                      <Col md={6}>
                        <ProgressBar
                          variant="success"
                          now={
                            moderator.rating &&
                            (moderator.rating[1] / totalRatings) * 100
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <h6>{moderator.rating && moderator.rating[1]}</h6>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Col>
            <Col md={7}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <div className="bg-xlight d-flex justify-content-between align-items-center">
                        <h5>
                          No. of new <span className="text-success">paid</span>{" "}
                          queries received in an hour
                        </h5>
                        <div>
                          <FaCaretUp className="text-success mr-1" />
                          <p className="text-success">
                            {moderator.stats?.hourly_paid}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="bg-xlight d-flex justify-content-between align-items-center">
                        <h5>
                          No. of new <span className="text-danger">free</span>{" "}
                          queries received in an hour
                        </h5>
                        <div>
                          <FaCaretUp className="text-success mr-1" />
                          <p className="text-success">
                            {moderator.stats?.hourly_free}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3 stat">
                    <Col md={5}>
                      <p>No. of queries answered this week</p>
                    </Col>
                    <Col md={5}>
                      <ProgressBar
                        variant="success"
                        now={
                          (moderator.stats?.weekly_total /
                            moderator.stats?.weekly_total) *
                          100
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <h6>{moderator.stats?.weekly_total}</h6>
                    </Col>
                  </Row>
                  <Row className="mt-3 stat">
                    <Col md={5}>
                      <p>No. of paid queries answered this week</p>
                    </Col>
                    <Col md={5}>
                      <ProgressBar
                        variant="info"
                        now={
                          (moderator.stats?.weekly_paid /
                            moderator.stats?.weekly_total) *
                          100
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <h6>{moderator.stats?.weekly_paid}</h6>
                    </Col>
                  </Row>
                  <Row className="mt-3 stat">
                    <Col md={5}>
                      <p>No. of free queries answered this week</p>
                    </Col>
                    <Col md={5}>
                      <ProgressBar
                        variant="warning"
                        now={
                          (moderator.stats?.weekly_free /
                            moderator.stats?.weekly_total) *
                          100
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <h6>{moderator.stats?.weekly_free}</h6>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* <div className="message-wrapper mt-3">
                <Card>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="message-header">Recent Queries</h5>
                    <Link to="/questions/1" className="btn btn-primary btn-round">
                      View all
                    </Link>
                  </div>
                  <div className="messages messages-sm">
                    <Row className="mb-2">
                      <Col md={1}>
                        <img
                          src="/assets/images/user1.png"
                          className="message-avatar"
                          alt=""
                        />
                      </Col>
                      <Col md={10}>
                        <div className="single-message customer">
                          <h5>Anish Lamsal</h5>
                          <h6>Customer</h6>
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Explicabo in voluptas optio tempore aut
                            aperiam numquam nisi impedit quos.
                          </p>
                          <p className="time">2hrs</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div> */}
            </Col>
          </Row>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  moderator: state.user.profile,
});

export default connect(mapStateToProps, { getUserProfile })(Profile);
