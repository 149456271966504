import { useState, useEffect, useCallback } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
} from "react-bootstrap";
import {
  FaCopy,
  FaBell,
  FaImage,
  FaInfoCircle,
  FaSignOutAlt,
  FaTrashAlt,
  FaUserCircle,
  FaUserLock,
} from "react-icons/fa";
import { AiFillApple, AiFillAndroid } from "react-icons/ai";
import { toast } from "react-toastify";
import { logoutUser } from "../_redux/actions/ActionAuthentication";
import {
  getCustomerImages,
  addCustomerCredit,
} from "../_redux/actions/ActionCustomers";
import { changePassword } from "../_redux/actions/ActionUser";
import { connect } from "react-redux";
import Message from "../pages/Chat/Message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import Select from "../components/Search";
import { fetchWrapper } from "../_helpers";
import ReactCountryFlag from "react-country-flag";
import Loader from "../components/Loader";
import swal from "sweetalert";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const Header = ({
  user,
  logoutUser,
  ticket,
  onTicketHandle,
  loading,
  changePassword,
  deleteCustomerImages,
  getCustomerImages,
  postCustomerImages,
  addCustomerCredit,
  images,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [page, setPage] = useState(1);
  const [costumer, setCostumer] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  const [question, setQuestion] = useState({ totalResponse: 0, response: [] });
  const [query, setQuery] = useState("");
  const [entityType] = useState("");

  const loadFunc = () => {
    getQuestionResponse(selectedUser.id, page + 1, entityType, query);
    setPage(page + 1);
  };

  const handleAddCredit = () => {
    const data = { customer_id: selectedUser.id, credit: 1 };
    addCustomerCredit(data);
  };

  const getQuestionResponse = useCallback(
    async (id, page = 1) => {
      try {
        const response = await fetchWrapper.get(
          COMMON_URL +
          `customer/profile/${id}/responses/?page=${page}&duplicate=${query}`
        );

        page === 1
          ? setQuestion({
            totalResponse: response.count,
            response: response.results,
          })
          : setQuestion((prev) => ({
            totalResponse: response.count,
            response: [...prev.response, ...response.results],
          }));
      } catch (e) { }
    },
    [query]
  );

  useEffect(() => {
    selectedUser && getCustomerImages(selectedUser.user?.profile_id);
    if (selectedUser !== null) {
      getQuestionResponse(selectedUser.id, page, entityType, query);
    }
  }, [
    getCustomerImages,
    getQuestionResponse,
    selectedUser,
    query,
    page,
    entityType,
  ]);

  const schema = yup.object().shape({
    old_password: yup.string().required("Old password is required"),
    new_password: yup.string().required("New Password is required"),
    password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords do not match")
      .required("Confirm Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onPasswordChange = (data) => {
    changePassword(data, user.user.id, setShowChangePassword);
  };

  const fetchSelectedUser = async (id) => {
    if (id) {
      try {
        const response = await fetchWrapper.get(
          COMMON_URL + `customer/profile/${id}/`
        );
        setSelectedUser(response);
      } catch (e) { }
    }
  };

  const handleSearchUser = async (nameString) => {
    if (nameString) {
      if (/^\d+$/.test(nameString)) {
        try {
          const response = await fetchWrapper.get(
            COMMON_URL + `customer/profile/?user_id=${nameString}`
          );
          setCostumer(response.results);
        } catch (e) { }
      } else if (nameString) {
        try {
          const response = await fetchWrapper.get(
            COMMON_URL + `customer/profile/?name=${nameString}`
          );
          setCostumer(response.results);
        } catch (e) { }
      }
    }
  };

  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };
  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  return (
    <div className="header">
      <div className="header__left">
        <div className="logo ml-2">
          <Link to={user && user.user?.role === "superuser" ? "/" : "/welcome"}>
            <img
              src="/assets/images/karmic_vert.png"
              alt="logo"
              className="logo__img"
            />
          </Link>
        </div>
        <div className="search-bar">
          <Select
            handleUserInput={handleSearchUser}
            data={costumer}
            handleDataSelect={(newUser) => {
              fetchSelectedUser(newUser.user?.profile_id)
              getQuestionResponse(newUser.user?.profile_id);
            }}
          />
        </div>
      </div>

      <div className="header__right">
        {user && user.user?.role !== "qa" && ticket && (
          <Button
            className="btn btn-primary btn-round"
            onClick={onTicketHandle}
          >
            Open Ticket
          </Button>
        )}
        {user && user.user?.role === "qa" && (
          <ul className="d-flex">
            <li className="nav-item mr-5">
              <Link to="/customerlisting/1">Customers</Link>
            </li>
            <li className="nav-item">
              <Link to="/feedback/1">Feedback pending</Link>
            </li>
          </ul>
        )}
        {user && user.user?.role === "superuser" && (
          <ul className="d-flex justify-content-end">
            <li className="nav-item">
              <Link to="/feedback/1">Feedback pending</Link>
            </li>
          </ul>
        )}
        <div className="notification pointer ml-4">
          <FaBell
            color="#707070"
            onClick={() => setShowNotification(!showNotification)}
          />
          <div className="notification-wrapper">
            <Toast
              onClose={() => setShowNotification(false)}
              show={showNotification}
            >
              <Toast.Body>Notifications here.</Toast.Body>
            </Toast>
            <Toast
              onClose={() => setShowNotification(false)}
              show={showNotification}
            >
              <Toast.Body>Notifications here.</Toast.Body>
            </Toast>
          </div>
        </div>

        <div className="user-dropdown">
          <img
            src={
              user.profile_image
                ? user.profile_image
                : "/assets/images/avatar.png"
            }
            alt="profile"
            className="user-img"
          />
          <div className="d-flex flex-column">
            <span className="user-name">{user && user.user?.username}</span>
            <span className="user-role">{user && user.user?.role}</span>
          </div>

          <Dropdown>
            <Dropdown.Toggle id="dropdown-custom-components"></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="text-danger"
                onClick={() => setShowChangePassword(!showChangePassword)}
              >
                <FaUserLock /> Change Password
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => logoutUser()}
              >
                <FaSignOutAlt /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {selectedUser && (
        <Modal
          show={true}
          onHide={() => setSelectedUser(null)}
          className="fullWidth"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Search result for "{selectedUser.user.id}"
            </Modal.Title>
          </Modal.Header>
          <Row>
            <Col md={4}>
              <div className="leftbar modal-leftbar">
                <div className="profile-header">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        selectedUser.profile_image
                          ? selectedUser.profile_image
                          : "/assets/images/avatar.png"
                      }
                      alt="profile"
                      className="profile-img"
                    />
                    <div>
                      <h5>{selectedUser.name} </h5>
                      <p>Customer id: {selectedUser.user?.id}</p>
                    </div>
                    <Button
                      className="btn-primary btn-round"
                      onClick={() => {
                        swal({
                          text: "Do you want to provide free question ?",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((confirm) => {
                          if (confirm) {
                            handleAddCredit();
                          }
                        });
                      }}
                    >
                      Provide free question
                    </Button>
                  </div>
                </div>
                <div className="profile-info">
                  <span className="d-flex align-items-center">
                    <FaUserCircle className="mr-1 text-info" size="18" />
                    <p className="info-heading text-info">Identification</p>
                  </span>
                  <div class="d-flex mt-3 flex-column">
                    <div className="queries mb-3">
                      <h5 className="d-flex">
                        {selectedUser.user?.username}
                        {selectedUser.user?.agent === "android" ? (
                          <p
                            className="bg-dark mb-0 d-flex justify-content-center align-items-center mx-1"
                            style={{ borderRadius: "2px", padding: "1px" }}
                          >
                            <AiFillAndroid color="white" />
                          </p>
                        ) : selectedUser.user?.agent === "ios" ? (
                          <p
                            className="bg-dark mb-0 d-flex justify-content-center align-items-center mx-1"
                            style={{ borderRadius: "2px", padding: "1px" }}
                          >
                            <AiFillApple color="white" />
                          </p>
                        ) : null}
                      </h5>
                      <p>Username</p>
                    </div>
                    <div className="queries mb-3">
                      <h5 className="text-break">
                        {selectedUser.duplicates &&
                          selectedUser.duplicates.length > 0
                          ? selectedUser.duplicates.map((item, index) => (
                            <Link
                              to={`/customer/profile/${selectedUser.duplicates[index]}`}
                              onClick={() => setSelectedUser(null)}
                            >
                              {selectedUser.duplicates[index]},
                            </Link>
                          ))
                          : "None"}
                      </h5>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div className="queries mb-3">
                      <h5>{selectedUser.device_id}</h5>
                      <p>Device ID</p>
                    </div>
                    <div className="queries">
                      <h5>
                        <FaCopy
                          size="25"
                          onClick={() => {
                            toast.success("Text copied to clipboard");
                            navigator.clipboard.writeText(selectedUser.fcm_token);
                          }}
                        />
                      </h5>
                      <p>Copy FCM Token</p>
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="d-flex justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaInfoCircle className="mr-1 text-danger" size="18" />
                      <p className="info-heading text-danger">Information</p>
                    </span>
                  </div>
                  <div class="d-flex align-items-end mt-3 mx-4">
                    <div className="queries">
                      <h5>{selectedUser.gender}</h5>
                      <p>Gender</p>
                    </div>
                    <div className="queries">
                      <h5>{selectedUser.age}</h5>
                      <p>Age</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end mt-3 mx-4">
                    <div className="queries">
                      <h5>{selectedUser.dob_AD}</h5>
                      <p>Date of Birth</p>
                    </div>
                    <div className="queries">
                      <h5>{selectedUser.birth_time} </h5>
                      <span className="accurate ml-0">
                        {selectedUser.is_bt_accurate
                          ? "Accurate"
                          : "Not Accurate"}
                      </span>
                      <p>Time of Birth</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end mt-3 mx-4">
                    <div className="queries">
                      <h5>
                        <ReactCountryFlag
                          className="emojiFlag mr-1"
                          countryCode={selectedUser.country_code}
                          style={{
                            fontSize: "2em",
                          }}
                          title={selectedUser.country}
                          svg
                        />
                        {selectedUser.country}
                      </h5>
                      <p>Country</p>
                    </div>
                    <div className="queries">
                      <h5>{selectedUser.address}</h5>
                      <p>Place</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end mt-3 mx-4">
                    <div className="queries">
                      <h5>{selectedUser.phone}</h5>
                      <p>Contact</p>
                    </div>
                    <div className="queries">
                      <h5>{selectedUser.zodiac_name}</h5>
                      <p>Zodiac</p>
                    </div>
                  </div>
                </div>
                <div className="chat-profile-image">
                  <span className="d-flex align-items-center">
                    <FaImage className="mr-1" size="18" color="#7A57FF" />
                    <p className="info-heading">Image</p>
                  </span>
                  <Row className="mt-3">
                    {images &&
                      images.length > 0 &&
                      images.map((image, index) => (
                        <Col md={6}>
                          <img
                            src={image.image}
                            alt={image.image}
                            className="kundali pointer"
                            onClick={() => showImage(image.image)}
                          />
                          {user && user.user?.role !== "qa" && (
                            <span
                              className="deleteIcon pointer"
                              onClick={() =>
                                deleteCustomerImages(selectedUser.id, image.id)
                              }
                            >
                              <FaTrashAlt size="8" />
                            </span>
                          )}
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={8}>
              {selectedUser.duplicates && selectedUser.duplicates.length > 0 && (
                <div className="d-flex align-items-center justify-content-end mr-4 dublicate-switch">
                  <p className="text-danger mr-2">
                    {query === ""
                      ? "Show Dublicates Chat History"
                      : "Hide Dublicates Chat History"}
                  </p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onClick={() => {
                        if (query === "" ? setQuery("show") : setQuery(""))
                          setPage(1);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              )}

              <div className="message-wrapper">
                <div className="messages main-conversation" id="scrollableDiv">
                  <InfiniteScroll
                    dataLength={question.response?.length}
                    next={loadFunc}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflow: "hidden",
                      paddingTop: "10px",
                    }}
                    inverse={true}
                    hasMore={question.response?.length < question.totalResponse}
                    loader={
                      <Spinner animation="border" variant="danger" size="sm" />
                    }
                    scrollableTarget="scrollableDiv"
                    pullDownToRefreshThreshold={50}
                  >
                    {question.response?.length > 0 ? (
                      question.response.map((item, index) => (
                        <div className="d-flex mb-2">
                          <img
                            src={
                              item.response_from_user.image
                                ? item.response_from_user.image
                                : "/assets/images/avatar.png"
                            }
                            className="message-avatar"
                            alt=""
                          />

                          <Message
                            type={item.response_type}
                            name={item.response_from_user.name}
                            message={item.response_text}
                            pin={item.pinned}
                            created={item.created_at}
                            id={item.id}
                            entity_type={item.entity_type}
                          />
                        </div>
                      ))
                    ) : (
                      <Loader />
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
      {!loading ? (
        <Modal
          show={showChangePassword}
          onHide={() => {
            setShowChangePassword(false);
            reset();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onPasswordChange)}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter old password"
                  {...register("old_password")}
                />
                <Form.Text className="text-danger">
                  {errors.old_password?.message}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formGroupName">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  {...register("new_password")}
                />
                <Form.Text className="text-danger">
                  {errors.new_password?.message}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formGroupName">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  {...register("password")}
                />
                <Form.Text className="text-danger">
                  {errors.password?.message}
                </Form.Text>
              </Form.Group>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                Update
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}
      {lightboxDisplay && (
        <div id="lightbox" onClick={hideLightBox}>
          <img id="lightbox-img" src={imageToShow} alt=""></img>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  loading: state.ui.loading,
  images: state.customers.images,
});

const mapDispatchToProps = {
  logoutUser,
  changePassword,
  getCustomerImages,
  addCustomerCredit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
