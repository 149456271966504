import {
  FaAtom,
  FaBell,
  FaChartPie,
  FaChevronDown,
  FaChevronUp,
  FaCog,
  FaCogs,
  FaHome,
  FaInfoCircle,
  FaList,
  FaPaperclip,
  FaQuestionCircle,
  FaSearch,
  FaSms,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";
import {RiShieldStarFill} from "react-icons/ri";

const SidebarData = [
  {
    id: 1,
    title: "Dashboard",
    icon: <FaHome />,
    path: "/",
  },
  {
    id: 2,
    title: "General Management",
    icon: <FaList />,
    path: false,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,
    subNav: [
      {
        title: "Staff Management",
        path: "/general/staff",
        icon: <FaUserFriends />,
      },
      {
        title: "Question Template Management",
        path: "/general/questionmanagement",
        icon: <FaQuestionCircle />,
      },
    ],
  },
  {
    id: 3,
    title: "Customers",
    icon: <FaUser />,
    path: "/customers/1",
  },
  {
    id: 4,
    title: "Questions",
    icon: <FaQuestionCircle />,
    path: "/questions/1",
  },
  {
    id: 5,
    title: "Settings",
    icon: <FaCogs />,
    path: false,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,
    subNav: [
      {
        title: "General",
        path: "/settings/general",
        icon: <FaCog />,
      },
      {
        title: "System Notification",
        path: "/settings/notification",
        icon: <FaBell />,
      },
      {
        title: "System Messages",
        path: "/settings/messages",
        icon: <FaSms />,
      },
      {
        title: "Clarification Templates",
        path: "/settings/clarification",
        icon: <FaPaperclip />,
      },
      {
        title: "Zodiacs",
        path: "/settings/zodiacs/1",
        icon: <FaAtom />,
      },
      {
        title: "Astrologer Helpers",
        path: "/settings/astrologer/helpers",
        icon: <FaInfoCircle />,
      },
      {
        title: "Moderator Helpers",
        path: "/settings/moderator/helpers",
        icon: <FaInfoCircle />,
      },
    ],
  },
  {
    title: "Sales Report",
    icon: <FaChartPie />,
    path: "/sales/1",
  },
  {
    title: "Points Report",
    icon: <RiShieldStarFill />,
    path: "/points/1",
  },
  {
    title: "Page Content",
    icon: <FaSearch />,
    path: "/pagecontent",
  },
];

export default SidebarData;
