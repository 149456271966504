import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  postClarificationTemplate,
  getClarificationType,
  editClarificationTemplate,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";

const AddTemplate = ({
  postClarificationTemplate,
  getClarificationType,
  clarificationType,
  clarificationTemp,
  editClarificationTemplate,
  setShowAdd,
}) => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    clarificationTemp
      ? editClarificationTemplate(data, clarificationTemp.id)
      : postClarificationTemplate(data);
    reset();
    setShowAdd(false);
  };
  useEffect(() => {
    getClarificationType();
  }, [getClarificationType]);

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Template Type</Form.Label>
                <Form.Control
                  as="select"
                  {...register("clarification_type")}
                  defaultValue={
                    clarificationTemp && clarificationTemp.clarification_type
                  }
                >
                  {clarificationType.length > 0 &&
                    clarificationType.map((item, index) => (
                      <option value={item.id} key={item.id}>
                        {item.clarification_type}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  type="text"
                  {...register("clarification")}
                  defaultValue={
                    clarificationTemp && clarificationTemp.clarification
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                {clarificationTemp ? "Edit" : "Send"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  clarificationType: state.clarificationType.clarificationType,
});

const mapDispatchToProps = {
  getClarificationType,
  postClarificationTemplate,
  editClarificationTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);
