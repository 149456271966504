import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Table, Button } from "react-bootstrap";
import PaginationComp from "../../components/PaginationComp";
import Layout from "../../layouts/Layout";
import { getPointsReport } from "../../_redux/actions/ActionPoints";
import Loader from "../../components/Loader";
import { connect } from "react-redux";
import { FaChevronUp, FaChevronDown, FaTimesCircle } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";

import { BsFilter } from "react-icons/bs";

const SalesReport = ({
  getPointsReport,
  points,
  pointsCount,
  loading,
  getQuestionById,
}) => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [showDate, setShowDate] = useState(false);
  const [showUserType, setShowUserType] = useState(false);
  // const [viewPdf, setViewPdf] = useState(false);
  const [filter, setFilter] = useState({
    from_date: "",
    to_date: "",
    query: "",
  });

  const [userType, setUserType] = useState("moderator");

  // const viewPdfModal = () => {
  //   setViewPdf(true);
  // };

  useEffect(() => {
    getPointsReport(page, userType, filter);
  }, [page, userType, filter, getPointsReport]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  return (
    <Layout>
      <div className="questions-section">
        <Row className="mt-3">
          <Col md={4}>
            <h5 className="font-weight-bold text-danger mb-2 textCapitalize">
              Points Report for {userType}
            </h5>
          </Col>
          {/* <Col
            md={{ span: 2, offset: 1 }}
            className="d-flex flex-column align-items-end"
          >
            <div
              className="btn border-danger text-danger"
              onClick={() => viewPdfModal(true)}
            >
              <FaRegFilePdf className="mr-2" />
              view pdf
            </div>
            <Modal show={viewPdf} onHide={() => setViewPdf(false)} size="lg">
              <Modal.Body className="pdfviewer-modal p-0">
                <PDFViewer style={{ width: "100%", height: "80vh" }}>
                  <PdfRender items={points} from="points" userType={userType} />
                </PDFViewer>
              </Modal.Body>
            </Modal>
          </Col> */}
          <Col md={2}>
            <div className="select-toggle">
              <div
                className="selector pointer"
                onClick={() => setShowUserType(!showUserType)}
              >
                <BsFilter className="mr-2" />
                <p className="textCapitalize">{userType}</p>
              </div>
              {showUserType && (
                <div className="select-wrapper-right select-wrapper-sm">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setUserType("astrologer");
                      setShowUserType(false);
                    }}
                  >
                    Astrologer
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setUserType("moderator");
                      setShowUserType(false);
                    }}
                  >
                    Moderator
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div className="select-toggle mb-2">
              <div
                className="selector pointer"
                onClick={() => setShowDate(!showDate)}
              >
                <p>Filter Date</p>
                {showDate ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showDate && (
                <div className="select-wrapper-right">
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            from_date: e.target.value,
                          }));
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            to_date: e.target.value,
                          }));
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        query: "today",
                      }));
                      setShowDate(false);
                    }}
                  >
                    Today
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        query: "week",
                      }));
                      setShowDate(false);
                    }}
                  >
                    This Week
                  </Button>
                  <Button
                    className="btn-outline-primary-100"
                    onClick={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        query: "month",
                      }));
                      setShowDate(false);
                    }}
                  >
                    This Month
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {filter.from_date && (
              <span className="user-wrapper">
                <p>{filter && filter.from_date}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      from_date: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.to_date && (
              <span className="user-wrapper">
                <p>{filter && filter.to_date}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      to_date: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.query && (
              <span className="user-wrapper">
                <p>
                  {filter.query === "today" && "Today"}
                  {filter.query === "week" && "This Week"}
                  {filter.query === "month" && "This Month"}
                </p>

                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      query: "",
                    }));
                  }}
                />
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td className="textCapitalize"> {userType}</td>
                      <td className="text-center">Questions Handled</td>
                      <td className="text-center">Free Questions</td>
                      <td className="text-center">Paid Questions </td>
                      <td className="text-center">Answered</td>
                      {userType === "moderator" && (
                        <td className="text-center">Points Earned</td>
                      )}
                      <td>Level</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      points?.length > 0 ? (
                        points?.map((point, index) => (
                          <tr>
                            <td>{point.profile_id}</td>
                            <td>{point.name}</td>
                            <td className="text-center">
                              {point.questions_handled}
                            </td>
                            <td className="text-center">{point.free}</td>
                            <td className="text-center">{point.paid}</td>
                            <td className="text-center">{point.answered}</td>
                            {userType === "moderator" && (
                              <td className="text-center">
                                {point.total_points}
                              </td>
                            )}
                            <td>{point.level}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <div className="text-center">No results found</div>
                          </td>
                        </tr>
                      )
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </Table>
                {pointsCount > 10 && (
                  <PaginationComp
                    total={pointsCount}
                    page={page}
                    setPage={handleSetPage}
                  />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  points: state.points.report,
  pointsCount: state.points.totalReports,
  loading: state.ui.loading,
});
export default connect(mapStateToProps, { getPointsReport })(SalesReport);
