import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { deleteCustomerNotes } from "../../_redux/actions/ActionCustomers";
import { connect } from "react-redux";

const Note = ({ item, deleteCustomerNotes }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="single-note w-100 mr-2">
        <h5>{item.note_content}</h5>
        <p>{item.created_at.slice(0, 10)}</p>
      </div>
      <FaTrashAlt
        className="pointer"
        color="#BC2A20"
        onClick={() => deleteCustomerNotes(item.id)}
      />
    </div>
  );
};

export default connect(null, { deleteCustomerNotes })(Note);
