import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { connect } from "react-redux";

import { getFeedback } from "../../_redux/actions/ActionFeedback";
import { Link, useParams, useHistory } from "react-router-dom";
import PaginationComp from "../../components/PaginationComp";
import Loader from "../../components/Loader";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

const PendingFeedback = ({ getFeedback, feedback, totalFeedback, loading }) => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  useEffect(() => {
    getFeedback(page);
  }, [getFeedback, page]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  return (
    <>
      <Header />
      <div className="questions-section minh-100">
        <Container>
          <Row className="mt-5">
            <Col md={4}>
              <h5 className="font-weight-bold text-danger">Feedback Pending</h5>
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col md={12}>
              <div className="dashboard-table">
                <Card>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Customer Id</th>
                        <th>Name</th>
                        <th>Query</th>
                        <th>Email</th>
                        <th>Created At</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading ? (
                        feedback.length > 0 &&
                        feedback.map((item, i) => (
                          <tr key={item.id}>
                            <td className="text-muted">{item.customer}.</td>
                            <td>{item.customer_name}</td>
                            <td>{item.text}</td>
                            <td>{item.customer_email}</td>
                            <td>{item.created_at.slice(0, 10)}</td>
                            <td width="10%" className="text-center">
                              <Link
                                to={{
                                  pathname: `/chat/${item.customer}`,
                                  query: { queryId: item.id },
                                }}
                              >
                                <FaEye className="text-success pointer mr-2" />
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </Table>
                  {totalFeedback > 10 && (
                    <PaginationComp
                      total={totalFeedback}
                      page={page}
                      setPage={handleSetPage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  feedback: state.feedback.feedback,
  totalFeedback: state.feedback.totalFeedback,
  loading: state.ui.loading,
});

const mapDispatchToProps = { getFeedback };

export default connect(mapStateToProps, mapDispatchToProps)(PendingFeedback);
