import * as ActionTypes from "../ActionTypes";

const initialState = {
  helperType: [],
  helperTemplate: [],
  totalhelperTemplate: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ASTROLOGER_HELPER_TYPE:
      return {
        ...state,
        helperType: action.payload.results,
      };
    case ActionTypes.POST_ASTROLOGER_HELPER_TYPE:
      return {
        ...state,
        helperType: [action.payload, ...state.helperType],
      };
    case ActionTypes.DELETE_ASTROLOGER_HELPER_TYPE:
      const tempClar = [...state.helperType];
      const index = tempClar.findIndex((user) => user.id === action.payload);
      tempClar.splice(index, 1);
      return {
        ...state,
        helperType: tempClar,
      };

    case ActionTypes.GET_ASTROLOGER_HELPER_TEMPLATE:
      return {
        ...state,
        helperTemplate: action.payload.results,
        totalhelperTemplate: action.payload.count,
      };
    case ActionTypes.POST_ASTROLOGER_HELPER_TEMPLATE:
      return {
        ...state,
        helperTemplate: [action.payload, ...state.helperTemplate],
      };

    case ActionTypes.DELETE_ASTROLOGER_HELPER_TEMPLATE:
      const tempHelp = [...state.helperTemplate];
      const indexHelp = tempHelp.findIndex(
        (user) => user.id === action.payload
      );
      tempHelp.splice(indexHelp, 1);
      return {
        ...state,
        helperTemplate: tempHelp,
        totalhelperTemplate: state.totalhelperTemplate - 1,
      };
    case ActionTypes.EDIT_ASTROLOGER_HELPER_TEMPLATE:
      const helpTemp = [...state.helperTemplate];
      const editedIndex = helpTemp.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        helpTemp[editedIndex] = action.payload;
      }
      return { ...state, helperTemplate: helpTemp };
    default:
      return state;
  }
}
