import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Card,
  Table,
  Button,
  //  Modal
} from "react-bootstrap";
import PaginationComp from "../../components/PaginationComp";
import Layout from "../../layouts/Layout";
import { useParams, useHistory } from "react-router-dom";
import { getSalesReport } from "../../_redux/actions/ActionSales";
import Loader from "../../components/Loader";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  FaChevronUp,
  FaChevronDown,
  // FaRegFilePdf,
  FaTimesCircle,
  FaCopy,
} from "react-icons/fa";
import { getQuestionById } from "../../_redux/actions/ActionQuestions";
// import { PDFViewer } from "@react-pdf/renderer";
// import PdfRender from "./PdfRender";
import ReactTooltip from "react-tooltip";
import {} from "react-icons";
const SalesReport = ({
  getSalesReport,
  sales,
  salesCount,
  loading,
  getQuestionById,
}) => {
  const { pageNum } = useParams();
  const history = useHistory();
   const [page, setPage] = useState(pageNum);
  const [showDate, setShowDate] = useState(false);
  // const [viewPdf, setViewPdf] = useState(false);
  const [filter, setFilter] = useState({
    from_date: "",
    to_date: "",
    query: "",
  });
  const MAX_LENGTH = 120;

  useEffect(() => {
    getSalesReport(page, filter);
  }, [page, filter, getSalesReport]);

  // const viewPdfModal = () => {
  //   setViewPdf(true);
  // };

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  return (
    <>
      <Layout>
        <div className="questions-section position-relative">
          <Row className="mt-3">
            <Col md={4}>
              <h5 className="font-weight-bold text-danger mb-2">
                Sales Report
              </h5>
            </Col>
            {/* <Col md={{ span: 2, offset: 3 }}>
              <div
                className="btn border-danger text-danger"
                onClick={() => viewPdfModal(true)}
              >
                <FaRegFilePdf className="mr-2" />
                view pdf
              </div>
              <Modal show={viewPdf} onHide={() => setViewPdf(false)} size="lg">
                <Modal.Body className="pdfviewer-modal p-0">
                  <PDFViewer style={{ width: "100%", height: "80vh" }}>
                    <PdfRender items={sales} from="points" />
                  </PDFViewer>
                </Modal.Body>
              </Modal>
            </Col> */}
            <Col md={3}>
              <div className="select-toggle mb-2">
                <div
                  className="selector pointer"
                  onClick={() => setShowDate(!showDate)}
                >
                  <p>Filter Date</p>
                  {showDate ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {showDate && (
                  <div className="select-wrapper">
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>From</Form.Label>
                        <Form.Control
                          type="date"
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              from_date: e.target.value,
                            }));
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>To</Form.Label>
                        <Form.Control
                          type="date"
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              to_date: e.target.value,
                            }));
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Button
                      className="btn-outline-primary-100 mb-2"
                      onClick={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          query: "today",
                        }));
                        setShowDate(false);
                      }}
                    >
                      Today
                    </Button>
                    <Button
                      className="btn-outline-primary-100 mb-2"
                      onClick={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          query: "week",
                        }));
                        setShowDate(false);
                      }}
                    >
                      This Week
                    </Button>
                    <Button
                      className="btn-outline-primary-100"
                      onClick={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          query: "month",
                        }));
                        setShowDate(false);
                      }}
                    >
                      This Month
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              {filter.from_date && (
                <span className="user-wrapper">
                  <p>{filter && filter.from_date}</p>
                  <FaTimesCircle
                    size="12"
                    color="#BC2A20"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        from_date: "",
                      }));
                    }}
                  />
                </span>
              )}
              {filter.to_date && (
                <span className="user-wrapper">
                  <p>{filter && filter.to_date}</p>
                  <FaTimesCircle
                    size="12"
                    color="#BC2A20"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        to_date: "",
                      }));
                    }}
                  />
                </span>
              )}
              {filter.query && (
                <span className="user-wrapper">
                  <p>
                    {filter.query === "today" && "Today"}
                    {filter.query === "week" && "This Week"}
                    {filter.query === "month" && "This Month"}
                  </p>

                  <FaTimesCircle
                    size="12"
                    color="#BC2A20"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        query: "",
                      }));
                    }}
                  />
                </span>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="dashboard-table">
                <Card className="table-minheight">
                  <Table responsive>
                    <thead>
                      <tr>
                        <td width="5%" className="text-center">
                          Id
                        </td>
                        <td width="2%"></td>
                        <td width="45%">Question</td>
                        <td width="10%">Posted by</td>
                        <td width="10%">Created At</td>
                        <td width="10%" className="text-center">
                          Order Details
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading ? (
                        sales?.length > 0 ? (
                          sales?.map((sale, index) => (
                            <tr
                              className={sale.type === "Paid" ? "bg-gray" : ""}
                            >
                              <td className="text-muted name text-center">
                                {sale.id}
                              </td>
                              <td
                                className="text-danger text-center"
                                width="5%"
                              >
                                {sale.type === "Paid" ? "$" : ""}
                              </td>
                              <td
                                className="description"
                                data-tip={sale.extra_fields?.question_text}
                              >
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="solid"
                                />
                                {sale.extra_fields.question_text?.substring(
                                  0,
                                  MAX_LENGTH
                                )}
                              </td>
                              <td className="name">
                                {sale.extra_fields?.customer_name}
                              </td>
                              <td className="name">
                                {sale.used_at?.split("T")[0]}
                              </td>
                              <td
                                className="name text-center"
                                data-tip={sale.order_details}
                              >
                                <FaCopy
                                  size="25"
                                  onClick={() => {
                                    toast.success("Text copied to clipboard");
                                    navigator.clipboard.writeText(
                                      sale.order_details
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className="text-center">
                                No results found
                              </div>
                            </td>
                          </tr>
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </Table>
                  {salesCount > 10 && (
                    <PaginationComp
                      total={salesCount}
                      page={page}
                      setPage={handleSetPage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => ({
  sales: state.sales.report,
  salesCount: state.sales.totalReports,
  loading: state.ui.loading,
});
export default connect(mapStateToProps, { getSalesReport, getQuestionById })(
  SalesReport
);
