import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

//redux stuff
import { connect } from "react-redux";
import {
  postAUser,
  getAllAstrologer,
  editUser,
} from "../../_redux/actions/ActionUser";

const AddStaff = ({
  showAdd,
  setShowAdd,
  postAUser,
  getAllAstrologer,
  astrologer,
  userDetail,
  setUserDetail,
  editUser,
}) => {
  useEffect(() => {
    getAllAstrologer();
  }, [getAllAstrologer]);

  // const schema = yup.object().shape({
  //   username: yup.string().required("Username is required"),
  //   email: yup.string().required("Email is required"),
  //   password: yup.string().required("Password is required"),
  // });
  const [imageFile] = useState(
    userDetail && userDetail.profile_image && userDetail.profile_image
  );

  const { register, handleSubmit, watch } = useForm({
    mode: "onBlur",
    defaultValues: {},
    // resolver: yupResolver(schema),
  });
  const watchRole = watch("role", "moderator");
  const onSubmit = (data) => {
    userDetail
      ? editUser(data, userDetail.id, setShowAdd, showAdd)
      : postAUser(data, setShowAdd, showAdd);
    // setShowAdd(!showAdd);
    setUserDetail(null);
  };
  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Profile Image</Form.Label>
                <Form.Control type="file" {...register("profile_image")} />
                {userDetail && userDetail.profile_image && (
                  <img
                    src={
                      typeof imageFile === "string"
                        ? imageFile
                        : URL.createObjectURL(imageFile)
                    }
                    alt="avatar"
                    className="table-avatar"
                  />
                )}
              </Form.Group>
            </Col>

            <Col md={8}>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="eg.anish@gmail.com"
                      id="username"
                      {...register("username")}
                      defaultValue={userDetail && userDetail.user?.username}
                    />
                    {/* <Form.Text className="text-danger">
                  {errors.username?.message}
                </Form.Text> */}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      {...register("role")}
                      defaultValue={userDetail && userDetail.user?.role}
                    >
                      {userDetail && (
                        <option
                          value={userDetail.user?.role}
                          key={userDetail.user?.role}
                        >
                          {userDetail.user?.role}
                        </option>
                      )}
                      <option value="moderator" key="moderator">
                        Moderator
                      </option>
                      <option value="astrologer" key="astrologer">
                        Astrologer
                      </option>
                      <option value="qa" key="qa">
                        QA
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      {...register("gender")}
                      defaultValue={userDetail && userDetail.gender}
                    >
                      {userDetail && (
                        <option
                          value={userDetail.gender}
                          key={userDetail.gender}
                        >
                          {userDetail.gender}
                        </option>
                      )}
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {watchRole === "moderator" && (
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Assign Astrologer</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("assigned_astrologer")}
                        defaultValue={
                          userDetail && userDetail.assigned_astrologer
                        }
                      >
                        {astrologer.length > 0 &&
                          astrologer.map((astro) => (
                            <option value={astro.id}>
                              {astro.user?.first_name} {astro.user?.last_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}

                <Col md={4}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="eg. Anish"
                      {...register("first_name")}
                      defaultValue={userDetail && userDetail.user?.first_name}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="eg. Lamsal"
                      {...register("last_name")}
                      defaultValue={userDetail && userDetail.user?.last_name}
                    />
                  </Form.Group>
                </Col>
                {watchRole === "moderator" && (
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Designation</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("level")}
                        defaultValue={userDetail && userDetail.level}
                      >
                        <option value="Free">Free</option>
                        <option value="Bronze">Bronze</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                        <option value="Diamond">Diamond</option>
                        <option value="Spiritualist">Spiritualist</option>
                        <option value="Counselor">Counselor</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Phone No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="eg.9845288425"
                      {...register("phone")}
                      defaultValue={userDetail && userDetail.user?.phone}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="eg.anish@gmail.com"
                      id="email"
                      {...register("email")}
                      defaultValue={userDetail && userDetail.user?.email}
                    />
                    {/* <Form.Text className="text-danger">
                      {errors.email?.message}
                    </Form.Text> */}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      // required
                      type="password"
                      placeholder="Change Password"
                      {...register("password")}
                      id="password"
                    />
                    {/* <Form.Text className="text-danger">
                      {errors.password?.message}
                    </Form.Text> */}
                  </Form.Group>
                </Col>
                {watchRole === "astrologer" && (
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Show in App</Form.Label>
                      <Form.Control
                        type="checkbox"
                        id="showin_app"
                        {...register("showin_app")}
                        defaultChecked={userDetail && userDetail.showin_app}
                      />
                      {/* <Form.Text className="text-danger">
                      {errors.email?.message}
                    </Form.Text> */}
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-outline-primary-200 btn-round btn-sm mr-3"
                      onClick={() => {
                        setShowAdd(!showAdd);
                        setUserDetail(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-primary btn-round btn-sm"
                      type="submit"
                    >
                      {userDetail ? "Update" : "Add"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  astrologer: state.user.astrologer,
});

const mapDispatchToProps = { postAUser, getAllAstrologer, editUser };

export default connect(mapStateToProps, mapDispatchToProps)(AddStaff);
