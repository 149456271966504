import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../../layouts/Header";
import LeftBar from "./LeftBar";
import Main from "./Main";
import RightBar from "./RightBar";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import { getCustomerProfile } from "../../_redux/actions/ActionCustomers";

const Chat = ({ question, match, getCustomerProfile, customer, location }) => {
  useEffect(() => {
    match.params.id && getCustomerProfile(match.params.id);
  }, [getCustomerProfile, match, match.params.id]);

  const queryId = location?.query?.queryId;

  return (
    <>
      <Header />
      {customer ? (
        <Row noGutters>
          <Col md={3}>{customer && <LeftBar customer={customer} />}</Col>
          <Col md={6}>
            {customer && <Main customer={customer} queryId={queryId} />}
          </Col>
          <Col md={3}>{customer && <RightBar customer={customer} />}</Col>
        </Row>
      ) : question ? (
        <Row noGutters>
          <Col md={3}>
            {question && <LeftBar customer={question.customer} />}
          </Col>
          <Col md={6}>{question && <Main customer={question.customer} />}</Col>
          <Col md={3}>
            {question && <RightBar customer={question.customer} />}
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  question: state.ticket.ticket,
  customer: state.customers.customer,
});

const mapDispatchToProps = { getCustomerProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
