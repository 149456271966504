import React, { useState, useEffect, useCallback } from "react";
import { Accordion, Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { FaChevronCircleUp, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Message from "./Message";
import {
  getQuestionResponse,
  postResponse,
  getQuestionsOnly,
} from "../../_redux/actions/ActionQuestions";
import {
  getClarificationType,
  getClarificationTemplate,
  getGeneralSettings,
} from "../../_redux/actions/ActionSettings";
import { getTicket } from "../../_redux/actions/ActionTicket";
import { getUserData } from "../../_redux/actions/ActionUser";
import { postQueryResponse } from "../../_redux/actions/ActionFeedback";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useForm } from "react-hook-form";
import Helpers from "./Helpers/Helpers";
import useComponentVisible from "../../hooks/useComponentVisible";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import swal from "sweetalert";
import { MentionsInput, Mention } from "react-mentions";

const Main = ({
  response,
  getQuestionResponse,
  getTicket,
  postResponse,
  totalResponse,
  getUserData,
  user,
  getClarificationType,
  clarificationType,
  getQuestionsOnly,
  questionsOnly,
  totalOnly,
  settings,
  loading,
  feedback,
  customer,
  postQueryResponse,
  queryId,
  question
}) => {
  const history = useHistory();
  const [showClarification, setShowClarification] = useState(false);
  const [page, setPage] = useState(1);
  const [entityType, setEntityType] = useState("");
  const [questionCount, setQuestionCount] = useState(0);
  const MAX_LENGTH = 120;
  const [query] = useState("");

  const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

  const { ref, componentVisible, setComponentVisible, setIgnoreClick } =
    useComponentVisible(false);

  useEffect(() => {
    getQuestionResponse(customer.id, 1, entityType, query);
    getUserData();
    getClarificationType();
    getQuestionsOnly(customer.id);
  }, [
    getQuestionResponse,
    getUserData,
    getClarificationType,
    getQuestionsOnly,
    customer.id,
    entityType,
    query,
  ]);
  const loadFunc = () => {
    getQuestionResponse(customer.id, page + 1, entityType, query);
    setPage(page + 1);
  };

  const { register, handleSubmit, setValue, watch } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });
  const message = watch("response_text", "");
  const onClarify = (data) => {
    if (message.length < settings[0].clarification_limit) {
      toast.error("Character is less than clarification limit");
    } else {
      swal({
        text: "Are you sure to send this message as clarify?",
        buttons: true,
        icon: "warning",
      }).then((confirm) => {
        if (confirm) {
          postResponse({ ...data, question_id: question.id, send_to: "clarify" }, user.id, history);
        }
      });
    }
  };
  const onAstroClarify = (data) => {
    if (message.length < settings[0].clarification_limit) {
      toast.error("Character is less than clarification limit");
    } else {
      swal({
        text: "Are you sure to send this message as clarify?",
        buttons: true,
        icon: "warning",
      }).then((confirm) => {
        if (confirm) {
          postResponse({ ...data, question_id: question.id, send_to: "moderator" }, user.id, history);
        }
      });
    }
  };
  const onAstrologer = (data) => {
    if (message.length < settings[0].moderator_answer_limit) {
      toast.error("Character is less than moderator answer limit");
    } else {
      swal({
        text: "Are you sure to send this message to astrologer?",
        buttons: true,
        icon: "warning",
      }).then((confirm) => {
        if (confirm) {
          postResponse({ ...data, question_id: question.id, send_to: "astrologer" }, user.id, history);
        }
      });
    }
  };
  const onModerator = (data) => {
    if (message.length < settings[0].astrologer_answer_limit) {
      toast.error("Character is less than astrologer answer limit");
    } else {
      swal({
        text: "Are you sure to send this message to moderator?",
        buttons: true,
        icon: "warning",
      }).then((confirm) => {
        if (confirm) {
          postResponse({ ...data, question_id: question.id, send_to: "moderator" }, user.id, history);
        }
      });
    }
  };
  const onAnswer = (data) => {
    if (message.length < settings[0].moderator_answer_limit) {
      toast.error("Character is less than moderator answer limit");
    } else {
      swal({
        text: "Are you sure to send this message as answer?",
        buttons: true,
        icon: "warning",
      }).then((confirm) => {
        if (confirm) {
          postResponse({ ...data, question_id: question.id, send_to: "answer" }, user.id, history);
        }
      });
    }
  };

  const onCancel = (data) => {
    swal({
      text: "Are you sure to cancel this message?",
      buttons: true,
      icon: "warning",
    }).then((confirm) => {
      if (confirm) {
        postQueryResponse({ ...data, question_id: feedback && feedback[0].id, type: "cancel" }, queryId, history);
      }
    });
  };

  const onFreeQuestion = (data) => {
    swal({
      text: "Are you sure to send a free question?",
      buttons: true,
      icon: "warning",
    }).then((confirm) => {
      if (confirm) {
        postQueryResponse({ ...data, question_id: feedback && feedback[0].id,  type: "free question" }, queryId, history);
      }
    })
      .catch(err => console.log(err));
  };

  const onAstrologerQa = (data) => {
    swal({
      text: "Are you sure to send this message to astrologer?",
      buttons: true,
      icon: "warning",
    }).then((confirm) => {
      if (confirm) {
        postQueryResponse({ ...data, question_id: feedback && feedback[0].id, type: "to astrologer" }, queryId, history);
      }
    })
      .catch(err => console.log(err));
  };

  const onAnswerQa = (data) => {
    swal({
      text: "Are you sure to send this message?",
      buttons: true,
      icon: "warning",
    }).then((confirm) => {
      if (confirm && feedback) {
        postQueryResponse({ ...data, question_id: feedback && feedback[0].id, type: "send" }, queryId, history);
      } else if (confirm && !feedback) {
        console.log("")
      }
    }).catch(err => console.log(err));
  };

  return (
    <div className="main-chat d-flex flex-direction-column position-relative">
      <div className="top-header">
        {questionsOnly && (
          <div className="d-flex align-items-center">
            <div className="scroll-nav mr-3 d-flex flex-column align-items-center">
              {questionCount >= 1 ? (
                <FaChevronUp
                  color="#bc2a20"
                  onClick={() => setQuestionCount(questionCount - 1)}
                  className="pointer"
                />
              ) : (
                <FaChevronUp color="#707070" />
              )}
              <div className="count">{questionCount + 1}</div>
              {questionCount < questionsOnly.length - 1 ? (
                <FaChevronDown
                  color="#bc2a20"
                  onClick={() => setQuestionCount(questionCount + 1)}
                  className="pointer"
                />
              ) : (
                <FaChevronDown color="#707070" />
              )}
            </div>

            <img
              src={
                questionsOnly[questionCount]?.customer?.profile_image
                  ? questionsOnly[questionCount]?.customer?.profile_image
                  : "/assets/images/avatar.png"
              }
              alt=""
              className="user-img"
            />
            <h6 className="pointer">
              {questionsOnly[questionCount]?.question.substring(0, MAX_LENGTH)}
            </h6>
          </div>
        )}
        <p>{questionsOnly[questionCount]?.created.slice(0, 10)}</p>
      </div>

      <div className="roles">
        <Form>
          <Form.Check
            inline
            type="radio"
            label="All"
            name="response"
            onClick={() => {
              setEntityType("");
              setPage(1);
            }}
          />
          <Form.Check
            inline
            type="radio"
            label="System"
            name="response"
            onClick={() => {
              setEntityType("system");
              setPage(1);
            }}
          />
          <Form.Check
            inline
            type="radio"
            label="Questions"
            name="response"
            onClick={() => {
              setEntityType("question");
              setPage(1);
            }}
          />
          <Form.Check
            inline
            type="radio"
            label="Answers"
            name="response"
            onClick={() => {
              setEntityType("answer");
              setPage(1);
            }}
          />
        </Form>
      </div>

      <div className="message-wrapper">
        <div
          className={
            componentVisible
              ? `messages main-conversation-sm`
              : `messages main-conversation`
          }
          id="scrollableDiv"
        >
          <InfiniteScroll
            dataLength={response.length}
            next={loadFunc}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "hidden",
              paddingTop: "10px",
            }}
            inverse={true}
            hasMore={response.length < totalResponse}
            loader={<Spinner animation="border" variant="danger" size="sm" />}
            scrollableTarget="scrollableDiv"
            pullDownToRefreshThreshold={50}
          >
            {response.length > 0 ? (
              response.map((item, index) => (
                <div className="d-flex mb-2">
                  <img
                    src={
                      item.response_from_user.image
                        ? item.response_from_user.image
                        : "/assets/images/avatar.png"
                    }
                    className="message-avatar"
                    alt=""
                  />
                  <Message
                    type={item.response_type}
                    name={item.response_from_user.name}
                    message={item.response_text}
                    pin={item.pinned}
                    created={item.created_at}
                    id={item.id}
                    entity_type={item.entity_type}
                  />
                </div>
              ))
            ) : (
              <Loader />
            )}
          </InfiniteScroll>
        </div>
      </div>

      <div
        className="d-flex pl-3"
        style={{ position: "absolute", bottom: "0", width: "100%" }}
        ref={ref}
      >
        {componentVisible ? null : (
          <div className="message-input">
            <Form.Control
              type="text"
              placeholder="Enter message here"
              {...register("response_text")}
              onClick={() =>
                !componentVisible
                  ? setComponentVisible(true)
                  : setComponentVisible(false)
              }
              ref={autoFocus}
            />
          </div>
        )}
        {componentVisible && (
          <div className="message-input-lg ">
            <Form>
              <MentionsInput
                className="mentions"
                placeholder="Enter message here"
                {...register("response_text")}
                onChange={({ target: { value } }) =>
                  setValue("response_text", value)
                }
                value={message}
              >
                <Mention ref={autoFocus} />
              </MentionsInput>
              {user.user.role !== "superuser" && (
                <div
                  className={
                    message?.length < settings[0].moderator_answer_limit
                      ? `char-count text-danger`
                      : `char-count text-success`
                  }
                >
                  {message?.length}
                </div>
              )}

              <div className="btn-collect">
                <div className="d-flex align-items-center">
                  <div
                    className="clarify-icon mr-2"
                    onClick={() => {
                      setShowClarification(true);
                      setIgnoreClick(true);
                    }}
                    data-tip="Clarification helpers"
                  >
                    {/* <ReactTooltip place="left" type="dark" effect="float" /> */}
                    <FaChevronCircleUp
                      size="24"
                      color="#BC2A20"
                      className="pointer"
                    />
                  </div>
                  <Helpers
                    setMessage={setValue}
                    setIgnoreClick={setIgnoreClick}
                  />
                </div>
                <div>
                  {user.user.role === "astrologer" && (
                    <>
                      <Button
                        className="btn-clarify"
                        onClick={handleSubmit(onAstroClarify)}
                      >
                        Clarify
                      </Button>
                      <Button
                        className="btn-astrologer"
                        onClick={handleSubmit(onModerator)}
                      >
                        Moderator
                      </Button>
                    </>
                  )}
                  {user.user.role === "moderator" && (
                    <>
                      <Button
                        className="btn-clarify"
                        onClick={handleSubmit(onClarify)}
                      >
                        Clarify
                      </Button>
                      <Button
                        className="btn-astrologer"
                        onClick={handleSubmit(onAstrologer)}
                      >
                        Astrologer
                      </Button>
                      <Button
                        className="btn-answer"
                        onClick={handleSubmit(onAnswer)}
                      >
                        Answer
                      </Button>
                    </>
                  )}
                  {(user.user.role === "qa" || user.user.role ===  "superuser" ) && (
                    <>
                      <Button
                        className="btn-cancel"
                        onClick={handleSubmit(onCancel)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-clarify"
                        onClick={handleSubmit(onFreeQuestion)}
                      >
                        Free Question
                      </Button>
                      <Button
                        className="btn-astrologer"
                        onClick={handleSubmit(onAstrologerQa)}
                      >
                        Create New Question
                      </Button>

                      <Button
                        className="btn-answer"
                        onClick={handleSubmit(onAnswerQa)}
                      >
                        Send
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
      <Modal
        show={showClarification}
        onHide={() => {
          setShowClarification(false);
          setIgnoreClick(false);
        }}
        className="modal-left"
      >
        <Modal.Header closeButton>
          <Modal.Title>Clarification Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            {clarificationType.length > 0 &&
              clarificationType.map((item) => (
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={item.clarification_type}
                    className="pointer d-flex align-items-center justify-content-between"
                  >
                    <span>{item.clarification_type}</span>
                    <FaChevronDown />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={item.clarification_type}>
                    <Card.Body>
                      {item.clarifytemplate_set.map((clarify) => (
                        <li
                          className="clarify-list"
                          onClick={() => {
                            setValue("response_text", clarify.clarification);
                            setShowClarification(false);
                            setIgnoreClick(false);
                          }}
                        >
                          {clarify.clarification}
                        </li>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const mapStateToPros = (state) => ({
  response: state.customerQuestions.questionResponse,
  questionsOnly: state.customerQuestions.questionsOnly,
  totalOnly: state.customerQuestions.totalOnly,
  totalResponse: state.customerQuestions.totalResponse,
  question: state.ticket.ticket,
  feedback: state.feedback.singleFeedback,
  user: state.user.user,
  clarificationType: state.clarificationType.clarificationType,
  settings: state.GeneralSettings.settings,
  loading: state.ui.loading,
});

export default connect(mapStateToPros, {
  getTicket,
  getQuestionResponse,
  postResponse,
  getUserData,
  getClarificationType,
  getClarificationTemplate,
  getQuestionsOnly,
  getGeneralSettings,
  postQueryResponse,
})(Main);
