import React from "react";
import { useLocation } from "react-router-dom";

import SidebarData from "./SidebarData";
import SubMenu from "./SubMenu";

const Sidebar = ({ showMenu, setShowMenu }) => {
  const location = useLocation();
  return (
    <div className={showMenu ? "sidebar" : "sidebar sidebar-sm"}>
      <div className="sidebar__wrapper">
        <div className="toggle" onClick={() => setShowMenu(!showMenu)}>
          <img
            src={
              showMenu
                ? "/assets/images/toggle-left.png"
                : "/assets/images/toggle-right.png"
            }
            alt="toggle"
            className="toggle-menu"
          />
        </div>
        {SidebarData.map((item, index) => {
          return (
            <SubMenu
              item={item}
              key={item.id}
              currentPath={location.pathname}
              showMenu={showMenu}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
