import * as ActionTypes from "../ActionTypes";
import { jwtCheck } from "../../_helpers";

const initialState = {
  isLogged: jwtCheck() ? true : false,
  isLoading: false,
  user: null,
  moderator: [],
  astrologer: [],
  allUser: [],
  totalUsers: 0,
  position: null,
  profile: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LOGGEDIN:
      return { ...state, isLogged: true };
    case ActionTypes.SET_LOGGEDOUT:
      return { ...state, isLogged: false, user: null, allUser: [] };
    case ActionTypes.SET_ALL_USER:
      return {
        ...state,
        allUser: action.payload.results,
        totalUsers: action.payload.count,
      };

    case ActionTypes.SET_NEW_USER:
      return {
        ...state,
        allUser: [action.payload, ...state.allUser],
        totalUsers: state.totalUsers + 1,
      };
    case ActionTypes.REMOVE_DELETED_USER:
      const tempUsers = [...state.allUser];
      const index = tempUsers.findIndex((user) => user.id === action.payload);
      tempUsers.splice(index, 1);
      return { ...state, allUser: tempUsers, totalUsers: state.totalUsers - 1 };

    case ActionTypes.EDIT_USER:
      const editedUser = [...state.allUser];
      const editedIndex = editedUser.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        editedUser[editedIndex] = action.payload;
      }

      return { ...state, allUser: [...editedUser] };

    case ActionTypes.GET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case ActionTypes.SET_USER_LOADING:
      return { ...state, isLoading: true };
    case ActionTypes.CLEAR_USER_LOADING:
      return { ...state, isLoading: false };
    case ActionTypes.SET_USER:
      return {
        ...state,
        isLogged: true,
        user: action.payload,
      };
    case ActionTypes.GET_ALL_MODERATOR:
      return {
        ...state,
        moderator: action.payload.results,
      };
    case ActionTypes.GET_ALL_ASTROLOGER:
      return {
        ...state,
        astrologer: action.payload.results,
      };

    case ActionTypes.GET_TEMPLATE_QUESTIONS:
      return {
        ...state,
        questionTemplate: action.payload.results,
        totalQuestionTemplate: action.payload.count,
      };

    default:
      return state;
  }
}
