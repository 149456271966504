import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  postSystemMessage,
  editSystemMessage,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";

const AddMessage = ({
  setShowAdd,
  postSystemMessage,
  systemMessage,
  editSystemMessage,
}) => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    systemMessage
      ? editSystemMessage(data, systemMessage.id)
      : postSystemMessage(data);

    reset();
    setShowAdd(false);
  };
  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Message Type</Form.Label>
                <Form.Control
                  as="select"
                  {...register("system_messages_type")}
                  defaultValue={
                    systemMessage && systemMessage.system_messages_type
                  }
                >
                  <option value="welcome">Welcome</option>
                  <option value="first question">First Question</option>
                  <option value="welcome bonus">Welcome Bonus</option>
                  <option value="dissatisfied (Rating less than 3)">
                    Dissatisfied (Rating &lt; 3)
                  </option>
                  <option value="support request by email">
                    Support request by email
                  </option>
                  <option value="clarify to moderator">
                    Clarify to moderator
                  </option>
                  <option value="clarify to user">Clarify to user</option>
                  <option value="rate on first answer">
                    Rate on first answer
                  </option>
                  <option value="profile change">Profile change</option>
                  <option value="block question">Block Question</option>
                  <option value="return customer credit">
                    Return customer credit
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  row={3}
                  placeholder="eg. Message here"
                  {...register("message")}
                  defaultValue={systemMessage && systemMessage.message}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Button
                className="btn-outline-primary btn-round btn-sm mr-2"
                onClick={() => setShowAdd(false)}
              >
                Cancel
              </Button>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                {systemMessage ? "Edit" : "Send"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { postSystemMessage, editSystemMessage };

export default connect(mapStateToProps, mapDispatchToProps)(AddMessage);
