import React, {useState} from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'

const Layout = (props) => {
  const [showMenu, setShowMenu] = useState(true);
    return (
      <div className="main-wrapper">
        <Header />
        <div className="content-wrapper">
          <Sidebar showMenu={showMenu} setShowMenu={setShowMenu}/>
          <div className={showMenu ? "main-content" : "main-content-sm"}>
            <section className="section">{props.children}</section>
          </div>
        </div>
        <Footer/>
      </div>
    );
}

export default Layout
