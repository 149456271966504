import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  postAstroHelperTemplate,
  getAstroHelperType,
  editAstroHelperTemplate,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";

const AddHelper = ({
  postAstroHelperTemplate,
  editAstroHelperTemplate,
  getAstroHelperType,
  helperType,
  helperData,
  setShowAdd,
}) => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    helperData
      ? editAstroHelperTemplate(data, helperData.id)
      : postAstroHelperTemplate(data);
    reset();
    setShowAdd(false);
  };
  useEffect(() => {
    getAstroHelperType();
  }, [getAstroHelperType]);
  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Template Type</Form.Label>
                <Form.Control
                  as="select"
                  {...register("category")}
                  defaultValue={helperData && helperData.category}
                >
                  {helperType.length > 0 &&
                    helperType.map((item, index) => (
                      <option value={item.id}>{item.category}</option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  type="text"
                  {...register("text")}
                  defaultValue={helperData && helperData.text}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                {helperData ? "Edit" : "Send"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  helperType: state.AstrologerHelper.helperType,
});

const mapDispatchToProps = {
  postAstroHelperTemplate,
  getAstroHelperType,
  editAstroHelperTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHelper);
