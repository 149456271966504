import * as ActionTypes from "../ActionTypes";

const initialState = {
  clarificationTemplate: [],
  totalClarificationTemplate: 0,
  loading: false,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ALL_CLARIFICATION_TEMPLATE:
      return {
        ...state,
        clarificationTemplate: action.payload.results,
        totalClarificationTemplate: action.payload.count,
      };
    case ActionTypes.POST_CLARIFICATION_TEMPLATE:
      const newClari = [...state.clarificationTemplate];
      if (newClari.length >= 10) {
        newClari.pop();
      }
      return {
        ...state,
        clarificationTemplate: [action.payload, ...newClari],
        totalClarificationTemplate:
          newClari < 10
            ? state.totalClarificationTemplate + 1
            : state.totalClarificationTemplate,
      };
    case ActionTypes.DELETE_CLARIFICATION_TEMPLATE:
      const tempClar = [...state.clarificationTemplate];
      const index = tempClar.findIndex((user) => user.id === action.payload);
      tempClar.splice(index, 1);
      return {
        ...state,
        clarificationTemplate: tempClar,
        totalClarificationTemplate: state.totalClarificationTemplate - 1,
      };
    case ActionTypes.EDIT_CLARIFICATION_TEMPLATE:
      const tempClarTemp = [...state.clarificationTemplate];
      const editedIndex = tempClarTemp.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        tempClarTemp[editedIndex] = action.payload;
      }
      return { ...state, clarificationTemplate: tempClarTemp };
    default:
      return state;
  }
}
