import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getPointsReport =
  (page = 1, userType, filter) =>
  async (dispatch) => {
    let filterStr = "";
    if (filter.from_date) {
      filterStr = filterStr + `&from_date=${filter.from_date}`;
    }
    if (filter.to_date) {
      filterStr = filterStr + `&to_date=${filter.to_date}`;
    }
    if (filter.query) {
      filterStr = filterStr + `&query=${filter.query}`;
    }
    dispatch({
      type: ActionTypes.LOADING_UI,
    });

    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `points/${userType}/?page=${page}${filterStr}`
      );
      dispatch({
        type: ActionTypes.GET_POINTS_REPORT,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: ActionTypes.STOP_LOADING_UI,
    });
  };
