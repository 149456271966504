import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
import { getUserData } from "./ActionUser";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

// Question Types

export const getQuestionTypes = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `template/question-type/`
    );
    dispatch({
      type: ActionTypes.GET_QUESTION_TYPES,
      payload: response.results,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postQuestionTypes = (data) => async (dispatch) => {
  try {
    const form_data = new FormData();
    form_data.append("images", data.images[0]);
    form_data.append("question_type", data.question_type);
    const response = await fetchWrapper.postMultipart(
      COMMON_URL + `template/question-type/`,
      form_data
    );
    dispatch({
      type: ActionTypes.POST_QUESTION_TYPES,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: { error: false, message: "Question type added successfully" },
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteQuestionType = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `template/question-type/${id}`, id);
    dispatch({
      type: ActionTypes.DELETE_QUESTION_TYPES,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Question type deleted successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

// Question Template

export const getTemplateQuestions =
  (page = 1) =>
    async (dispatch) => {
      dispatch({
        type: ActionTypes.LOADING_UI,
      });
      try {
        const response = await fetchWrapper.get(
          COMMON_URL + `template/question/?page=${page}`
        );
        dispatch({
          type: ActionTypes.GET_TEMPLATE_QUESTIONS,
          payload: response,
        });
      } catch (e) {
        console.log(e);
      }
      dispatch({
        type: ActionTypes.STOP_LOADING_UI,
      });
    };

export const postNewQuestionTemplate = (data) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `template/question/`,
      data
    );
    dispatch({
      type: ActionTypes.POST_TEMPLATE_QUESTIONS,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Question template added successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};
export const editQuestionTemplate = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `template/question/${id}/`,
      data
    );
    dispatch({ type: ActionTypes.EDIT_TEMPLATE_QUESTION, payload: response });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Question template edited successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteQuestionTemplate = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(COMMON_URL + `template/question/${id}`, id);
    dispatch({
      type: ActionTypes.DELETE_TEMPLATE_QUESTION,
      payload: id,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Question template deleted successfully",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

// Customer Questions

export const getCustomerQuestions =
  (page = 1, filter) =>
    async (dispatch) => {
      let filterStr = "";
      if (filter.from) {
        filterStr = filterStr + `&from=${filter.from}`;
      }
      if (filter.to) {
        filterStr = filterStr + `&to=${filter.to}`;
      }
      if (filter.state) {
        filterStr = filterStr + `&state=${filter.state}`;
      }
      if (filter.status) {
        filterStr = filterStr + `&status=${filter.status}`;
      }
      if (filter.country) {
        filterStr = filterStr + `&country=${filter.country}`;
      }
      if (filter.query) {
        filterStr = filterStr + `&query=${filter.query}`;
      }
      dispatch({
        type: ActionTypes.LOADING_UI,
      });
      try {
        const response = await fetchWrapper.get(
          COMMON_URL + `question/?page=${page}${filterStr}`
        );
        dispatch({
          type: ActionTypes.GET_CUSTOMER_QUESTIONS,
          payload: response,
        });
      } catch (e) {
        console.log(e);
      }
      dispatch({
        type: ActionTypes.STOP_LOADING_UI,
      });
    };

export const getQuestionsOnly = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `question/?customer=${id}`
    );
    dispatch({
      type: ActionTypes.GET_QUESTIONS_ONLY,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

// Customer Question to moderator
export const getModeratorQuestion = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(COMMON_URL + `open-ticket/`);
    dispatch({
      type: ActionTypes.GET_MODERATOR_QUESTIONS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};
// Customer Question to moderator
export const getQuestionResponse =
  (id, page = 1, entityType, query) =>
    async (dispatch) => {
      let entityStr = "";
      if (entityType) {
        entityStr = entityStr + `&entity_type=${entityType}`;
      }
      if (query) {
        entityStr = entityStr + `&duplicate=${query}`;
      }
      try {
        const response = await fetchWrapper.get(
          COMMON_URL +
          `customer/profile/${id}/responses/?page=${page}${entityStr}`
        );
        dispatch({
          type:
            page === 1
              ? ActionTypes.GET_FILTERED_RESPONSE
              : ActionTypes.GET_QUESTIONS_RESPONSE,
          payload: response,
        });
      } catch (e) {
        console.log(e);
      }
    };

export const postResponse = (data, id, history) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `profile/${id}/response/`,
      data
    );
    dispatch({
      type: ActionTypes.POST_RESPONSE,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Message sent successfully",
      },
    });
    dispatch(getUserData(history));
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: error?.data,
      },
    });
  }
};

export const assignModerator = (id, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOADING_UI,
  });
  try {
    const response = await fetchWrapper.post(COMMON_URL + `assign-moderator/`, {
      question_id: id,
      moderator: data,
      state: "Assigned",
    });
    dispatch({
      type: ActionTypes.ASSIGN_MODERATOR,
      payload: response,
    });
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: false,
        message: "Question successfully assigned to moderator.",
      },
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: ActionTypes.STOP_LOADING_UI,
  });
};

// get question by Id
export const getQuestionById = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(COMMON_URL + `question/${id}/`);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const changeQuestionState = (id, state) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(COMMON_URL + `question/${id}/change-status/`, { state: state });
    return response
  } catch (e) {
    console.log(e)
  }
}