import * as ActionTypes from "../ActionTypes";

const initialState = {
  notifications: [],
  totalNotifications: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.results,
        totalNotifications: action.payload.count,
      };
    case ActionTypes.POST_NEW_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };

    case ActionTypes.DELETE_NOTIFICATION:
      const tempMsg = [...state.notifications];
      const index = tempMsg.findIndex((user) => user.id === action.payload);
      tempMsg.splice(index, 1);
      return {
        ...state,
        notifications: tempMsg,
        totalNotifications: state.totalNotifications - 1,
      };
    default:
      return state;
  }
}
