import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";

const NotFound = () => {
  return (
    <>
      <Header />
      <Container className="mt-5">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1>The page you are looking is not found</h1>
          <Link to="/welcome" className="text-center text-danger">
            Go Back
          </Link>
        </div>
      </Container>
    </>
  );
};

export default NotFound;
