import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Layout from "../../../layouts/Layout";
import { useForm } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import {
  getGeneralSettings,
  postGeneralSettings,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import Loader from "../../../components/Loader";

const GeneralSetting = ({
  getGeneralSettings,
  settings,
  postGeneralSettings,
  loading,
}) => {
  const [content, setContent] = useState(null);
  useEffect(() => {
    getGeneralSettings();
  }, [getGeneralSettings]);

  useEffect(() => {
    if (settings.length > 0) {
      setContent(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(settings[0].notice))
        )
      );
    }
  }, [settings]);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    data.notice =
      content && draftToHtml(convertToRaw(content.getCurrentContent()));
    postGeneralSettings(data);
  };

  return (
    <>
      {loading && <Loader />}
      <Layout>
        <div className="setting-wrapper">
          <Row>
            <Col md={4}>
              <h5 className="font-weight-bold text-danger">General Settings</h5>
            </Col>
          </Row>
          {!loading && settings.length > 0 && (
            <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Notice</Form.Label>

                    <Editor
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      editorState={content}
                      onEditorStateChange={(e) => setContent(e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Question price</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={settings[0].question_price}
                      {...register("question_price")}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Blocked character</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={settings[0].blocked_characters}
                      {...register("blocked_characters")}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Astrologer answer limit</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={settings[0].astrologer_answer_limit}
                      {...register("astrologer_answer_limit")}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Moderator answer limit</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={settings[0].moderator_answer_limit}
                      {...register("moderator_answer_limit")}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Clarification limit</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={settings[0].clarification_limit}
                      {...register("clarification_limit")}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          )}
        </div>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => ({
  settings: state.GeneralSettings.settings,
  loading: state.ui.loading,
});

export default connect(mapStateToProps, {
  getGeneralSettings,
  postGeneralSettings,
})(GeneralSetting);
