import * as ActionTypes from "../ActionTypes";

const initialState = {
  clarificationType: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ALL_CLARIFICATION_TYPE:
      return { ...state, clarificationType: action.payload.results };
    case ActionTypes.POST_CLARIFICATION_TYPE:
      return {
        ...state,
        clarificationType: [action.payload, ...state.clarificationType],
      };
    case ActionTypes.DELETE_CLARIFICATION_TYPE:
      const tempClar = [...state.clarificationType];
      const index = tempClar.findIndex((user) => user.id === action.payload);
      tempClar.splice(index, 1);
      return { ...state, clarificationType: tempClar };
    default:
      return state;
  }
}
