import * as ActionTypes from "../ActionTypes";

const initialState = {
  customerQuestions: [],
  questionsOnly: [],
  totalOnly: [],
  questionResponse: [],
  totalQuestions: [],
  totalResponse: [],
  postResponse: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_QUESTIONS:
      return {
        ...state,
        customerQuestions: action.payload.results,
        totalQuestions: action.payload.count,
      };
    case ActionTypes.GET_QUESTIONS_ONLY:
      return {
        ...state,
        questionsOnly: action.payload.results,
        totalOnly: action.payload.count,
      };

    case ActionTypes.GET_QUESTIONS_RESPONSE:
      return {
        ...state,
        questionResponse: [
          ...state.questionResponse,
          ...action.payload.results,
        ],
        totalResponse: action.payload.count,
      };
    case ActionTypes.GET_FILTERED_RESPONSE:
      return {
        ...state,
        questionResponse: [...action.payload.results],
        totalResponse: action.payload.count,
      };
    case ActionTypes.POST_RESPONSE:
      return {
        ...state,
        postResponse: [action.payload, ...state.postResponse],
      };
    case ActionTypes.PATCH_QUESTIONS_RESPONSE:
      const tempResponse = [...state.questionResponse];
      const index = tempResponse.findIndex(
        (res) => res.id === action.payload.id
      );
      if (index !== -1) {
        tempResponse[index] = action.payload;
      }
      return {
        ...state,
        questionResponse: tempResponse,
      };
    default:
      return state;
  }
}
