import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  FaCloudUploadAlt,
  FaCopy,
  FaImage,
  FaInfoCircle,
  FaQuestionCircle,
  FaTrashAlt,
  FaUserCircle,
} from "react-icons/fa";
import { AiFillApple, AiFillAndroid } from "react-icons/ai";
import Layout from "../../layouts/Layout";
import Message from "../Chat/Message";
import {
  getCustomerProfile,
  getCustomerImages,
  postCustomerImages,
  deleteCustomerImages,
  addCustomerCredit,
} from "../../_redux/actions/ActionCustomers";
import { getQuestionResponse } from "../../_redux/actions/ActionQuestions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactCountryFlag from "react-country-flag";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";


const Profile = ({
  match,
  getCustomerProfile,
  customer,
  getCustomerImages,
  postCustomerImages,
  images,
  totalResponse,
  deleteCustomerImages,
  addCustomerCredit,
  getQuestionResponse,
  response,
}) => {
  const [page, setPage] = useState(1);
  const [entityType] = useState("");

  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getCustomerProfile(match.params.id);
    getQuestionResponse(match.params.id, 1, entityType, query);
  }, [
    entityType,
    getCustomerProfile,
    getQuestionResponse,
    match.params.id,
    query,
  ]);

  useEffect(() => {
    if (customer) {
      getCustomerImages(customer.id);
    }
  }, [customer, getCustomerImages]);

  const loadFunc = () => {
    getQuestionResponse(match.params.id, page + 1, entityType, query);
    setPage(page + 1);
  };

  const { register, watch, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });
  const watchImage = watch("image");

  const onSubmit = (files) => {
    postCustomerImages(files, customer.id);
  };

  const handleAddCredit = () => {
    const data = { customer_id: customer.id, credit: 1 };
    addCustomerCredit(data);
  };

  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };
  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  return (
    <Layout>
      <div className="profile-section">
        {customer && (
          <Row>
            <Col md={8}>
              <div className="profile-wrapper">
                <Card>
                  <div className="profile-header">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          customer.profile_image
                            ? customer.profile_image
                            : "/assets/images/avatar.png"
                        }
                        alt="profile"
                        className="profile-img"
                      />
                      <div>
                        <h5 className="d-flex">
                          {customer.name}{" "}
                          {customer.user?.agent === "android" ? (
                            <p
                              className="bg-dark mb-0 d-flex justify-content-center align-items-center mx-1"
                              style={{ borderRadius: "2px", padding: "1px" }}
                            >
                              <AiFillAndroid color="white" />
                            </p>
                          ) : customer.user?.agent === "ios" ? (
                            <p
                              className="bg-dark mb-0 d-flex justify-content-center align-items-center mx-1"
                              style={{ borderRadius: "2px", padding: "1px" }}
                            >
                              <AiFillApple color="white" />
                            </p>
                          ) : null}
                        </h5>
                        <p>Customer ID: {customer.user?.id}</p>
                      </div>
                    </div>
                    <div className="queri">
                      <h5>{customer.user?.username}</h5>
                      <p>Username</p>
                    </div>

                    <Button
                      className="btn-primary btn-round"
                      onClick={() => {
                        swal({
                          text: "Do you want to provide free question ?",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((confirm) => {
                          if (confirm) {
                            handleAddCredit();
                          }
                        });
                      }}
                    >
                      Provide free question
                    </Button>
                  </div>

                  <Row>
                    <Col md={6}>
                      <div className="profile-info">
                        <span className="d-flex align-items-center">
                          <FaUserCircle className="mr-1 text-info" size="18" />
                          <p className="info-heading text-info">
                            Identification
                          </p>
                        </span>
                        <div class="d-flex mt-3 flex-column">
                          <div className="queries mb-3">
                            <h5>{customer.dob_AD}</h5>
                            <p>Joined on</p>
                          </div>
                          <div className="queries mb-3">
                            <h5 className="text-break">
                              {customer.duplicates &&
                                customer.duplicates.length > 0
                                ? customer.duplicates.map((item, index) => (
                                  <Link
                                    to={`/customer/profile/${customer.duplicates[index]}`}
                                  >
                                    {customer.duplicates[index]},
                                  </Link>
                                ))
                                : "None"}
                            </h5>
                            <p>Duplicates</p>
                          </div>
                        </div>
                        <div class="d-flex flex-column">
                          <div className="queries mb-3">
                            <h5>{customer.device_id}</h5>
                            <p>Device ID</p>
                          </div>
                          <div className="queries">
                            <h5>
                              <FaCopy
                                size="25"
                                onClick={() => {
                                  toast.success("Text copied to clipboard");
                                  navigator.clipboard.writeText(customer.fcm_token);
                                }}
                              />
                            </h5>
                            <p>Copy FCM Token</p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info">
                        <div className="d-flex justify-content-between">
                          <span className="d-flex align-items-center">
                            <FaInfoCircle
                              className="mr-1 text-danger"
                              size="18"
                            />
                            <p className="info-heading text-danger">
                              Information
                            </p>
                          </span>
                        </div>
                        <div class="d-flex align-items-end mt-3 mx-4">
                          <div className="queries">
                            <h5>{customer.gender}</h5>
                            <p>Gender</p>
                          </div>
                          <div className="queries">
                            <h5>{customer.age}</h5>
                            <p>Age</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-end mt-3 mx-4">
                          <div className="queries">
                            <h5>{customer.dob_AD}</h5>
                            <p>Date of Birth</p>
                          </div>
                          <div className="queries">
                            <h5>{customer.birth_time} </h5>
                            <span className="accurate ml-0">
                              {customer.is_bt_accurate
                                ? "Accurate"
                                : "Not Accurate"}
                            </span>
                            <p>Time of Birth</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-end mt-3 mx-4">
                          <div className="queries">
                            <h5>
                              <ReactCountryFlag
                                className="emojiFlag mr-1"
                                countryCode={customer.country_code}
                                style={{
                                  fontSize: "2em",
                                }}
                                title={customer.country}
                                svg
                              />
                              {customer.country}
                            </h5>
                            <p>Country</p>
                          </div>
                          <div className="queries">
                            <h5>{customer.address}</h5>
                            <p>Place</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-end mt-3 mx-4">
                          <div className="queries">
                            <h5>{customer.user?.phone}</h5>
                            <p>Contact</p>
                          </div>
                          <div className="queries">
                            <h5>{customer.zodiac_name}</h5>
                            <p>Zodiac</p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-queries">
                        <span className="d-flex align-items-center">
                          <FaQuestionCircle
                            color="#1C70BE"
                            className="mr-1"
                            size="18"
                          />
                          <p className="info-heading">Queries sent</p>
                        </span>
                        <Row className="mt-3 ml-2">
                          <Col md={6}>
                            <div className="queries mb-2">
                              <h5>{customer.stats?.total}</h5>
                              <p>Total</p>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="queries mb-2">
                              <h5>{customer.stats?.paid}</h5>
                              <p>Paid</p>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="queries">
                              <h5>{customer.stats?.free}</h5>
                              <p>Free</p>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="queries">
                              <h5>{customer.stats?.answered}</h5>
                              <p>Answered</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="profile-image">
                        <span className="d-flex align-items-center">
                          <FaImage className="mr-1" size="18" color="#7A57FF" />
                          <p className="info-heading">Image</p>
                        </span>
                        <Row className="mt-3 ml-2">
                          <Col md={12}>
                            <div className="drag-area mb-3">
                              <div class="icon">
                                <FaCloudUploadAlt color="#B8B8B8" />
                              </div>
                              <header>Drag & Drop</header>
                              <span>Or</span>
                              <Form
                                onSubmit={handleSubmit(onSubmit)}
                                className="d-flex flex-column align-items-center"
                              >
                                <div className="file-upload-wrapper">
                                  <Form.Control
                                    type="file"
                                    {...register("image")}
                                    className="customer-file-input mb-2"
                                    multiple
                                  />
                                </div>

                                <Button
                                  className="btn-primary btn-round"
                                  type="submit"
                                  disabled={watchImage?.length ? false : true}
                                >
                                  Upload Image
                                </Button>
                              </Form>
                            </div>
                          </Col>
                          <Col md={12}>
                            <Row noGutters>
                              {images.length > 0 &&
                                images.map((image, index) => (
                                  <Col md={6}>
                                    <img
                                      src={image.image}
                                      alt={image.image_title}
                                      className="kundali pointer"
                                      onClick={() => showImage(image.image)}
                                    />

                                    <span
                                      className="deleteIcon pointer"
                                      onClick={() =>
                                        deleteCustomerImages(
                                          customer.id,
                                          image.id
                                        )
                                      }
                                    >
                                      <FaTrashAlt size="8" />
                                    </span>
                                  </Col>
                                ))}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col md={4}>
              {customer.duplicates && customer.duplicates.length > 0 && (
                <div className="d-flex align-items-center justify-content-end dublicate-switch">
                  <p className="text-danger mr-2">
                    {query === ""
                      ? "Show Dublicates Chat History"
                      : "Hide Dublicates Chat History"}
                  </p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onClick={() => {
                        if (query === "" ? setQuery("show") : setQuery(""))
                          setPage(1);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              )}

              <div className="message-wrapper bg-white">
                <div className="messages main-conversation" id="scrollableDiv">
                  <InfiniteScroll
                    dataLength={response.length}
                    next={loadFunc}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflow: "hidden",
                      paddingTop: "10px",
                    }}
                    inverse={true}
                    hasMore={response.length < totalResponse}
                    loader={
                      <Spinner animation="border" variant="danger" size="sm" />
                    }
                    scrollableTarget="scrollableDiv"
                    pullDownToRefreshThreshold={50}
                  >
                    {response.length > 0 ? (
                      response.map((item, index) => (
                        <div className="d-flex mb-2">
                          <img
                            src={
                              item.response_from_user.image
                                ? item.response_from_user.image
                                : "/assets/images/avatar.png"
                            }
                            className="message-avatar"
                            alt=""
                          />
                          <Message
                            type={item.response_type}
                            name={item.response_from_user.name}
                            message={item.response_text}
                            pin={item.pinned}
                            created={item.created_at}
                            id={item.id}
                            entity_type={item.entity_type}
                          />
                        </div>
                      ))
                    ) : (
                      <Loader />
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {lightboxDisplay && (
        <div id="lightbox" onClick={hideLightBox}>
          <img id="lightbox-img" src={imageToShow} alt=""></img>
        </div>
      )}
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  customer: state.customers.customer,
  images: state.customers.images,
  question: state.ticket.ticket,
  response: state.customerQuestions.questionResponse,
  totalResponse: state.customerQuestions.totalResponse,
});
export default connect(mapStateToProps, {
  getCustomerProfile,
  getCustomerImages,
  postCustomerImages,
  deleteCustomerImages,
  addCustomerCredit,
  getQuestionResponse,
})(Profile);
