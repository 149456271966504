import React from "react";

const Query = ({ customer, singleFeedback }) => {
  return (
    <div className="message-wrapper">
      <div className="messages flex-column">
        {singleFeedback &&
          singleFeedback.map((item, index) => (
            <div className={`single-message customer mt-3`}>
              <div className="d-flex justify-content-between align-items-center">
                <h5>{item.extra_details.name}</h5>
              </div>
              <h6>Customer</h6>
              <p>{item.text}</p>
              <p className="time">{item.created_at} ago</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Query;
