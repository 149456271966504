import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Notes from "./Notes";
import PinnedMessage from "./PinnedMessage";
import { getCustomerNotes } from "../../_redux/actions/ActionCustomers";
import { connect } from "react-redux";
import Query from "./Query";
import { getSingleFeedback } from "../../_redux/actions/ActionFeedback";

const RightBar = ({
  getCustomerNotes,
  getSingleFeedback,
  notes,
  customer,
  user,
  singleFeedback,
}) => {
  useEffect(() => {
    getCustomerNotes(customer.id);
    if (user.user?.role === "qa" || user.user?.role === "superuser") {
      getSingleFeedback(customer.id);
    }
  }, [getCustomerNotes, customer.id, getSingleFeedback, user.user?.role]);
  return (
    <div className="rightbar">
      <Tabs
        defaultActiveKey={
          (user && user.user?.role === "qa") || user.user?.role === "superuser"
            ? `query`
            : `pinned`
        }
        id="uncontrolled-tab-example"
      >
        {user && (user.user?.role === "qa" || user.user?.role === "superuser") && (
          <Tab eventKey="query" title="Queries">
            <Query customer={customer} singleFeedback={singleFeedback} />
          </Tab>
        )}
        <Tab eventKey="pinned" title="Pinned Message">
          <PinnedMessage customer={customer} />
        </Tab>
        <Tab eventKey="notes" title="User Notes">
          {notes && <Notes notes={notes} customer={customer} />}
        </Tab>
      </Tabs>
    </div>
  );
};
const mapStateToProps = (state) => ({
  notes: state.customers.notes,
  user: state.user.user,
  singleFeedback: state.feedback.singleFeedback,
});

export default connect(mapStateToProps, {
  getCustomerNotes,
  getSingleFeedback,
})(RightBar);
