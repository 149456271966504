import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getDashboardStats = () => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(
      COMMON_URL + `admin-dashboard/stats/`
    );
    dispatch({
      type: ActionTypes.GET_DASHBOARD_STATS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};
