import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import Layout from "../../../layouts/Layout";
import AddNotification from "./AddNotification";
import {
  deleteNotification,
  getNotifications,
} from "../../../_redux/actions/ActionSettings";
import { connect } from "react-redux";
import PaginationComp from "../../../components/PaginationComp";
import Loader from "../../../components/Loader";
import swal from "sweetalert";

const SystemNotification = ({
  getNotifications,
  notifications,
  totalNotifications,
  loading,
  deleteNotification,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getNotifications(page);
  }, [getNotifications, page]);
  return (
    <Layout>
      <div className="questions-section">
        <Row className="mt-3">
          <Col md={4}>
            <h5 className="font-weight-bold text-danger mb-2">
              System Notification
            </h5>
          </Col>
          <Col md={{ span: 2, offset: 6 }}>
            {!showAdd && (
              <Button
                className="btn-primary btn-round float-right mb-2"
                onClick={() => setShowAdd(!showAdd)}
              >
                Add Notification
              </Button>
            )}
            {showAdd && (
              <Button
                className="btn-primary btn-round float-right mb-2"
                onClick={() => setShowAdd(!showAdd)}
              >
                Cancel
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={12}>
            {showAdd && (
              <AddNotification showAdd={showAdd} setShowAdd={setShowAdd} />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {!showAdd && (
              <div className="dashboard-table">
                <Card className="table-minheight">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th>Delivery Time</th>
                        <th>Delivery Type</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading ? (
                        notifications.length > 0 &&
                        notifications.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td width="5%">{index + 1}</td>
                              <td width="15%">{item.delivery_datetime}</td>
                              <td width="15%">{item.delivery_type}</td>
                              <td width="10%">{item.title}</td>
                              <td width="45%">{item.message}</td>
                              <td width="10%" className="text-center">
                                <FaTrashAlt
                                  className="text-danger pointer"
                                  onClick={() => {
                                    swal({
                                      title: "Are you sure?",
                                      text: "Once deleted, you will not be able to recover !",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        deleteNotification(item.id);
                                      }
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </Table>
                  {totalNotifications > 10 && (
                    <PaginationComp
                      total={totalNotifications}
                      page={page}
                      setPage={setPage}
                    />
                  )}
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  totalNotifications: state.notifications.totalNotifications,
  loading: state.ui.loading,
});

export default connect(mapStateToProps, {
  getNotifications,
  deleteNotification,
})(SystemNotification);
