import * as ActionTypes from "../ActionTypes";

const initialState = {
  feedback: [],
  totalFeedback: [],
  singleFeedback: [],
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_QUERY_FEEDBACK:
      return {
        ...state,
        feedback: action.payload.results,
        totalFeedback: action.payload.count,
      };
    case ActionTypes.GET_SINGLE_QUERY_FEEDBACK:
      return {
        ...state,
        singleFeedback: action.payload.results,
      };

    default:
      return state;
  }
}
