import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainTitle from "../../components/MainTitle";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//redux stuff
import { connect } from "react-redux";
import { loginUser } from "../../_redux/actions/ActionAuthentication";

// react-icons
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const bg = {
  background: "url('/assets/images/astro.jpg') no-repeat",
  backgroundSize: "cover",
};

const schema = yup.object().shape({
  email: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

const eye = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 20,
  width: 20,
  right: 10,
  bottom: 10,
  cursor: "pointer",
};

const Login = ({ loginUser }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (user) => {
    loginUser(user, history);
  };

  return (
    <div className="login">
      <div className="login__wrapper" style={bg}>
        <div className="login__form">
          <img
            src="/assets/images/karmic_vert.png"
            alt="logo"
            className="logo"
          />
          <MainTitle title="Log in" />
          <Form onSubmit={handleSubmit(handleLogin)}>
            <Form.Group>
              <Form.Label for="username">Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username/Email"
                id="username"
                {...register("email")}
              />
              <Form.Text className="text-danger">
                {errors.email?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label for="password">Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  {...register("password")}
                />
                <div style={eye}>
                  {showPassword ? (
                    <AiFillEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <AiFillEyeInvisible onClick={() => setShowPassword(true)} />
                  )}
                </div>
              </div>
              <Form.Text className="text-danger">
                {errors.password?.message}
              </Form.Text>
            </Form.Group>
            <Link to="/forgot" className="forgot-password">
              <p>Forgot your password?</p>
            </Link>
            <Form.Group>
              <Form.Check name="checkbox" label="Remember me" />
            </Form.Group>
            <Button
              className="btn-primary mb-2 d-block btn-round"
              type="submit"
            >
              Login
            </Button>
          </Form>
          <div className="copyright">
            <p>Designed and Powered by</p>
            <a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
              Ideabreed IT Solution Pvt. Ltd.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { loginUser };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
