import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Accordion, Card, Modal } from "react-bootstrap";
import { FaChevronDown, FaInfoCircle } from "react-icons/fa";
import { connect } from "react-redux";
import {
  getAstroHelperType,
  getModHelperType,
  postAstroHelperTemplate,
  postModHelperTemplate,
} from "../../../_redux/actions/ActionSettings";
import SingleCategory from "./SingleCategory";
import ReactTooltip from "react-tooltip";

const Helpers = ({
  setMessage,
  user,
  helper,
  postAstroHelperTemplate,
  postModHelperTemplate,
  getAstroHelperType,
  getModHelperType,
  setIgnoreClick,
  handleHelper,
}) => {
  const [showHelper, setShowHelper] = useState(false);

  useEffect(() => {
    if (user.user.role === "astrologer") {
      getAstroHelperType();
    } else {
      getModHelperType();
    }
  }, [getAstroHelperType, getModHelperType, user.user.role]);

  const handleShowHelper = () => {
    setShowHelper(true);
  };

  return (
    <Fragment>
      <div
        className="clarify-icon"
        onClick={() => {
          handleShowHelper(true);
          setIgnoreClick(true);
        }}
        data-tip="Helper templates"
      >
        <ReactTooltip place="right" type="dark" effect="float" />
        <FaInfoCircle size="24" color="#BC2A20" className="pointer" />
      </div>
      <Modal
        show={showHelper}
        onHide={() => {
          setShowHelper(false);
          setIgnoreClick(false);
        }}
        className="modal-left"
      >
        <Modal.Header closeButton>
          <Modal.Title>Helper Text Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            {helper?.length > 0 &&
              helper.map((item) => (
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={item.category}
                    className="pointer d-flex align-items-center justify-content-between"
                  >
                    <span>{item.category}</span>
                    <FaChevronDown />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={item.category}>
                    <SingleCategory
                      setMessage={setMessage}
                      user={user}
                      item={item}
                      postAstroHelperTemplate={postAstroHelperTemplate}
                      postModHelperTemplate={postModHelperTemplate}
                      getAstroHelperType={getAstroHelperType}
                      getModHelperType={getModHelperType}
                      handleHelper={handleHelper}
                      setShowHelper={setShowHelper}
                      setIgnoreClick={setIgnoreClick}
                    />
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const mapStateToPros = (state) => ({
  user: state.user.user,
  helper:
    state?.user?.user?.user.role === "astrologer"
      ? state.AstrologerHelper?.helperType
      : state.moderatorHelper?.helperType,
});

export default connect(mapStateToPros, {
  getAstroHelperType,
  getModHelperType,
  postAstroHelperTemplate,
  postModHelperTemplate,
})(Helpers);
