import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  Container,
} from "react-bootstrap";
import {
  FaCheckCircle,
  FaCloudUploadAlt,
  FaImage,
  FaInfoCircle,
  FaQuestionCircle,
  FaTrashAlt,
  FaUserCircle,
} from "react-icons/fa";
import Message from "../Chat/Message";
import {
  getCustomerProfile,
  getCustomerImages,
  postCustomerImages,
  addCustomerCredit,
  deleteCustomerImages,
} from "../../_redux/actions/ActionCustomers";
import { getQuestionResponse } from "../../_redux/actions/ActionQuestions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import swal from "sweetalert";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";

const CustomersProfile = ({
  match,
  getCustomerProfile,
  customer,
  getCustomerImages,
  postCustomerImages,
  images,
  question,
  response,
  totalResponse,
  addCustomerCredit,
  getQuestionResponse,
  deleteCustomerImages,
}) => {
  const [page, setPage] = useState(1);
  const [entityType] = useState("");
  const [query, setQuery] = useState("");
  useEffect(() => {
    getQuestionResponse(match.params.id, page, entityType, query);
  }, [entityType, getQuestionResponse, match.params.id, page, query]);

  const loadFunc = () => {
    getQuestionResponse(match.params.id, page + 1, entityType, query);
    setPage(page + 1);
  };

  useEffect(() => {
    getCustomerProfile(match.params.id);
  }, [getCustomerProfile, match.params.id]);

  useEffect(() => {
    if (customer) {
      getCustomerImages(customer.id);
    }
  }, [customer, getCustomerImages]);
  const { register, watch, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const watchImage = watch("image");
  const onSubmit = (data) => {
    postCustomerImages(data, customer.id);
  };

  const handleAddCredit = () => {
    const data = { customer_id: customer.id, credit: 1 };
    addCustomerCredit(data);
  };

  return (
    <>
      <Header />
      <div className="profile-section minh-100">
        {customer && (
          <Container>
            <Row className="mt-5 mb-4">
              <Col md={8}>
                <div className="profile-wrapper">
                  <Card>
                    <div className="profile-header">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            customer.profile_image
                              ? customer.profile_image
                              : "/assets/images/avatar.png"
                          }
                          alt="profile"
                          className="profile-img"
                        />
                        <div>
                          <h5>{customer.name}</h5>
                          <p>Customer id: {customer.user?.id}</p>
                        </div>
                      </div>

                      <Button
                        className="btn-primary btn-round"
                        onClick={() => {
                          swal({
                            // title: "Are you sure?",
                            text: "Do you want to provide free question ?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((confirm) => {
                            if (confirm) {
                              handleAddCredit();
                            }
                          });
                        }}
                      >
                        Provide free question
                      </Button>
                    </div>
                    <div className="profile-info">
                      <span className="d-flex align-items-center">
                        <FaUserCircle className="mr-1 text-info" size="18" />
                        <p className="info-heading text-info">Identification</p>
                      </span>
                      <div class="d-flex align-items-end mt-3 mx-4">
                        <div className="queries">
                          <h5>{customer.user?.username}</h5>
                          <p>Username</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.dob_AD}</h5>
                          <p>Joined on</p>
                        </div>
                        <div className="queries">
                          <h5>
                            {customer.duplicates &&
                            customer.duplicates.length > 0
                              ? customer.duplicates.map((item, index) => (
                                  <Link
                                    to={`/customer/profile/${customer.duplicates[index]}`}
                                  >
                                    {customer.duplicates[index]},
                                  </Link>
                                ))
                              : "None"}
                          </h5>
                          <p>Duplicates</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-info">
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center">
                          <FaInfoCircle
                            className="mr-1 text-danger"
                            size="18"
                          />
                          <p className="info-heading text-danger">
                            Information
                          </p>
                        </span>
                      </div>
                      <div class="d-flex align-items-end mt-3 mx-4">
                        <div className="queries">
                          <h5>{customer.gender}</h5>
                          <p>Gender</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.age}</h5>
                          <p>Age</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.dob_AD}</h5>
                          <p>Date of Birth</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.birth_time} </h5>
                          <span className="accurate ml-0">
                            {customer.is_bt_accurate
                              ? "Accurate"
                              : "Not Accurate"}
                          </span>
                          <p>Time of Birth</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-end mt-3 mx-4">
                        <div className="queries">
                          <h5>
                            <ReactCountryFlag
                              className="emojiFlag mr-1"
                              countryCode={customer.country_code}
                              style={{
                                fontSize: "2em",
                              }}
                              title={customer.country}
                              svg
                            />
                            {customer.country}
                          </h5>
                          <p>Country</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.address}</h5>
                          <p>Place</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.user?.phone}</h5>
                          <p>Contact</p>
                        </div>
                        <div className="queries">
                          <h5>{customer.zodiac}</h5>
                          <p>Zodiac</p>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="profile-queries">
                          <span className="d-flex align-items-center">
                            <FaQuestionCircle
                              color="#1C70BE"
                              className="mr-1"
                              size="18"
                            />
                            <p className="info-heading">Queries sent</p>
                          </span>
                          <Row className="mt-3 ml-2">
                            <Col md={4}>
                              <div className="queries">
                                <h5>15</h5>
                                <p>Total</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="queries">
                                <h5>14</h5>
                                <p>Paid</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="queries">
                                <h5>1</h5>
                                <p>Free</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="profile-queries">
                          <span className="d-flex align-items-center">
                            <FaCheckCircle
                              className="mr-1 text-success"
                              size="18"
                            />
                            <p className="info-heading text-success">
                              Queries answer received
                            </p>
                          </span>
                          <Row className="mt-3 ml-2">
                            <Col md={4}>
                              <div className="queries">
                                <h5>15</h5>
                                <p>Total</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="queries">
                                <h5>14</h5>
                                <p>Paid</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="queries">
                                <h5>1</h5>
                                <p>Free</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <div className="profile-image">
                      <span className="d-flex align-items-center">
                        <FaImage className="mr-1" size="18" color="#7A57FF" />
                        <p className="info-heading">Image</p>
                      </span>
                      <Row className="mt-3 ml-2">
                        <Col md={4}>
                          <div className="drag-area">
                            <div class="icon">
                              <FaCloudUploadAlt color="#B8B8B8" />
                            </div>
                            <header>Drag & Drop</header>
                            <span>Or</span>
                            <Form
                              onSubmit={handleSubmit(onSubmit)}
                              className="d-flex flex-column align-items-center"
                            >
                              <div className="file-upload-wrapper">
                                <Form.Control
                                  type="file"
                                  {...register("image")}
                                  className="customer-file-input mb-2"
                                  multiple
                                />
                              </div>

                              <Button
                                className="btn-primary btn-round"
                                type="submit"
                                disabled={watchImage?.length ? false : true}
                              >
                                Upload Image
                              </Button>
                            </Form>
                          </div>
                        </Col>

                        <Col md={8}>
                          <Row noGutters>
                            {images.length > 0 &&
                              images.map((image, index) => (
                                <Col md={4}>
                                  <img
                                    src={image.image}
                                    alt="kundali"
                                    className="kundali"
                                  />

                                  <span
                                    className="deleteIcon pointer"
                                    onClick={() =>
                                      deleteCustomerImages(
                                        customer.id,
                                        image.id
                                      )
                                    }
                                  >
                                    <FaTrashAlt size="8" />
                                  </span>
                                </Col>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Col>
              <Col md={4}>
                {customer.duplicates && customer.duplicates.length > 0 && (
                  <div className="d-flex align-items-center justify-content-end dublicate-switch">
                    <p className="text-danger mr-2">
                      {query === ""
                        ? "Show Dublicates Chat History"
                        : "Hide Dublicates Chat History"}
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        onClick={() => {
                          if (query === "" ? setQuery("show") : setQuery(""))
                            setPage(1);
                        }}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                )}
                <div className="message-wrapper bg-white">
                  <div className="messages" id="scrollableDiv">
                    <InfiniteScroll
                      dataLength={response.length}
                      next={loadFunc}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        overflow: "hidden",
                        paddingTop: "10px",
                      }}
                      inverse={true}
                      hasMore={response.length < totalResponse}
                      loader={
                        <Spinner
                          animation="border"
                          variant="danger"
                          size="sm"
                        />
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {response.length > 0 &&
                        response.map((item, index) => (
                          <div className="d-flex mb-2">
                            <img
                              src={
                                item.response_from_user.image
                                  ? item.response_from_user.image
                                  : "/assets/images/avatar.png"
                              }
                              className="message-avatar"
                              alt=""
                            />
                            <Message
                              type={item.response_type}
                              name={item.response_from_user.name}
                              message={item.response_text}
                              pin={item.pinned}
                              created={item.created}
                              id={item.id}
                              entity_type={item.entity_type}
                            />
                          </div>
                        ))}
                    </InfiniteScroll>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => ({
  customer: state.customers.customer,
  images: state.customers.images,
  question: state.ticket.ticket,
  response: state.customerQuestions.questionResponse,
  totalResponse: state.customerQuestions.totalResponse,
});
export default connect(mapStateToProps, {
  getCustomerProfile,
  getCustomerImages,
  postCustomerImages,
  getQuestionResponse,
  addCustomerCredit,
  deleteCustomerImages,
})(CustomersProfile);
