import { Card, Col, Row, ProgressBar } from "react-bootstrap";
import {
  FaCaretUp,
  FaCheckCircle,
  FaInfoCircle,
  FaPencilAlt,
  FaQuestionCircle,
  FaStar,
  FaUserCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
const Profile = () => {
  return (
    <Layout>
      <div className="profile-section">
        <Row>
          <Col md={4}>
            <div className="profile-wrapper">
              <Card>
                <div className="profile-header">
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/user3.png"
                      alt="profile"
                      className="profile-img"
                    />
                    <div>
                      <h5>Swami Ram</h5>
                      <p>Astrologer</p>
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <span className="d-flex align-items-center">
                    <FaUserCircle className="mr-1 text-info" size="18" />
                    <p className="info-heading text-info">Identification</p>
                  </span>
                  <Row className="mt-3 ml-2">
                    <Col md={6}>
                      <div className="queries">
                        <h5>tf102@gmail.com</h5>
                        <p>Username</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="profile-info">
                  <div className="d-flex justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaInfoCircle className="mr-1 text-danger" size="18" />
                      <p className="info-heading text-danger">Information</p>
                    </span>
                    <span className="d-flex align-items-center">
                      <FaPencilAlt color="#333333" size="12" className="mr-1" />
                      <p className="info-edit">Edit</p>
                    </span>
                  </div>
                  <Row className="mt-3 ml-2">
                    <Col md={6}>
                      <div className="queries">
                        <h5>Male</h5>
                        <p>Gender</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="queries">
                        <h5>Gold</h5>
                        <p>Designation</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3 ml-2">
                    <Col md={6}>
                      <div className="queries">
                        <h5>+977-19910209</h5>
                        <p>Contact</p>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between align-items-center mt-3"></div>
                </div>
                <div className="profile-queries">
                  <span className="d-flex align-items-center">
                    <FaQuestionCircle
                      color="#1C70BE"
                      className="mr-1"
                      size="18"
                    />
                    <p className="info-heading">Queries received</p>
                  </span>
                  <Row className="mt-3 ml-2">
                    <Col md={4}>
                      <div className="queries">
                        <h5>15</h5>
                        <p>Total</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="queries">
                        <h5>14</h5>
                        <p>Paid</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="queries">
                        <h5>1</h5>
                        <p>Free</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="profile-queries">
                  <span className="d-flex align-items-center">
                    <FaCheckCircle className="mr-1 text-success" size="18" />
                    <p className="info-heading text-success">
                      Queries answered
                    </p>
                  </span>
                  <Row className="mt-3 ml-2">
                    <Col md={4}>
                      <div className="queries">
                        <h5>15</h5>
                        <p>Total</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="queries">
                        <h5>14</h5>
                        <p>Paid</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="queries">
                        <h5>1</h5>
                        <p>Free</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="profile-review">
                  <span className="d-flex align-items-center">
                    <FaStar className="mr-1" color="#AE7B01" size="18" />
                    <p className="info-heading">Assigned moderators</p>
                  </span>
                </div>
              </Card>
            </div>
          </Col>
          <Col md={8}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <div className="bg-xlight d-flex justify-content-between align-items-center">
                      <h5>
                        No. of new <span className="text-success">paid</span>{" "}
                        queries received in an hour
                      </h5>
                      <div>
                        <FaCaretUp className="text-success mr-1" />
                        <p className="text-success">4</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="bg-xlight d-flex justify-content-between align-items-center">
                      <h5>
                        No. of new <span className="text-danger">free</span>{" "}
                        queries received in an hour
                      </h5>
                      <div>
                        <FaCaretUp className="text-success mr-1" />
                        <p className="text-success">4</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 stat">
                  <Col md={5}>
                    <p>No. of queries answered in hour</p>
                  </Col>
                  <Col md={5}>
                    <ProgressBar variant="success" now={80} />
                  </Col>
                  <Col md={2}>
                    <h6>12</h6>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <div className="message-wrapper mt-3">
              <Card>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="message-header">Recent Queries</h5>
                  <Link to="/questions/1" className="btn btn-primary btn-round">
                    View all
                  </Link>
                </div>
                <div className="messages messages-sm">
                  <Row className="mb-2">
                    <Col md={1}>
                      <img
                        src="/assets/images/user1.png"
                        className="message-avatar"
                        alt=""
                      />
                    </Col>
                    <Col md={10}>
                      <div className="single-message customer">
                        <h5>Anish Lamsal</h5>
                        <h6>Customer</h6>
                        <p>
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Explicabo in voluptas optio tempore aut aperiam
                          numquam nisi impedit quos.
                        </p>
                        <p className="time">2hrs</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={1}>
                      <img
                        src="/assets/images/user2.png"
                        className="message-avatar"
                        alt=""
                      />
                    </Col>
                    <Col md={10}>
                      <div className="single-message astrologer">
                        <h5>Shishir Pandey</h5>
                        <h6>Astrologer</h6>
                        <p>
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Explicabo in voluptas optio tempore aut aperiam
                          numquam nisi impedit quos.
                        </p>
                        <p className="time">2hrs</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={1}>
                      <img
                        src="/assets/images/user3.png"
                        className="message-avatar"
                        alt=""
                      />
                    </Col>
                    <Col md={10}>
                      <div className="single-message moderator">
                        <h5>Dipak Khatiwada</h5>
                        <h6>Moderator</h6>
                        <p>
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Explicabo in voluptas optio tempore aut aperiam
                          numquam nisi impedit quos.
                        </p>
                        <p className="time">2hrs</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={1}>
                      <img
                        src="/assets/images/user3.png"
                        className="message-avatar"
                        alt=""
                      />
                    </Col>
                    <Col md={10}>
                      <div className="single-message moderator">
                        <h5>Dipak Khatiwada</h5>
                        <h6>Moderator</h6>
                        <p>
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Explicabo in voluptas optio tempore aut aperiam
                          numquam nisi impedit quos.
                        </p>
                        <p className="time">2hrs</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={1}>
                      <img
                        src="/assets/images/user2.png"
                        className="message-avatar"
                        alt=""
                      />
                    </Col>
                    <Col md={10}>
                      <div className="single-message astrologer">
                        <h5>Dipak Khatiwada</h5>
                        <h6>Astrologer</h6>
                        <p>
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Explicabo in voluptas optio tempore aut aperiam
                          numquam nisi impedit quos.
                        </p>
                        <p className="time">2hrs</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Profile;
