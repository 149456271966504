import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
import { toast } from "react-toastify";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getAllZodiacs =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ActionTypes.IS_ZODIAC_LOADING, payload: true });
    try {
      const response = await fetchWrapper.get(
        COMMON_URL + `system/zodiac/?page=${page}`
      );
      dispatch({ type: ActionTypes.GET_ZODIACS, payload: response });
      dispatch({ type: ActionTypes.STOP_ZODIAC_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionTypes.STOP_ZODIAC_LOADING, payload: false });
    }
  };

export const addNewZodiac = (data) => async (dispatch) => {
  try {
    const response = await fetchWrapper.postMultipart(
      COMMON_URL + `system/zodiac/`,
      data
    );
    if (response) {
      dispatch({ type: ActionTypes.ADD_NEW_ZODIAC, payload: response });
      toast.success("Zodiac Successfully Added");
    }
  } catch (e) {
    toast.error("Something went wrong:" + e);
  }
};

export const deleteZodiac = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(
      COMMON_URL + `system/zodiac/${id}`,
      id
    );
    dispatch({ type: ActionTypes.DELETE_ZODIAC, payload: id });
    toast.success("Zodiac Successfully Deleted");
  } catch (e) {
    toast.error("Something went wrong:" + e);
  }
};

export const editZodiac = (formData, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(
      COMMON_URL + `system/zodiac/${id}/`,
      formData,
      true
    );
    dispatch({ type: ActionTypes.EDIT_ZODIAC, payload: response });
    toast.success("Zodiac Successfully Edited");
  } catch (e) {
    toast.error("Something went wrong :" + e);
  }
};
