import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import useComponentVisible from "../hooks/useComponentVisible";
import ReactCountryFlag from "react-country-flag";

const Search = ({ handleUserInput, data = [], handleDataSelect }) => {
  const [searchResult, setSearchResult] = useState("");
  const { ref, componentVisible, setComponentVisible } =
    useComponentVisible(false);

  const onHandleEnter = (e) => {
    e.preventDefault();

    handleUserInput(searchResult);
    setComponentVisible(true);
  };

  return (
    <div className="search-bar" ref={ref}>
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Search User"
            id="searchInput"
            onChange={(e) => setSearchResult(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && onHandleEnter(e)}
          ></Form.Control>
        </Form.Group>
      </Form>
      {componentVisible && (
        <div className="search-wrapper">
          {data ? (
            data.map((d) => (
              <div
                className="search-items pointer"
                key={d.id}
                onClick={() => {
                  handleDataSelect(d);
                  setComponentVisible(false);
                  document.getElementById("searchInput").value = "";
                }}
              >
                <Row>
                  <Col
                    md={2}
                    style={{
                      paddingLeft: "20px",
                      marginTop: "-5px",
                    }}
                  >
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={d.country_code}
                      style={{
                        fontSize: "1em",
                      }}
                      title={d.country}
                      svg
                    />
                    <p className="os">{d.agent}</p>
                  </Col>
                  <Col md={5}>
                    <div className="d-flex align-items-center">
                      <div>
                        <h5>{d.name}</h5>
                        <p>ID: {d.user?.id}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={2}>
                    <h5>Age</h5>
                    <p>{d.age} yrs</p>
                  </Col>
                  <Col md={3}>
                    <h5>Joined</h5>
                    <p>{d.user?.date_joined}</p>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <Spinner animation="border" variant="danger" />
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
