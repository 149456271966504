import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  FaCloudUploadAlt,
  FaImage,
  FaInfoCircle,
  FaTrashAlt,
  FaUserCircle,
} from "react-icons/fa";
import {
  deleteCustomerImages,
  getCustomerImages,
  postCustomerImages,
} from "../../_redux/actions/ActionCustomers";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { assignZodiac } from "../../_redux/actions/ActionCustomers";
import Select from "react-select";
import { fetchWrapper } from "../../_helpers";
import ReactCountryFlag from "react-country-flag";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

const LeftBar = ({
  getCustomerImages,
  postCustomerImages,
  deleteCustomerImages,
  assignZodiac,
  customer,
  images,
  user,
}) => {
  const [zodiacOptions, setZodiacOptions] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  useEffect(() => {
    getCustomerImages(customer.id);
  }, [getCustomerImages, customer.id]);

  useEffect(() => {
    (async () => {
      try {
        const zods = await fetchWrapper.get(COMMON_URL + `system/zodiac/`);
        const tempArray = [];
        // const totalPages = Math.ceil(zods.length / 10);
        zods.forEach((element) => {
          tempArray.push({
            label: (
              <div className="d-flex align-items-center">
                <div>
                  {element.image ? (
                    <img
                      className="zodiac-select-img"
                      src={element.image}
                      alt={element.name}
                    />
                  ) : (
                    <div className="zodiac-select-img"></div>
                  )}{" "}
                </div>{" "}
                <div className="ml-2">{element.name}</div>
              </div>
            ),
            value: element.id,
            ...element,
          });
        });
        setZodiacOptions(tempArray);
        const defaultOptionVal = tempArray.filter(
          (ar) => ar.value === customer.zodiac
        );
        setSelectedOption(defaultOptionVal);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [customer.zodiac]);

  const handleZodiacAssign = (e) => {
    const data = {
      zodiac: e.value,
    };
    assignZodiac(data, customer.id);
    const nextSelect = zodiacOptions.filter((ar) => ar.value === e.value);
    setSelectedOption(nextSelect[0]);
  };

  const { register, watch, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });
  const watchImage = watch("image");

  const onSubmit = (data) => {
    postCustomerImages(data, customer.id);
  };

  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };
  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  return (
    <div className="leftbar">
      <div className="profile-header w-100">
        <div className="d-flex align-items-center">
          <img
            src={
              customer.profile_image
                ? customer.profile_image
                : "/assets/images/avatar.png"
            }
            alt="profile"
            className="profile-img"
          />
          <div>
            <h5>{customer.name}</h5>
            <div className="d-flex justify-content-between">
              <p className="mr-4">Customer id: {customer.user?.id}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-info">
        <span className="d-flex align-items-center">
          <FaUserCircle className="mr-1 text-info" size="18" />
          <p className="info-heading text-info">Identification</p>
        </span>
        <div class="d-flex align-items-end mt-3 mx-4">
          <div className="queries">
            <h5>{customer.user.username}</h5>
            <p>Username</p>
          </div>
        </div>
      </div>
      <div className="profile-info">
        <div className="d-flex justify-content-between">
          <span className="d-flex align-items-center">
            <FaInfoCircle className="mr-1 text-danger" size="18" />
            <p className="info-heading text-danger">Information</p>
          </span>
        </div>
        <div class="d-flex align-items-end mt-3 mx-4">
          <div className="queries">
            <h5>{customer.gender}</h5>
            <p>Gender</p>
          </div>
          <div className="queries">
            <h5>{customer.age}</h5>
            <p>Age</p>
          </div>
        </div>
        <div class="d-flex align-items-end mt-3 mx-4">
          <div className="queries">
            <h5>{customer.dob_AD}</h5>
            <p>Date of Birth</p>
          </div>
          <div className="queries">
            <h5>{customer.birth_time} </h5>
            <span className="accurate ml-0">
              {customer.is_bt_accurate ? "Accurate" : "Not Accurate"}
            </span>
            <p>Time of Birth</p>
          </div>
        </div>
        <div class="d-flex align-items-end mt-3 mx-4">
          <div className="queries">
            <h5>
              <ReactCountryFlag
                className="emojiFlag mr-1"
                countryCode={customer.country_code}
                style={{
                  fontSize: "2em",
                }}
                title={customer.country}
                svg
              />
              {customer.country}
            </h5>
            <p>Country</p>
          </div>
          <div className="queries">
            <h5>{customer.address}</h5>
            <p>Place</p>
          </div>
        </div>
        <div class="d-flex align-items-end mt-3 mx-4">
          <div className="queries">
            <h5>{customer.user.phone}</h5>
            <p>Contact</p>
          </div>

          <div className="queries">
            <div></div>
            <Select
              options={zodiacOptions}
              value={selectedOption ? selectedOption : null}
              placeholder="Select Zodiac"
              isSearchable={false}
              onChange={(e) => handleZodiacAssign(e)}
            />
            {customer.zodiac ? <p>Zodiac</p> : <p>Assign Zodiac</p>}
          </div>
        </div>
      </div>
      <div className="chat-profile-image">
        <span className="d-flex align-items-center">
          <FaImage className="mr-1" size="18" color="#7A57FF" />
          <p className="info-heading">Image</p>
        </span>
        <Row className="mt-3 ml-2">
          {user && user.user?.role !== "qa" && (
            <Col md={12}>
              <div className="drag-area">
                <div class="icon">
                  <FaCloudUploadAlt color="#B8B8B8" />
                </div>
                <header>Drag & Drop</header>
                <span>Or</span>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="d-flex flex-column align-items-center"
                >
                  <Form.Control
                    type="file"
                    {...register("image")}
                    className="customer-file-input mb-2"
                    multiple
                  />
                  <Button
                    className="btn-primary btn-round"
                    type="submit"
                    disabled={watchImage?.length ? false : true}
                  >
                    Upload Image
                  </Button>
                </Form>
              </div>
            </Col>
          )}
        </Row>
        <Row className="mt-3 ml-2" noGutters>
          {images.length > 0 &&
            images.map((image, index) => (
              <Col md={4}>
                <img
                  src={image.image}
                  alt="kundali"
                  className="kundali pointer"
                  onClick={() => showImage(image.image)}
                />
                {user && user.user?.role !== "qa" && (
                  <span
                    className="deleteIcon pointer"
                    onClick={() => deleteCustomerImages(customer.id, image.id)}
                  >
                    <FaTrashAlt size="8" />
                  </span>
                )}
              </Col>
            ))}
        </Row>
      </div>
      {lightboxDisplay && (
        <div id="lightbox" onClick={hideLightBox}>
          <img id="lightbox-img" src={imageToShow} alt=""></img>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  images: state.customers.images,
  customers: state.customers.customers,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getCustomerImages,
  postCustomerImages,
  deleteCustomerImages,
  assignZodiac,
})(LeftBar);
