import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import {
  FaChevronDown,
  FaChevronUp,
  FaEdit,
  FaEye,
  FaTimesCircle,
} from "react-icons/fa";
import Layout from "../../layouts/Layout";
import { connect } from "react-redux";
import { getAllZodiacs } from "../../_redux/actions/ActionZodiacs";
import { getCustomers } from "../../_redux/actions/ActionCustomers";
import { Link, useParams, useHistory } from "react-router-dom";
import EditCustomer from "./EditCustomer";
import PaginationComp from "../../components/PaginationComp";
import Loader from "../../components/Loader";
import ReactFlagsSelect from "react-flags-select";

const Customers = ({
  getCustomers,
  customers,
  totalCustomers,
  loading,
  getAllZodiacs,
  allZodiacs,
}) => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [showEdit, setShowEdit] = useState(false);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [page, setPage] = useState(pageNum);
  const [showFilter, setShowFilter] = useState("");
  const [zodiacName, setZodiacName] = useState("");
  const [filter, setFilter] = useState({
    device: "",
    country: "",
    gender: "",
    zodiac: "",
  });

  useEffect(() => {
    getCustomers(page, filter);
    getAllZodiacs();
  }, [filter, getAllZodiacs, getCustomers, page]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  return (
    <Layout>
      <div className="questions-section">
        <Row>
          <Col md={4}>
            <h5 className="font-weight-bold text-danger">Customers</h5>
          </Col>
          <Col md={2}>
            <ReactFlagsSelect
              selected={filter.country}
              onSelect={(code) => {
                setFilter((prev) => ({
                  ...prev,
                  country: code,
                }));
              }}
              showSelectedLabel={true}
              showSecondarySelectedLabel={true}
              selectedSize={16}
              showOptionLabel={true}
              showSecondaryOptionLabel={true}
              optionsSize={16}
              placeholder="Select a country"
              searchable={true}
              alignOptionsToRight={false}
              fullWidth={true}
              disabled={false}
            />
          </Col>
          <Col md={2}>
            <div className="select-toggle pointer">
              <div
                className="selector"
                onClick={() =>
                  setShowFilter(showFilter !== "device" ? "device" : "")
                }
              >
                <p>{filter.device ? filter.device : "Device"}</p>
                {showFilter === "device" ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showFilter === "device" && (
                <div className="select-wrapper-right">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, device: "android" }));
                      setShowFilter("");
                    }}
                  >
                    Android
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, device: "ios" }));
                      setShowFilter("");
                    }}
                  >
                    iOs
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={2}>
            <div className="select-toggle pointer">
              <div
                className="selector"
                onClick={() =>
                  setShowFilter(showFilter !== "gender" ? "gender" : "")
                }
              >
                <p>{filter.gender ? filter.gender : "Gender"}</p>
                {showFilter === "gender" ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showFilter === "gender" && (
                <div className="select-wrapper">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, gender: "Male" }));
                      setShowFilter("");
                    }}
                  >
                    Male
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, gender: "Female" }));
                      setShowFilter("");
                    }}
                  >
                    Female
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, gender: "Other" }));
                      setShowFilter("");
                    }}
                  >
                    Other
                  </Button>
                </div>
              )}
            </div>
          </Col>

          <Col md={2}>
            <div className="select-toggle pointer">
              <div
                className="selector"
                onClick={() =>
                  setShowFilter(showFilter !== "zodiac" ? "zodiac" : "")
                }
              >
                <p>{zodiacName ? zodiacName : "Zodiac"}</p>
                {showFilter === "zodiac" ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showFilter === "zodiac" && (
                <div className="select-wrapper-right">
                  {allZodiacs &&
                    allZodiacs.map((item) => (
                      <Button
                        className="btn-outline-primary-100 mb-2"
                        onClick={() => {
                          setFilter((prev) => ({
                            ...prev,
                            zodiac: `${item.id}`,
                          }));
                          setShowFilter("");
                          setZodiacName(item.name);
                        }}
                      >
                        {item.name}
                      </Button>
                    ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {filter.device && (
              <span className="user-wrapper">
                <p>{filter && filter.device}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      device: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.country && (
              <span className="user-wrapper">
                <p>{filter && filter.country}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      country: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.gender && (
              <span className="user-wrapper">
                <p>{filter && filter.gender}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      gender: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.zodiac && (
              <span className="user-wrapper">
                <p>{filter && filter.zodiac}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      zodiac: "",
                    }));
                  }}
                />
              </span>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {showEdit && (
              <EditCustomer
                showEdit={showEdit}
                setShowEdit={setShowEdit}
                customerInfo={customerInfo}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Name</th>
                      {/* <th>Username</th> */}
                      <th>Gender</th>
                      <th>Date of birth</th>
                      <th>Device</th>
                      <th>Country</th>
                      <th>Zodiac</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      customers.length > 0 &&
                      customers.map((item, i) => (
                        <tr key={item.user?.id}>
                          <td width="5%" className="text-muted">
                            {(page - 1) * 10 + i + 1}.
                          </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  item.profile_image
                                    ? item.profile_image
                                    : "/assets/images/avatar.png"
                                }
                                className="user-img"
                                alt="user"
                              />
                              <div className="ml-2">
                                <h6 className="name font-weight-bold">
                                  {item.name}
                                </h6>
                                <h6 className="day">ID: {item.user?.id}</h6>
                              </div>
                            </div>
                          </td>
                          {/* <td className="description">{item.user.username}</td> */}
                          <td className="type textCapitalize">{item.gender}</td>

                          <td className="type textCapitalize">{item.dob_AD}</td>
                          <td className="type textCapitalize">
                            {item.user?.agent}
                          </td>
                          <td className="type">{item.country}</td>
                          <td className="type">{item.zodiac_name}</td>
                          <td className="text-center">
                            <Link to={`/customer/profile/${item.id}`}>
                              <FaEye className="text-success pointer mr-2" />
                            </Link>

                            <FaEdit
                              className="text-info pointer"
                              onClick={() => {
                                setShowEdit(!showEdit);
                                setCustomerInfo(item);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </Table>
                {totalCustomers > 10 && (
                  <PaginationComp
                    total={totalCustomers}
                    page={page}
                    setPage={handleSetPage}
                  />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
  totalCustomers: state.customers.totalCustomers,
  loading: state.ui.loading,
  allZodiacs: state.zodiacs.zodiacs,
});

const mapDispatchToProps = { getCustomers, getAllZodiacs };

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
