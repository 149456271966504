import * as ActionTypes from "../ActionTypes";

const initialState = {
  questionTypes: [],
  questionTemplate: [],
  totalQuestionTemplate: 0,
};

export default function func(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_QUESTION_TYPES:
      return { ...state, questionTypes: action.payload };
    case ActionTypes.POST_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: [action.payload, ...state.questionTypes],
      };
    case ActionTypes.DELETE_QUESTION_TYPES:
      const tempQuestionType = [...state.questionTypes];
      const index = tempQuestionType.findIndex(
        (group) => group.id === action.payload
      );
      if (index !== -1) {
        tempQuestionType.splice(index, 1);
      }
      return { ...state, questionTypes: tempQuestionType };

    case ActionTypes.GET_TEMPLATE_QUESTIONS:
      return {
        ...state,
        questionTemplate: action.payload.results,
        totalQuestionTemplate: action.payload.count,
      };
    case ActionTypes.POST_TEMPLATE_QUESTIONS:
      return {
        ...state,
        questionTemplate: [action.payload, ...state.questionTemplate],
        totalQuestionTemplate: state.totalQuestionTemplate + 1,
      };
    case ActionTypes.EDIT_TEMPLATE_QUESTION:
      const tempQuestionTemp = [...state.questionTemplate];
      const editedIndex = tempQuestionTemp.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        tempQuestionTemp[editedIndex] = action.payload;
      }
      return { ...state, questionTemplate: tempQuestionTemp };
    case ActionTypes.DELETE_TEMPLATE_QUESTION:
      const tempQuestionTempl = [...state.questionTemplate];
      const tempIndex = tempQuestionTempl.findIndex(
        (user) => user.id === action.payload
      );
      if (tempIndex !== -1) {
        tempQuestionTempl.splice(tempIndex, 1);
      }
      return { ...state, questionTemplate: tempQuestionTempl };
    default:
      return state;
  }
}
