import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import {
  FaChevronDown,
  FaChevronUp,
  FaEdit,
  FaEye,
  FaTimesCircle,
  FaTrashAlt,
} from "react-icons/fa";
import Layout from "../../layouts/Layout";
import AddStaff from "./AddStaff";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import swal from "sweetalert";

import { getAllUser, deleteAUser } from "../../_redux/actions/ActionUser";
import PaginationComp from "../../components/PaginationComp";
import { Link } from "react-router-dom";

const StaffManagement = ({
  allUser,
  getAllUser,
  deleteAUser,
  totalUsers,
  loading,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [page, setPage] = useState(1);
  const [userDetail, setUserDetail] = useState(null);
  const [showFilter, setShowFilter] = useState("");
  const [filter, setFilter] = useState({
    gender: "",
    role: "",
    level: "",
  });
  useEffect(() => {
    getAllUser(page, filter);
  }, [filter, getAllUser, page]);

  function editStaff(item) {
    setUserDetail(item);
    setShowAdd(!showAdd);
  }
  return (
    <Layout>
      <div className="questions-section">
        <Row>
          <Col md={4}>
            <h5 className="font-weight-bold text-danger mb-2">
              Staff Management
            </h5>
          </Col>
          <Col md={2}>
            <div className="select-toggle pointer">
              <div
                className="selector"
                onClick={() =>
                  setShowFilter(showFilter !== "gender" ? "gender" : "")
                }
              >
                <p>{filter.gender ? filter.gender : "Gender"}</p>
                {showFilter === "gender" ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showFilter === "gender" && (
                <div className="select-wrapper">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, gender: "Male" }));
                      setShowFilter("");
                    }}
                  >
                    Male
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, gender: "Female" }));
                      setShowFilter("");
                    }}
                  >
                    Female
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, gender: "Other" }));
                      setShowFilter("");
                    }}
                  >
                    Other
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={2}>
            <div className="select-toggle pointer">
              <div
                className="selector"
                onClick={() =>
                  setShowFilter(showFilter !== "role" ? "role" : "")
                }
              >
                <p>{filter.role ? filter.role : "Role"}</p>
                {showFilter === "role" ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showFilter === "role" && (
                <div className="select-wrapper-right">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, role: "moderator" }));
                      setShowFilter("");
                    }}
                  >
                    Moderator
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, role: "astrologer" }));
                      setShowFilter("");
                    }}
                  >
                    Astrologer
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, role: "qa" }));
                      setShowFilter("");
                    }}
                  >
                    QA
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={2}>
            <div className="select-toggle pointer">
              <div
                className="selector"
                onClick={() =>
                  setShowFilter(showFilter !== "level" ? "level" : "")
                }
              >
                <p>{filter.level ? filter.level : "Level"}</p>
                {showFilter === "level" ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showFilter === "level" && (
                <div className="select-wrapper-right">
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Free" }));
                      setShowFilter("");
                    }}
                  >
                    Free
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Bronze" }));
                      setShowFilter("");
                    }}
                  >
                    Bronze
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Silver" }));
                      setShowFilter("");
                    }}
                  >
                    Silver
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Gold" }));
                      setShowFilter("");
                    }}
                  >
                    Gold
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Diamond" }));
                      setShowFilter("");
                    }}
                  >
                    Diamond
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Spiritualist" }));
                      setShowFilter("");
                    }}
                  >
                    Spiritualist
                  </Button>
                  <Button
                    className="btn-outline-primary-100 mb-2"
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, level: "Counselor" }));
                      setShowFilter("");
                    }}
                  >
                    Counselor
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={2}>
            {!showAdd && (
              <Button
                className="btn-primary btn-round float-right mb-2"
                onClick={() => setShowAdd(!showAdd)}
              >
                Add Staff
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {filter.role && (
              <span className="user-wrapper">
                <p>{filter && filter.role}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      role: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.gender && (
              <span className="user-wrapper">
                <p>{filter && filter.gender}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      gender: "",
                    }));
                  }}
                />
              </span>
            )}
            {filter.level && (
              <span className="user-wrapper">
                <p>{filter && filter.level}</p>
                <FaTimesCircle
                  size="12"
                  color="#BC2A20"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      level: "",
                    }));
                  }}
                />
              </span>
            )}
          </Col>
        </Row>
        <Row className="mb-2 mt-2">
          <Col md={12}>
            {showAdd && (
              <AddStaff
                onAdd={() => setShowAdd(!showAdd)}
                showAdd={showAdd}
                setShowAdd={setShowAdd}
                userDetail={userDetail}
                setUserDetail={setUserDetail}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="dashboard-table">
              <Card className="table-minheight">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Gender</th>
                      <th>Role</th>
                      <th>Contact no.</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      allUser.length > 0 ? (
                        allUser.map((item, index) => (
                          <tr key={item.user?.id}>
                            <td>{(page - 1) * 10 + index + 1}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item.profile_image
                                      ? item.profile_image
                                      : "/assets/images/avatar.png"
                                  }
                                  className="user-img"
                                  alt="user"
                                />
                                <div className="ml-2">
                                  <h6 className="name font-weight-bold">
                                    {item.user?.first_name}{" "}
                                    {item.user?.last_name}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td className="description">
                              {item.user?.username}
                            </td>
                            <td className="type textCapitalize">
                              {item.gender}
                            </td>
                            <td className="type textCapitalize">
                              {item.user?.role}
                            </td>
                            <td className="type">{item.user?.phone}</td>
                            <td className="text-center">
                              <Link to={`/moderator/profile/${item.id}`}>
                                <FaEye className="text-success pointer mr-2" />
                              </Link>
                              <FaEdit
                                className="text-info pointer mr-1"
                                onClick={() => {
                                  editStaff(item);
                                }}
                              />
                              <FaTrashAlt
                                className="text-danger pointer"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover !",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      deleteAUser(item.id);
                                    }
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p className="text-danger p-3">No data found</p>
                      )
                    ) : (
                      <Loader></Loader>
                    )}
                  </tbody>
                </Table>
                {totalUsers > 10 && (
                  <PaginationComp
                    total={totalUsers}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  allUser: state.user.allUser,
  totalUsers: state.user.totalUsers,
  loading: state.ui.loading,
});

const mapDispatchToProps = { getAllUser, deleteAUser };

export default connect(mapStateToProps, mapDispatchToProps)(StaffManagement);
