import { fetchWrapper } from "../../_helpers";
import * as ActionTypes from "../ActionTypes";
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const loginUser = (user) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(
      COMMON_URL + `auth/token/login/`,
      user
    );
    localStorage.setItem("token", response.token);
    dispatch({ type: ActionTypes.SET_LOGGEDIN });
  } catch (e) {
    dispatch({
      type: ActionTypes.SET_MESSAGE,
      payload: {
        error: true,
        message: "Username and Password didn't match",
      },
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    dispatch({ type: ActionTypes.SET_LOGGEDOUT });
    await fetchWrapper.post(COMMON_URL + `auth/token/logout/`);
  } catch (e) {
    console.log(e);
  }
};
