import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  postQuestionTypes,
  getQuestionTypes,
  deleteQuestionType,
} from "../../_redux/actions/ActionQuestions";
import { connect } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

const AddQuestionType = ({
  postQuestionTypes,
  getQuestionTypes,
  questionTypes,
  deleteQuestionType,
  showAddType,
  setShowAddType,
}) => {
  useEffect(() => {
    getQuestionTypes();
  }, [getQuestionTypes]);

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    postQuestionTypes(data);
    reset();
    setShowAddType(false);
  };
  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Question Type</Form.Label>
                <Form.Control
                  type="text"
                  {...register("question_type")}
                  placeholder="eg:Career"
                />
              </Form.Group>
              <Form.Group controlId="formGroupName">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/png, image/jpeg"
                  {...register("images")}
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <div className="scroll-wrapper">
                <Row>
                  {questionTypes.length > 0 &&
                    questionTypes.map((item, index) => (
                      <Col md={6}>
                        <div className="question-type-list d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                item.images
                                  ? item.images
                                  : `/assets/images/noimage.jpg`
                              }
                              alt=""
                              className="no-img mr-2"
                            />
                            <p>{item.question_type}</p>
                          </div>

                          <FaTrashAlt
                            color="#BC2A20"
                            size={14}
                            className="pointer"
                            onClick={() => deleteQuestionType(item.id)}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button className="btn-primary btn-round btn-sm" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  questionTypes: state.questionTemplate.questionTypes,
});

const mapDispatchToProps = {
  postQuestionTypes,
  getQuestionTypes,
  deleteQuestionType,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionType);
